<section @fadeInPage class="wrapper">
  <aside class="left-panel">
    <img
      class="panel-image"
      src="../../../../assets/images/Rectangle 107.png"
    />
  </aside>

  <main class="right-panel">
    <ng-content></ng-content>
  </main>
</section>
