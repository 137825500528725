<div class="filter-container">
  <div class="dark-theme">
    <button
      *ngIf="!locationFlags.isPendingLocation"
      mat-button
      color="primary"
      [matTooltip]="buttonTooltip"
      matTooltipPosition="after"
      [style]="
        locationFlags.isLocationActive
          ? 'background-color: var(--tabs); color: #ffffff;'
          : ''
      "
      aria-label="Filter Location"
      (click)="onFilterByLocation()"
    >
      {{
        locationFlags.isLocationActive
          ? "Mostrando empresas cercanas"
          : "Filtrar por cercanía"
      }}
      <mat-icon
        [style]="locationFlags.isLocationActive ? 'color: var(--accent);' : ''"
        >location_on</mat-icon
      >
    </button>
    <mat-spinner
      diameter="35"
      *ngIf="locationFlags.isPendingLocation"
    ></mat-spinner>
  </div>
</div>
