import { __assign, __rest as __rest_1, __spreadArray } from "tslib";
import { __rest } from "tslib";
import { mergeDeep } from "../common/mergeDeep.js";
export function concatPagination(keyArgs) {
  if (keyArgs === void 0) {
    keyArgs = false;
  }
  return {
    keyArgs: keyArgs,
    merge: function (existing, incoming) {
      return existing ? __spreadArray(__spreadArray([], existing, true), incoming, true) : incoming;
    }
  };
}
export function offsetLimitPagination(keyArgs) {
  if (keyArgs === void 0) {
    keyArgs = false;
  }
  return {
    keyArgs: keyArgs,
    merge: function (existing, incoming, _a) {
      var args = _a.args;
      var merged = existing ? existing.slice(0) : [];
      if (incoming) {
        if (args) {
          var _b = args.offset,
            offset = _b === void 0 ? 0 : _b;
          for (var i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
          }
        } else {
          merged.push.apply(merged, incoming);
        }
      }
      return merged;
    }
  };
}
export function relayStylePagination(keyArgs) {
  if (keyArgs === void 0) {
    keyArgs = false;
  }
  return {
    keyArgs: keyArgs,
    read: function (existing, _a) {
      var canRead = _a.canRead,
        readField = _a.readField;
      if (!existing) return existing;
      var edges = [];
      var firstEdgeCursor = "";
      var lastEdgeCursor = "";
      existing.edges.forEach(function (edge) {
        if (canRead(readField("node", edge))) {
          edges.push(edge);
          if (edge.cursor) {
            firstEdgeCursor = firstEdgeCursor || edge.cursor || "";
            lastEdgeCursor = edge.cursor || lastEdgeCursor;
          }
        }
      });
      if (edges.length > 1 && firstEdgeCursor === lastEdgeCursor) {
        firstEdgeCursor = "";
      }
      var _b = existing.pageInfo || {},
        startCursor = _b.startCursor,
        endCursor = _b.endCursor;
      return __assign(__assign({}, getExtras(existing)), {
        edges: edges,
        pageInfo: __assign(__assign({}, existing.pageInfo), {
          startCursor: startCursor || firstEdgeCursor,
          endCursor: endCursor || lastEdgeCursor
        })
      });
    },
    merge: function (existing, incoming, _a) {
      var args = _a.args,
        isReference = _a.isReference,
        readField = _a.readField;
      if (!existing) {
        existing = makeEmptyData();
      }
      if (!incoming) {
        return existing;
      }
      var incomingEdges = incoming.edges ? incoming.edges.map(function (edge) {
        if (isReference(edge = __assign({}, edge))) {
          edge.cursor = readField("cursor", edge);
        }
        return edge;
      }) : [];
      if (incoming.pageInfo) {
        var pageInfo_1 = incoming.pageInfo;
        var startCursor = pageInfo_1.startCursor,
          endCursor = pageInfo_1.endCursor;
        var firstEdge = incomingEdges[0];
        var lastEdge = incomingEdges[incomingEdges.length - 1];
        if (firstEdge && startCursor) {
          firstEdge.cursor = startCursor;
        }
        if (lastEdge && endCursor) {
          lastEdge.cursor = endCursor;
        }
        var firstCursor = firstEdge && firstEdge.cursor;
        if (firstCursor && !startCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              startCursor: firstCursor
            }
          });
        }
        var lastCursor = lastEdge && lastEdge.cursor;
        if (lastCursor && !endCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: lastCursor
            }
          });
        }
      }
      var prefix = existing.edges;
      var suffix = [];
      if (args && args.after) {
        var index = prefix.findIndex(function (edge) {
          return edge.cursor === args.after;
        });
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1);
        }
      } else if (args && args.before) {
        var index = prefix.findIndex(function (edge) {
          return edge.cursor === args.before;
        });
        suffix = index < 0 ? prefix : prefix.slice(index);
        prefix = [];
      } else if (incoming.edges) {
        prefix = [];
      }
      var edges = __spreadArray(__spreadArray(__spreadArray([], prefix, true), incomingEdges, true), suffix, true);
      var pageInfo = __assign(__assign({}, incoming.pageInfo), existing.pageInfo);
      if (incoming.pageInfo) {
        var _b = incoming.pageInfo,
          hasPreviousPage = _b.hasPreviousPage,
          hasNextPage = _b.hasNextPage,
          startCursor = _b.startCursor,
          endCursor = _b.endCursor,
          extras = __rest_1(_b, ["hasPreviousPage", "hasNextPage", "startCursor", "endCursor"]);
        Object.assign(pageInfo, extras);
        if (!prefix.length) {
          if (void 0 !== hasPreviousPage) pageInfo.hasPreviousPage = hasPreviousPage;
          if (void 0 !== startCursor) pageInfo.startCursor = startCursor;
        }
        if (!suffix.length) {
          if (void 0 !== hasNextPage) pageInfo.hasNextPage = hasNextPage;
          if (void 0 !== endCursor) pageInfo.endCursor = endCursor;
        }
      }
      return __assign(__assign(__assign({}, getExtras(existing)), getExtras(incoming)), {
        edges: edges,
        pageInfo: pageInfo
      });
    }
  };
}
var getExtras = function (obj) {
  return __rest(obj, notExtras);
};
var notExtras = ["edges", "pageInfo"];
function makeEmptyData() {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: "",
      endCursor: ""
    }
  };
}
