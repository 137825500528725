import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { GET_PLACE_CATEGORIES } from 'src/app/graphql';
import { GetPlaceCategoriesResponse, PlaceCategory } from 'src/types/Place';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'categories-chips-list',
  templateUrl: './categories-chips-list.component.html',
  styleUrl: './categories-chips-list.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CategoriesChipsListComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) selectedCategories!: Subject<number[]>;
  @ViewChild('swiper', { static: false })
  swiperRef?: ElementRef<SwiperContainer>;
  navigationCssVariables = `
    --swiper-navigation-color: #561AD9;
    --swiper-navigation-size: 26px;
    --swiper-navigation-sides-offset: -10px;
  `;
  categories: PlaceCategory[] = [];

  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    this.apollo
      .watchQuery<GetPlaceCategoriesResponse>({ query: GET_PLACE_CATEGORIES })
      .valueChanges.subscribe((categoriesData) => {
        this.categories = categoriesData.data.placeCategories;
      });
  }

  ngAfterViewInit(): void {
    if (this.swiperRef) {
      // Configure Swiper with custom options
      const swiperConfiguration: SwiperOptions = {
        navigation: {
          enabled: true,
        },
        slidesPerView: 'auto',
      };
      // Add configuration to the swiper element
      Object.assign(this.swiperRef.nativeElement, swiperConfiguration);
    }
  }

  onSelectCategory(chipBoxEvent: MatChipListboxChange) {
    this.selectedCategories.next(chipBoxEvent.value);
  }
}
