import { __extends, __spreadArray } from "tslib";
import "../utilities/globals/index.js";
import { isNonNullObject } from "../utilities/index.js";
export var PROTOCOL_ERRORS_SYMBOL = Symbol();
export function graphQLResultHasProtocolErrors(result) {
  if (result.extensions) {
    return Array.isArray(result.extensions[PROTOCOL_ERRORS_SYMBOL]);
  }
  return false;
}
export function isApolloError(err) {
  return err.hasOwnProperty("graphQLErrors");
}
var generateErrorMessage = function (err) {
  var errors = __spreadArray(__spreadArray(__spreadArray([], err.graphQLErrors, true), err.clientErrors, true), err.protocolErrors, true);
  if (err.networkError) errors.push(err.networkError);
  return errors.map(function (err) {
    return isNonNullObject(err) && err.message || "Error message not found.";
  }).join("\n");
};
var ApolloError = function (_super) {
  __extends(ApolloError, _super);
  function ApolloError(_a) {
    var graphQLErrors = _a.graphQLErrors,
      protocolErrors = _a.protocolErrors,
      clientErrors = _a.clientErrors,
      networkError = _a.networkError,
      errorMessage = _a.errorMessage,
      extraInfo = _a.extraInfo;
    var _this = _super.call(this, errorMessage) || this;
    _this.name = "ApolloError";
    _this.graphQLErrors = graphQLErrors || [];
    _this.protocolErrors = protocolErrors || [];
    _this.clientErrors = clientErrors || [];
    _this.networkError = networkError || null;
    _this.message = errorMessage || generateErrorMessage(_this);
    _this.extraInfo = extraInfo;
    _this.__proto__ = ApolloError.prototype;
    return _this;
  }
  return ApolloError;
}(Error);
export { ApolloError };
