import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher as MaterialErrorStateMatcher } from '@angular/material/core';

export class ErrorStateMatcher implements MaterialErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      (control.invalid || form?.errors?.['mismatch']) &&
      (control.dirty || control.touched || (form && form.submitted))
    );
  }
}
