import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpecialtyDetails } from 'src/types/Place';

@Component({
  selector: 'specialty-card',
  templateUrl: './specialty-card.component.html',
  styleUrls: ['./specialty-card.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '40px',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
        })
      ),
      transition('collapsed <=> expanded', [animate('400ms ease')]),
    ]),
  ],
})
export class SpecialtyCardComponent {
  @Input({ required: true }) placeId!: number;
  @Input({ required: true }) specialty!: SpecialtyDetails;

  showMore: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  onClickScheduleAppointment(specialtyId: number) {
    this.router.navigate([`/schedule-appointment/${this.placeId}`], {
      relativeTo: this.activatedRoute,
      queryParams: { specialty: specialtyId },
    });
  }

  onToggleShowMore(clickEvent: MouseEvent) {
    clickEvent.stopPropagation();
    this.showMore = !this.showMore;
  }
}
