<div class="star-rating">
  <p>Califica tu experiencia</p>
  <div class="stars">
    <div *ngFor="let rating of ratings" class="star-container">
      <mat-icon class="star" (click)="selectRate(rating.rate)">
        <svg viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="none"
            [ngClass]="
              rating.rate <= selectedRate
                ? 'star-path star-path-selected'
                : 'star-path'
            "
            d="M33.5247 5.37145L39.5131 17.9209C39.971 18.8807 40.8834 19.5436 41.9377 19.6825L55.7235 21.4998C57.5947 21.7464 58.3439 24.0524 56.9751 25.3518L46.8903 34.9251C46.1191 35.6572 45.7706 36.7298 45.9642 37.7754L48.4959 51.448C48.8396 53.3039 46.878 54.729 45.2192 53.8287L32.9981 47.1958C32.0635 46.6886 30.9357 46.6886 30.0011 47.1958L17.78 53.8287C16.1212 54.729 14.1596 53.3039 14.5033 51.448L17.035 37.7754C17.2286 36.7298 16.8801 35.6572 16.1089 34.9251L6.0241 25.3518C4.65525 24.0524 5.40451 21.7464 7.27571 21.4998L21.0615 19.6825C22.1158 19.5436 23.0282 18.8807 23.4861 17.9209L29.4744 5.37145C30.2873 3.66805 32.7119 3.66805 33.5247 5.37145Z"
            fill-opacity="0.56"
            stroke="url(#paint0_linear_7373_7594)"
            stroke-width="0.897561"
          />
          <defs>
            <linearGradient
              id="paint0_linear_7373_7594"
              x1="49.4508"
              y1="46.3097"
              x2="26.1142"
              y2="6.81703"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#561AD9" stop-opacity="0.15" />
              <stop offset="1" stop-color="#290C6A" stop-opacity="0.49" />
            </linearGradient>
          </defs>
        </svg>
      </mat-icon>
      <span>{{ rating.label }}</span>
    </div>
  </div>
</div>
