import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'user-agent-modal',
  templateUrl: './user-agent-modal.component.html',
  styleUrl: './user-agent-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class UserAgentModalComponent {
  constructor(public dialogRef: MatDialogRef<UserAgentModalComponent>) {}

  onConfirmation() {
    this.dialogRef.close({ shouldAcknowledge: true });
  }
}
