<sidenav>
  <section @fadeIn class="my-appointments-container" *ngIf="!isLoading">
    <mat-tab-group
      disableRipple
      [selectedIndex]="selectedAppointmentStatus === 'approved' ? 1 : 0"
      style="
        --mdc-tab-indicator-active-indicator-color: transparent;
        --mat-tab-header-active-focus-indicator-color: transparent;
        --mat-tab-header-active-hover-indicator-color: transparent;
        --mat-tab-header-disabled-ripple-color: transparent;
        --mat-tab-header-active-ripple-color: transparent;
        --mat-tab-header-inactive-ripple-color: transparent;
      "
    >
      <mat-tab label="Pendientes">
        <div class="mat-tab-body-wrapper">
          <h1>Mis citas Agendadas</h1>

          <section>
            <div
              class="cards-grid-template"
              *ngIf="
                pendingAppointments.length > 0;
                else notPendingAppointments
              "
            >
              <appointment-details
                *ngFor="let appointment of pendingAppointments"
                [appointmentData]="{
              appointment,
              selectedAppointmentId
            }"
                (notifyAppointmentCancelation)="
                  onNotifyAppointmentCancelation($event)
                "
              ></appointment-details>
            </div>

            <ng-template #notPendingAppointments>
              <h2 class="no-appointments-message">
                No existe ninguna cita pendiente
              </h2>
            </ng-template>
          </section>
        </div>
      </mat-tab>

      <mat-tab label="Aprobadas">
        <div class="mat-tab-body-wrapper">
          <h1>Mis citas Agendadas</h1>

          <section>
            <div
              class="cards-grid-template"
              *ngIf="
                approvedAppointments.length > 0;
                else notApprovedAppointments
              "
            >
              <appointment-details
                *ngFor="let appointment of approvedAppointments"
                [appointmentData]="{
                appointment,
                selectedAppointmentId
              }"
                (notifyAppointmentCancelation)="
                  onNotifyAppointmentCancelation($event)
                "
              ></appointment-details>
            </div>

            <ng-template #notApprovedAppointments>
              <h2 class="no-appointments-message">
                No existe ninguna cita aprobada
              </h2>
            </ng-template>
          </section>
        </div>
      </mat-tab>

      <mat-tab label="Rechazadas">
        <div class="mat-tab-body-wrapper">
          <h1>Mis citas Agendadas</h1>

          <section>
            <div
              class="cards-grid-template"
              *ngIf="
                rejectedAppointments.length > 0;
                else notRejectedAppointments
              "
            >
              <appointment-details
                *ngFor="let appointment of rejectedAppointments"
                [appointmentData]="{ appointment }"
                (notifyAppointmentCancelation)="
                  onNotifyAppointmentCancelation($event)
                "
              ></appointment-details>
            </div>

            <ng-template #notRejectedAppointments>
              <h2 class="no-appointments-message">
                No existe ninguna cita rechazada
              </h2>
            </ng-template>
          </section>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>
</sidenav>
