<mat-card
  class="company-card"
  appearance="outlined"
  highlightSelected="#561ad9"
  highlightSelectedProperty="outlineColor"
  (click)="onClickCard(place.id)"
>
  <image [imgSrc]="place.profilePictureUrl" [imgAlt]="place.name"></image>
  <mat-card-content>
    <mat-card-header>
      <mat-card-title>
        {{ place.name }}
        <mat-icon>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_i_7626_53)">
              <path
                d="M5.65391 1.50346C5.84417 1.10472 6.41174 1.10473 6.60201 1.50346L7.77013 3.95145C7.8467 4.11191 7.99926 4.22275 8.17553 4.24599L10.8647 4.60047C11.3027 4.65821 11.4781 5.198 11.1577 5.50216L9.19045 7.36958C9.0615 7.492 9.00323 7.67134 9.0356 7.84617L9.52946 10.5132C9.6099 10.9477 9.15073 11.2813 8.76243 11.0705L6.37851 9.77665C6.22224 9.69184 6.03367 9.69184 5.8774 9.77665L3.49348 11.0705C3.10518 11.2813 2.64601 10.9476 2.72645 10.5132L3.22032 7.84616C3.25269 7.67133 3.19442 7.492 3.06546 7.36958L1.09826 5.50216C0.777837 5.198 0.953226 4.65821 1.39124 4.60047L4.08038 4.24599C4.25666 4.22275 4.40921 4.11191 4.48579 3.95145L5.65391 1.50346Z"
                fill="#561AD9"
              />
            </g>
            <defs>
              <filter
                id="filter0_i_7626_53"
                x="0.934082"
                y="1.20441"
                width="10.9129"
                height="10.6313"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="0.525254" dy="0.700338" />
                <feGaussianBlur stdDeviation="0.350169" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.271219 0 0 0 0 0.0588 0 0 0 0 0.735 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_7626_53"
                />
              </filter>
            </defs>
          </svg>
        </mat-icon>
        <span>{{ place.rate | number : "1.1-1" }}</span>
      </mat-card-title>
    </mat-card-header>
    <p class="category-text">
      {{ place.category }}
    </p>
    <p *ngIf="place.description !== 'null'; else address" [style.opacity]="0.9">
      {{ place.description }}
    </p>
    <ng-template #address>
      <p [style.opacity]="0.5">
        {{ place.address }}
      </p>
    </ng-template>
    <div class="custom-chip-list">
      <span
        class="custom-chip"
        *ngFor="let specialty of place.specialties"
        [style]="'background-color:' + specialty.Color + ';'"
        contrastTextColor
      >
        {{ specialty.Name }}
      </span>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button color="accent">Visitar</button>
  </mat-card-actions>
</mat-card>
