<div class="notification-container">
  <mat-icon class="" aria-hidden="false" aria-label="Success notification icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        *ngIf="!showSuccessMessage && !showWarningMessage"
        d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71645C0.00541606 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C24.9965 9.18587 23.6784 6.00848 21.335 3.66503C18.9915 1.32158 15.8141 0.00349978 12.5 0ZM17.0264 15.6659C17.1158 15.7552 17.1866 15.8613 17.235 15.978C17.2833 16.0947 17.3082 16.2198 17.3082 16.3461C17.3082 16.4725 17.2833 16.5976 17.235 16.7143C17.1866 16.831 17.1158 16.9371 17.0264 17.0264C16.9371 17.1158 16.831 17.1866 16.7143 17.235C16.5976 17.2833 16.4725 17.3082 16.3462 17.3082C16.2198 17.3082 16.0947 17.2833 15.978 17.235C15.8613 17.1866 15.7552 17.1158 15.6659 17.0264L12.5 13.8594L9.33414 17.0264C9.2448 17.1158 9.13874 17.1866 9.02202 17.235C8.9053 17.2833 8.78019 17.3082 8.65385 17.3082C8.52751 17.3082 8.4024 17.2833 8.28568 17.235C8.16896 17.1866 8.0629 17.1158 7.97356 17.0264C7.88422 16.9371 7.81336 16.831 7.76501 16.7143C7.71666 16.5976 7.69178 16.4725 7.69178 16.3461C7.69178 16.2198 7.71666 16.0947 7.76501 15.978C7.81336 15.8613 7.88422 15.7552 7.97356 15.6659L11.1406 12.5L7.97356 9.33413C7.79314 9.15371 7.69178 8.909 7.69178 8.65384C7.69178 8.39869 7.79314 8.15398 7.97356 7.97356C8.15399 7.79313 8.39869 7.69177 8.65385 7.69177C8.90901 7.69177 9.15372 7.79313 9.33414 7.97356L12.5 11.1406L15.6659 7.97356C15.7552 7.88422 15.8613 7.81335 15.978 7.765C16.0947 7.71666 16.2198 7.69177 16.3462 7.69177C16.4725 7.69177 16.5976 7.71666 16.7143 7.765C16.831 7.81335 16.9371 7.88422 17.0264 7.97356C17.1158 8.06289 17.1866 8.16895 17.235 8.28568C17.2833 8.4024 17.3082 8.5275 17.3082 8.65384C17.3082 8.78019 17.2833 8.90529 17.235 9.02201C17.1866 9.13874 17.1158 9.2448 17.0264 9.33413L13.8594 12.5L17.0264 15.6659Z"
        fill="#CD0000"
      />
      <path
        *ngIf="showSuccessMessage"
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9966 8.81843 22.7313 5.76814 20.4816 3.51843C18.2319 1.26872 15.1816 0.00335979 12 0ZM17.2685 9.88384L10.8069 16.3454C10.7212 16.4312 10.6194 16.4993 10.5073 16.5457C10.3953 16.5922 10.2752 16.6161 10.1538 16.6161C10.0325 16.6161 9.91243 16.5922 9.80037 16.5457C9.68831 16.4993 9.5865 16.4312 9.50077 16.3454L6.73154 13.5762C6.55834 13.4029 6.46103 13.168 6.46103 12.9231C6.46103 12.6781 6.55834 12.4432 6.73154 12.27C6.90475 12.0968 7.13967 11.9995 7.38462 11.9995C7.62957 11.9995 7.86449 12.0968 8.0377 12.27L10.1538 14.3873L15.9623 8.57769C16.0481 8.49193 16.1499 8.4239 16.2619 8.37748C16.374 8.33107 16.4941 8.30718 16.6154 8.30718C16.7367 8.30718 16.8568 8.33107 16.9688 8.37748C17.0809 8.4239 17.1827 8.49193 17.2685 8.57769C17.3542 8.66345 17.4223 8.76527 17.4687 8.87733C17.5151 8.98938 17.539 9.10948 17.539 9.23077C17.539 9.35205 17.5151 9.47216 17.4687 9.58421C17.4223 9.69627 17.3542 9.79808 17.2685 9.88384Z"
        fill="#148C00"
      />
      <path
        *ngIf="showWarningMessage"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5ZM12.5 4.6875C12.8108 4.6875 13.1089 4.81097 13.3286 5.03073C13.5484 5.2505 13.6719 5.54857 13.6719 5.85938V12.8906C13.6719 13.2014 13.5484 13.4995 13.3286 13.7193C13.1089 13.939 12.8108 14.0625 12.5 14.0625C12.1892 14.0625 11.8911 13.939 11.6714 13.7193C11.4516 13.4995 11.3281 13.2014 11.3281 12.8906V5.85938C11.3281 5.54857 11.4516 5.2505 11.6714 5.03073C11.8911 4.81097 12.1892 4.6875 12.5 4.6875ZM12.5 20.3125C12.9144 20.3125 13.3118 20.1479 13.6049 19.8549C13.8979 19.5618 14.0625 19.1644 14.0625 18.75C14.0625 18.3356 13.8979 17.9382 13.6049 17.6451C13.3118 17.3521 12.9144 17.1875 12.5 17.1875C12.0856 17.1875 11.6882 17.3521 11.3951 17.6451C11.1021 17.9382 10.9375 18.3356 10.9375 18.75C10.9375 19.1644 11.1021 19.5618 11.3951 19.8549C11.6882 20.1479 12.0856 20.3125 12.5 20.3125Z"
        fill="#D89B00"
      />
    </svg>
  </mat-icon>
  <span matSnackBarLabel>
    {{ message }}
  </span>
  <span matSnackBarActions (click)="close()">
    <mat-icon
      class="close-icon"
      aria-hidden="false"
      aria-label="Close notification"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
      >
        <path
          d="M0.146894 0.146161C0.193339 0.0995978 0.248515 0.0626549 0.30926 0.0374484C0.370005 0.012242 0.435126 -0.000732422 0.500894 -0.000732422C0.566661 -0.000732422 0.631782 0.012242 0.692527 0.0374484C0.753272 0.0626549 0.808448 0.0995978 0.854894 0.146161L3.50089 2.79316L6.14689 0.146161C6.19338 0.0996731 6.24857 0.0627968 6.30931 0.0376377C6.37005 0.0124786 6.43515 -0.000470638 6.50089 -0.000470638C6.56664 -0.000470638 6.63174 0.0124786 6.69248 0.0376377C6.75322 0.0627968 6.80841 0.0996731 6.85489 0.146161C6.90138 0.192649 6.93826 0.247838 6.96342 0.308578C6.98858 0.369317 7.00152 0.434417 7.00152 0.500161C7.00152 0.565905 6.98858 0.631005 6.96342 0.691745C6.93826 0.752484 6.90138 0.807673 6.85489 0.854161L4.20789 3.50016L6.85489 6.14616C6.90138 6.19265 6.93826 6.24784 6.96342 6.30858C6.98858 6.36932 7.00152 6.43442 7.00152 6.50016C7.00152 6.56591 6.98858 6.63101 6.96342 6.69174C6.93826 6.75248 6.90138 6.80767 6.85489 6.85416C6.80841 6.90065 6.75322 6.93753 6.69248 6.96268C6.63174 6.98784 6.56664 7.00079 6.50089 7.00079C6.43515 7.00079 6.37005 6.98784 6.30931 6.96268C6.24857 6.93753 6.19338 6.90065 6.14689 6.85416L3.50089 4.20716L0.854894 6.85416C0.808406 6.90065 0.753217 6.93753 0.692477 6.96268C0.631738 6.98784 0.566637 7.00079 0.500894 7.00079C0.43515 7.00079 0.370049 6.98784 0.30931 6.96268C0.248571 6.93753 0.193381 6.90065 0.146894 6.85416C0.100406 6.80767 0.0635292 6.75248 0.0383701 6.69174C0.013211 6.63101 0.000261784 6.56591 0.000261784 6.50016C0.000261784 6.43442 0.013211 6.36932 0.0383701 6.30858C0.0635292 6.24784 0.100406 6.19265 0.146894 6.14616L2.79389 3.50016L0.146894 0.854161C0.10033 0.807716 0.0633873 0.75254 0.0381808 0.691795C0.0129744 0.63105 0 0.565928 0 0.500161C0 0.434394 0.0129744 0.369273 0.0381808 0.308527C0.0633873 0.247782 0.10033 0.192607 0.146894 0.146161Z"
          fill="black"
        />
      </svg>
    </mat-icon>
  </span>
</div>
