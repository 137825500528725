import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router) {
    // Router events for some reason cannot be accessed via a guard, so it had to be implemented directly in the component:
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        const navigationEndEvent = e as NavigationEnd;
        const previousUrl = navigationEndEvent.url;
        const urlAfterRedirects = navigationEndEvent.urlAfterRedirects;
        if (urlAfterRedirects.includes('not-found')) {
          const navigationState =
            this.router.getCurrentNavigation()?.extras.state;
          let activatedByRouting = false;
          if (navigationState) {
            activatedByRouting = navigationState['activatedByRouting'];
          }
          if (previousUrl === urlAfterRedirects && !activatedByRouting) {
            this.router.navigate(['/'], { replaceUrl: true });
          }
        }
      });
  }

  ngOnInit(): void {}
}
