import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'site-in-maintenance',
  templateUrl: './site-in-maintenance.component.html',
  styleUrls: ['./site-in-maintenance.component.scss'],
})
export class SiteInMaintenanceComponent {
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        const navigationEndEvent = e as NavigationEnd;
        const previousUrl = navigationEndEvent.url;
        const urlAfterRedirects = navigationEndEvent.urlAfterRedirects;
        if (urlAfterRedirects.includes('site-in-maintenance')) {
          const navigationState =
            this.router.getCurrentNavigation()?.extras.state;
          let activatedByRouting = false;
          if (navigationState) {
            activatedByRouting = navigationState['activatedByRouting'];
          }
          if (previousUrl === urlAfterRedirects && !activatedByRouting) {
            this.router.navigate(['/'], { replaceUrl: true });
          }
        }
      });
  }
}
