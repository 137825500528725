<div class="profile-picture">
  <div class="profile-picture-wrapper" (click)="fileInput.click()">
    <image [imgSrc]="image" [imgAlt]="selectedFile.name"></image>
  </div>
  <!-- <img [src]="image" [alt]="selectedFile.name" (click)="fileInput.click()" /> -->
  <input
    type="file"
    accept="image/*"
    hidden
    #fileInput
    (change)="onImageSelected($event)"
  />
  <mat-icon class="camera-icon" (click)="fileInput.click()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 36"
      fill="none"
    >
      <circle cx="17.5" cy="18" r="17.5" fill="#F0F0F0" />
      <path
        d="M25.3333 12.0932H22.5L21.825 10.1935C21.7784 10.0635 21.6929 9.95118 21.5803 9.8718C21.4677 9.79241 21.3334 9.74988 21.1958 9.75H14.8042C14.5229 9.75 14.2708 9.92783 14.1771 10.1935L13.5 12.0932H10.6667C9.74583 12.0932 9 12.8422 9 13.7669V23.3071C9 24.2318 9.74583 24.9808 10.6667 24.9808H25.3333C26.2542 24.9808 27 24.2318 27 23.3071V13.7669C27 12.8422 26.2542 12.0932 25.3333 12.0932ZM18 21.6333C16.1583 21.6333 14.6667 20.1354 14.6667 18.2859C14.6667 16.4365 16.1583 14.9385 18 14.9385C19.8417 14.9385 21.3333 16.4365 21.3333 18.2859C21.3333 20.1354 19.8417 21.6333 18 21.6333ZM16 18.2859C16 18.8186 16.2107 19.3295 16.5858 19.7061C16.9609 20.0828 17.4696 20.2944 18 20.2944C18.5304 20.2944 19.0391 20.0828 19.4142 19.7061C19.7893 19.3295 20 18.8186 20 18.2859C20 17.7533 19.7893 17.2424 19.4142 16.8657C19.0391 16.4891 18.5304 16.2775 18 16.2775C17.4696 16.2775 16.9609 16.4891 16.5858 16.8657C16.2107 17.2424 16 17.7533 16 18.2859Z"
        fill="#561AD9"
      />
    </svg>
  </mat-icon>
</div>
