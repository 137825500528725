import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentsHistory, UserFeedbacks } from 'src/types/Appointment';
import { RateAppointmentModalComponent } from '../rate-appointment-modal/rate-appointment-modal.component';
import { AppointmentHistoryCommentModalComponent } from '../appointment-history-comment-modal/appointment-history-comment-modal.component';

@Component({
  selector: 'appointment-history-card',
  templateUrl: './appointment-history-card.component.html',
  styleUrls: ['./appointment-history-card.component.scss'],
})
export class AppointmentHistoryCardComponent implements OnInit {
  @Input({ required: true }) appointment!: AppointmentsHistory;
  readonly UserFeedbacksystemCodes = {
    client: 'Rate',
    admin: 'Comment',
  };
  date: string = '';

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.date = new Date(this.appointment.StartDateTime).toLocaleString(
      'es-ES',
      {
        dateStyle: 'long',
      }
    );
  }

  openRateAppointmentDialog(appointmentId: number) {
    this.dialog.open(RateAppointmentModalComponent, {
      data: {
        appointmentId,
      },
    });
  }

  openAppointmentCommentDialog(
    placeName: string,
    userFeedbacks: UserFeedbacks[]
  ) {
    const comment = this.getCommentBySystemCode(
      userFeedbacks,
      this.UserFeedbacksystemCodes.admin
    );

    this.dialog.open(AppointmentHistoryCommentModalComponent, {
      data: {
        placeName,
        comment: comment!.Description,
      },
    });
  }

  getCommentBySystemCode(userFeedbacks: UserFeedbacks[], systemCode: string) {
    const commentResponse = userFeedbacks.find(
      (feedback) => feedback.SystemCode.Value === systemCode
    );
    return commentResponse;
  }
}
