<div *ngIf="isAuthenticated">
  <div *ngIf="appointmentsQuery.valueChanges | async as appointments">
    <section *ngIf="appointments.data.appointmentsUpcomming.data.length > 0">
      <h2>Próximas Citas</h2>
      <carousel
        [appointments]="appointments.data.appointmentsUpcomming.data"
      ></carousel>
    </section>
  </div>
</div>
