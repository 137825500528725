import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PwaUpdateService } from 'src/app/services/pwa-update.service';

@Component({
  selector: 'sw-update-modal',
  templateUrl: './sw-update-modal.component.html',
  styleUrl: './sw-update-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SwUpdateModalComponent {
  constructor(public dialogRef: MatDialogRef<SwUpdateModalComponent>) {}

  onUpdate() {
    this.dialogRef.close({ shouldApplyUpdate: true });
  }
}
