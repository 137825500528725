import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MenuItem } from '../sidenav/menu/menu.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() menuToggler?: MatDrawer;
  @Input() isMobileScreen: boolean = false;
  @Input() menuSelectedItem: MenuItem = <MenuItem>{};
  authSubscription = new Subscription();
  isAuthenticated: boolean = false;
  isOnSettingsView: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authSubscription = this.authenticationService
      .isAuthenticated()
      .subscribe((user) => {
        this.isAuthenticated = !!user;
      });

    this.activatedRoute.url.subscribe((segment) => {
      const path = segment[0] ? segment[0].path : null;
      if (path) {
        this.isOnSettingsView = path === 'profile';
      }
    });
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  onHandleMenuToggler() {
    this.menuToggler?.toggle();
  }

  goToLogin() {
    this.router.navigate(['/sign-in'], {
      queryParams: { redirectTo: this.router.url },
    });
  }

  onDeleteAccount() {
    this.authenticationService.deactivateAccount();
  }

  goBack() {
    this.location.back();
  }
}
