<sidenav>
  <section *ngIf="!appointmentsService.isLoading" class="divider">
    <div @fadeInComponents *ngIf="specialties" class="divider-left">
      <h1>Seleccionar tipo de cita</h1>
      <div class="specialties-buttons-list">
        <button
          *ngFor="let specialty of specialties"
          mat-flat-button
          contrastTextColor
          [style.background-color]="specialty.Color"
          [darkenBorderColor]="
            appointmentsService.selectedSpecialtyIdByParam === specialty.Id
              ? specialty.Color
              : 'transparent'
          "
          (click)="onSelectSpecialty(specialty)"
        >
          {{ specialty.Name }}
        </button>
      </div>
      <div #calendarWrapper>
        <calendar></calendar>
      </div>
    </div>
    <div class="divider-right">
      <specialty-description-box></specialty-description-box>
      <schedule-time-slot-selector
        *ngIf="appointmentsService.dateSelected"
      ></schedule-time-slot-selector>
      <button
        *ngIf="appointmentsService.dateSelected"
        [disabled]="
          appointmentsService.selectedHour === null ||
          appointmentsService.isButtonLoading
        "
        class="schedule-button"
        mat-flat-button
        color="accent"
        (click)="appointmentsService.manageAppointmentScheduling()"
      >
        <span
          *ngIf="
            !appointmentsService.isButtonLoading &&
            !appointmentsService.appointmentIdByParam
          "
          >Agendar</span
        >
        <span
          *ngIf="
            !appointmentsService.isButtonLoading &&
            appointmentsService.appointmentIdByParam
          "
          >Reagendar</span
        >
        <mat-spinner
          *ngIf="appointmentsService.isButtonLoading"
          diameter="35"
          color="accent"
        ></mat-spinner>
      </button>
    </div>
  </section>
</sidenav>
