import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFromString',
})
export class TimeFromStringPipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date(`Jan 1, 1970 ${value}`);
    const timeFormatted = date.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return timeFormatted.toLowerCase();
  }
}
