import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppointmentsService } from 'src/app/services/appointments.service';
@Component({
  selector: 'custom-maps',
  templateUrl: './custom-maps.component.html',
  styleUrl: './custom-maps.component.scss',
})
export class CustomMapsComponent implements OnInit {
  @ViewChild('ngxPlaces') placesRef!: NgxGpAutocompleteDirective;
  id: number = 0;
  options = {
    componentRestrictions: {
      country: ['CR'],
    },
  };
  mapOptions = {
    center: {
      lat: 9.939532,
      lng: -84.088045,
    },
    zoom: 8,
  };
  marker: any = null;
  constructor(private _apppointmentService: AppointmentsService) {}
  ngOnInit(): void {
    if (this._apppointmentService.location) {
      this.marker = {
        position: {
          lat: this._apppointmentService.location.lat,
          lng: this._apppointmentService.location.lng,
        },
        title: `Marcador en: ${this._apppointmentService.location.lat}, ${this._apppointmentService.location.lng}`,
      };
      this.id = this._apppointmentService.location.id;
    }
  }
  onMapClick(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      this.marker = {
        position: {
          lat,
          lng,
        },
        title: `Marcador en: ${lat}, ${lng}`,
      };
      this._apppointmentService.location = {
        id: this.id,
        lat,
        lng,
      };
    }
  }
  public handleAddressChange(place: google.maps.places.PlaceResult) {
    this._apppointmentService.location = {
      id: this.id,
      lat: Number(place.geometry?.location?.lat()),
      lng: Number(place.geometry?.location?.lng()),
    };
    this.marker = {
      position: {
        lat: Number(place.geometry?.location?.lat()),
        lng: Number(place.geometry?.location?.lng()),
      },
      title: `Marcador en: ${place.geometry?.location?.lat()}, ${place.geometry?.location?.lng()}`,
    };
  }
}
