import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CalendarOptions, DateSelectArg } from '@fullcalendar/core';
import { Subscription } from 'rxjs';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { AppointmentsService } from 'src/app/services/appointments.service';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None, // No encapsulation to be able to style full-calendar component
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly dayInMilliseconds: number = 86400000;
  breakpointObserverSubscription = new Subscription();
  @ViewChild('calendar') calendarRef!: FullCalendarComponent;
  selectedDay: DateSelectArg = <DateSelectArg>{};
  workingDays: number[] = [];

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    locale: esLocale,
    businessHours: {
      daysOfWeek: [],
    },
    selectConstraint: {
      daysOfWeek: [],
    },
    validRange: {
      start: Date.now(),
    },
    contentHeight: '70vh',
    initialView: 'dayGridMonth',
    selectable: true,
    selectLongPressDelay: 0,
    unselectAuto: false,
    select: this.selectDayOnCalendar.bind(this),
    dayHeaderFormat: { weekday: 'long' },
    headerToolbar: {
      start: 'title',
      end: 'prev,next',
    },
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private appointmentsService: AppointmentsService
  ) {}

  ngOnInit(): void {
    this.breakpointObserverSubscription = this.breakpointObserver
      .observe('(max-width: 1200px)')
      .subscribe((breakpoint: BreakpointState) => {
        if (breakpoint.matches) {
          this.calendarOptions.dayHeaderFormat = { weekday: 'short' };
          this.calendarOptions.contentHeight = '70vh';
        } else {
          this.calendarOptions.dayHeaderFormat = { weekday: 'long' };
          this.calendarOptions.contentHeight = '80vh';
        }
      });
  }

  ngAfterViewInit(): void {
    this.appointmentsService.currentSpecialty
      .asObservable()
      .subscribe((specialty) => {
        if (specialty) {
          let scheduleDays: string[] = [];
          specialty.Schedule.ScheduleDetails.map((days) => {
            scheduleDays.push(days.Day);
          });
          let uniqueDays: string[] = [];
          if (scheduleDays.length > 0) {
            uniqueDays = scheduleDays!.filter((day, index, daysArray) => {
              return daysArray.indexOf(day) === index;
            });
            this.workingDays = this.parseDaysToNumbers(uniqueDays);
            this.calendarOptions.businessHours = {
              daysOfWeek: this.workingDays,
            };
            this.calendarOptions.selectConstraint = {
              daysOfWeek: this.workingDays,
            };
            /* Next lines will unselect the previous selected day visually in the calendar component. 
          The line is wrapped in a try-catch because there is a type error internally within the FullCalendar 
          library that sometimes triggers when angular creates the reference to the calendar.
          */
            try {
              this.calendarRef.getApi().unselect();
            } catch {}
          }
        }
      });
  }

  selectDayOnCalendar(daySelectedData: DateSelectArg) {
    const DifferenceBetweenDaysInMs =
      daySelectedData.end.getTime() - daySelectedData.start.getTime();

    if (DifferenceBetweenDaysInMs > this.dayInMilliseconds) {
      this.calendarRef.getApi().unselect();

      if (this.selectedDay.startStr) {
        this.calendarRef.getApi().select(this.selectedDay.startStr);
      }
      return;
    }

    this.selectedDay = daySelectedData;
    this.appointmentsService.onDateSelected(this.selectedDay.startStr);
  }

  parseDaysToNumbers(days: string[]) {
    let numberedDays: number[] = [];
    days.map((day) => {
      switch (day.toLowerCase()) {
        case 'lunes':
          numberedDays.push(1);
          break;
        case 'martes':
          numberedDays.push(2);
          break;
        case 'miercoles':
          numberedDays.push(3);
          break;
        case 'jueves':
          numberedDays.push(4);
          break;
        case 'viernes':
          numberedDays.push(5);
          break;
        case 'sabado':
          numberedDays.push(6);
          break;
        case 'domingo':
          numberedDays.push(0);
          break;
      }
    });
    return numberedDays;
  }

  ngOnDestroy(): void {
    this.breakpointObserverSubscription.unsubscribe();
  }
}
