import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform(value: any[], callbackFn: (...args: any) => boolean): any[] {
    return value.filter(callbackFn);
  }

}
