<section
  *ngIf="specialty.SpecialtyFiles.length > 0"
  class="specialty-card-swiper"
  [class.specialty-card-swiper-opened]="isOpened"
  (click)="onContainerClick($event)"
>
  <button
    mat-icon-button
    class="specialty-card-swiper-toggle"
    [class.specialty-card-swiper-toggle-opened]="isOpened"
    [style.background-color]="isOpened ? '#bfbfbfc2' : ''"
    [matTooltip]="!isOpened ? 'Ver Galería' : ''"
    matTooltipPosition="right"
    aria-label="toggler icon"
    (click)="onToggleView()"
  >
    <mat-icon
      [style.transform]="isOpened ? 'rotate(180deg)' : 'rotate(0deg)'"
      [style.color]="specialty.Color"
      aria-hidden="false"
      aria-label="toggler icon"
      fontIcon="keyboard_arrow_down"
    ></mat-icon>
  </button>

  <!-- Swiper collapsable/expandable container -->
  <section class="specialty-card-swiper-expandable-container">
    <swiper-container #swiper [style]="navigationCssVariables">
      <swiper-slide
        *ngFor="let specialtyPicture of specialty.SpecialtyFiles"
        [lazy]="true"
      >
        <mat-card>
          <mat-card-content class="swiper-card">
            <img [src]="specialtyPicture.Url" [alt]="specialtyPicture.Name" />
          </mat-card-content>
        </mat-card>
      </swiper-slide>
    </swiper-container>
  </section>
</section>
