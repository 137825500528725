<section
  @fadeInComponent
  *ngIf="selectedAppointmentTime"
  #appointmentInfoWrapper
  class="appointment-info"
>
  <h2 class="appointment-info-title">Detalles</h2>
  <div
    class="appointment-info-row"
    *ngIf="placeQuery!.valueChanges | async as placeDetails"
  >
    <h3>Lugar</h3>
    <p>{{ placeDetails.data.getPlace.Name }}</p>
    <p>{{ placeDetails.data.getPlace.Address }}</p>
  </div>
  <div
    class="appointment-info-row"
    *ngIf="currentUser.asObservable() | async as user"
  >
    <h3>Información Personal</h3>
    <p>{{ user.displayName }}</p>
    <p>{{ user.email }}</p>
    <p>{{ user.phoneNumber }}</p>
  </div>
  <div class="appointment-info-row">
    <h3>Tipo de Cita</h3>
    <p>{{ specialtyName }}</p>
    <h3>Fecha</h3>
    <p>{{ appointmentsService.dateSelected | date : "dd/MM/yyyy" }}</p>
    <h3>Horario</h3>
    <p>
      {{
        appointmentsService.dateSelected + " " + selectedAppointmentTime
          | date : "hh:mm a"
      }}
    </p>
    <h3>Tiempo Estimado</h3>
    <p>{{ estimatedAppointmentTime | timeSuffix }}</p>
    <h3>Precio</h3>
    <p>{{ specialtyPrice }}</p>
  </div>
</section>
