<section class="sidenav-menu">
  <ul
    class="sidenav-menu-list"
    [class.sidenav-menu-list-desktop]="!isMobileScreen"
  >
    <menu-items
      [menuItems]="menuItems"
      [isMobileScreen]="isMobileScreen"
      (notifySelectedItem)="onNotifySelectedItem($event)"
    ></menu-items>
  </ul>
  <div class="register-company-wrapper">
    <register-company-button></register-company-button>
  </div>
</section>
