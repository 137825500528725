import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfilePictureComponent implements AfterContentInit {
  constructor(private dialog?: MatDialog) {}
  @Input() profilePicture: string = '';
  @Output() selectedImageAsFile = new EventEmitter<File>();
  selectedFile: File = new File([], '');
  image: string = '/assets/images/default-picture.png';

  ngAfterContentInit(): void {
    if (this.profilePicture.length > 0) {
      this.image = this.profilePicture;
    }
  }

  onImageSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.image = URL.createObjectURL(event.target.files[0]);
      this.selectedFile = event.target.files[0];
      this.selectedImageAsFile.emit(this.selectedFile);
    }
  }
}
