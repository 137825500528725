import { transition, trigger, useAnimation } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeAnimation } from 'src/app/animations';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { ScheduleBySpecialty } from 'src/types/Specialty';

interface TimeSlot {
  id: number;
  hour: string;
  isAvailable: boolean;
}

@Component({
  selector: 'schedule-time-slot-selector',
  templateUrl: './schedule-time-slot-selector.component.html',
  styleUrls: ['./schedule-time-slot-selector.component.scss'],
  animations: [
    trigger('fadeInComponent', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: { time: '300ms 300ms', start: 0, end: 1 },
        }),
      ]),
    ]),
  ],
})
export class ScheduleTimeSlotSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('timeSlotSelectorWrapper')
  timeSlotSelectorWrapper?: ElementRef<HTMLElement>;

  DateSelectedSubscription = new Subscription();
  schedulesAvailableBySpecialtySubscription = new Subscription();

  isLoading: boolean = true;
  selectedTimeSlot: TimeSlot | null = null;
  currentDateSelected: string | null = null;
  schedules: {
    morning: TimeSlot[];
    noon: TimeSlot[];
    night: TimeSlot[];
  } = {
    morning: [],
    noon: [],
    night: [],
  };
  scheduleShowAmountOfSlots: {
    allMorningSlots: boolean;
    allNoonSlots: boolean;
    allNightSlots: boolean;
  } = {
    allMorningSlots: false,
    allNoonSlots: false,
    allNightSlots: false,
  };

  constructor(public appointmentsService: AppointmentsService) {}

  ngOnInit(): void {
    this.DateSelectedSubscription =
      this.appointmentsService.dateSelectedListener.subscribe(
        (dateSelected) => {
          if (dateSelected) {
            this.currentDateSelected = dateSelected;
            this.resetSchedules();
            setTimeout(() => {
              this.timeSlotSelectorWrapper?.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }, 100);
            this.schedulesAvailableBySpecialtySubscription =
              this.appointmentsService
                .schedulesAvailableBySpecialtyQuery(dateSelected)
                .valueChanges.subscribe((schedules) => {
                  if (schedules.data.getSchedulesAvailableBySpecialty.success) {
                    if (
                      this.schedules.morning.length > 0 ||
                      this.schedules.noon.length > 0 ||
                      this.schedules.night.length > 0
                    ) {
                      this.resetSchedules();
                    }

                    let uniqueId = 0;
                    const checkedSchedules =
                      this.checkSelectedDateSchedulesExactTime(
                        schedules.data.getSchedulesAvailableBySpecialty.data
                      );
                    checkedSchedules.map((daySchedule) => {
                      const timeSlot = { ...daySchedule, id: ++uniqueId };

                      if (+daySchedule.hour.slice(0, 2) < 12) {
                        this.schedules.morning.push(timeSlot);
                      } else if (+daySchedule.hour.slice(0, 2) >= 18) {
                        this.schedules.night.push(timeSlot);
                      } else {
                        this.schedules.noon.push(timeSlot);
                      }
                      /* If an appointment is pending to schedule, 
                       and there was a previously selected hour in the appointment service,
                       then mark the time slot as selected:*/
                      if (
                        this.appointmentsService
                          .isPendingAppointmentToSchedule &&
                        timeSlot.hour === this.appointmentsService.selectedHour
                      ) {
                        this.selectTimeSlot(timeSlot);
                      }
                    });
                    this.isLoading = false;
                  } else {
                    setTimeout(() => {
                      this.timeSlotSelectorWrapper?.nativeElement.scrollIntoView(
                        {
                          behavior: 'smooth',
                          block: 'center',
                        }
                      );
                    }, 100);
                    this.isLoading = false;
                  }
                });
          }
        }
      );
  }

  ngAfterViewInit(): void {
    this.appointmentsService.subscribeToMoreSchedulesAvailableBySpecialty();
  }

  subscribeToUpdatedTimeSlots() {}

  selectTimeSlot(selectedTimeSlotItem: TimeSlot) {
    this.selectedTimeSlot = selectedTimeSlotItem;
    this.appointmentsService.onTimeSlotSelected(this.selectedTimeSlot.hour);
    // this.notifySelectedTimeSlot.next(this.selectedTimeSlot.hour);
  }

  resetSchedules() {
    this.isLoading = true;
    this.schedules = {
      morning: [],
      noon: [],
      night: [],
    };
    if (!this.appointmentsService.isPendingAppointmentToSchedule) {
      this.appointmentsService.onTimeSlotSelected(null);
      this.selectedTimeSlot = null;
    }
  }

  toggleAmountOfTimeSlots(scheduleTime: 'morning' | 'noon' | 'night') {
    switch (scheduleTime) {
      case 'morning':
        this.scheduleShowAmountOfSlots.allMorningSlots =
          !this.scheduleShowAmountOfSlots.allMorningSlots;
        break;
      case 'noon':
        this.scheduleShowAmountOfSlots.allNoonSlots =
          !this.scheduleShowAmountOfSlots.allNoonSlots;
        break;
      case 'night':
        this.scheduleShowAmountOfSlots.allNightSlots =
          !this.scheduleShowAmountOfSlots.allNightSlots;
        break;
    }
  }

  checkSelectedDateSchedulesExactTime(schedules: ScheduleBySpecialty[]) {
    const dateOptions: Intl.DateTimeFormatOptions = {
      dateStyle: 'short',
      timeZone: 'UTC',
    };

    const currentRawDate = new Date(Date.now());
    const currentFormattedDate = currentRawDate.toLocaleString(
      'es-ES',
      dateOptions
    );
    const selectedCalendarDate = new Date(
      this.currentDateSelected!
    ).toLocaleString('es-ES', dateOptions);

    if (selectedCalendarDate === currentFormattedDate) {
      const newSchedules = schedules.map((timeSlot) => {
        let newTimeSlot = <ScheduleBySpecialty>{};

        const [hours, minutes] = timeSlot.hour.split(':');
        const timeSlotExactDateTime = new Date(currentRawDate);
        timeSlotExactDateTime.setHours(+hours);
        timeSlotExactDateTime.setMinutes(+minutes);

        if (timeSlotExactDateTime.getTime() <= currentRawDate.getTime()) {
          newTimeSlot = { ...timeSlot, isAvailable: false };
        } else {
          newTimeSlot = timeSlot;
        }
        return newTimeSlot;
      });

      return newSchedules;
    }

    return schedules;
  }

  ngOnDestroy(): void {
    this.DateSelectedSubscription.unsubscribe();
    this.schedulesAvailableBySpecialtySubscription.unsubscribe();
  }
}
