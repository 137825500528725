import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, map } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserInfo } from 'src/types/User';

export interface MenuItem {
  id: number;
  title: string;
  path: string;
  icon: string;
  isSvgIcon?: boolean;
  iconColor: 'accent';
  textColor: 'var(--accent)';
  isMenuItem: boolean;
  shouldBeDisabled?: boolean;
}

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() isMobileScreen: boolean = false;
  @Output() notifySelectedItem = new EventEmitter<MenuItem>();
  authSubscription = new Subscription();
  USER_STATUS_ICON = `
  <svg
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="menu-icon"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.94444 8.55556C6.94444 7.08213 7.52976 5.66905 8.57163 4.62718C9.6135 3.58532 11.0266 3 12.5 3C13.9734 3 15.3865 3.58532 16.4284 4.62718C17.4702 5.66905 18.0556 7.08213 18.0556 8.55556C18.0556 10.029 17.4702 11.4421 16.4284 12.4839C15.3865 13.5258 13.9734 14.1111 12.5 14.1111C11.0266 14.1111 9.6135 13.5258 8.57163 12.4839C7.52976 11.4421 6.94444 10.029 6.94444 8.55556ZM6.94444 16.8889C5.10266 16.8889 3.33632 17.6205 2.03398 18.9229C0.731645 20.2252 0 21.9916 0 23.8333C0 24.9384 0.438987 25.9982 1.22039 26.7796C2.00179 27.561 3.0616 28 4.16667 28H20.8333C21.9384 28 22.9982 27.561 23.7796 26.7796C24.561 25.9982 25 24.9384 25 23.8333C25 21.9916 24.2684 20.2252 22.966 18.9229C21.6637 17.6205 19.8973 16.8889 18.0556 16.8889H6.94444Z"
      fill="currentColor"
    />
    <circle
      cx="3.5"
      cy="3.5"
      r="3.5"
      transform="matrix(1 0 0 -1 20 7)"
      fill="#E20000"
    />
  </svg>
`;
  USER_ICON = `
   <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.94444 5.55556C6.94444 4.08213 7.52976 2.66905 8.57163 1.62718C9.6135 0.585316 11.0266 0 12.5 0C13.9734 0 15.3865 0.585316 16.4284 1.62718C17.4702 2.66905 18.0556 4.08213 18.0556 5.55556C18.0556 7.02898 17.4702 8.44206 16.4284 9.48393C15.3865 10.5258 13.9734 11.1111 12.5 11.1111C11.0266 11.1111 9.6135 10.5258 8.57163 9.48393C7.52976 8.44206 6.94444 7.02898 6.94444 5.55556ZM6.94444 13.8889C5.10266 13.8889 3.33632 14.6205 2.03398 15.9229C0.731645 17.2252 0 18.9916 0 20.8333C0 21.9384 0.438987 22.9982 1.22039 23.7796C2.00179 24.561 3.0616 25 4.16667 25H20.8333C21.9384 25 22.9982 24.561 23.7796 23.7796C24.561 22.9982 25 21.9384 25 20.8333C25 18.9916 24.2684 17.2252 22.966 15.9229C21.6637 14.6205 19.8973 13.8889 18.0556 13.8889H6.94444Z"
      fill="currentColor"
    />
  </svg>
`;

  constructor(
    private authenticationService: AuthenticationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'user-status',
      sanitizer.bypassSecurityTrustHtml(this.USER_STATUS_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      'user-custom',
      sanitizer.bypassSecurityTrustHtml(this.USER_ICON)
    );
  }

  menuItems: MenuItem[] = [
    {
      id: 1,
      title: 'Página Principal',
      path: '/',
      icon: 'home',
      iconColor: 'accent',
      textColor: 'var(--accent)',
      isMenuItem: true,
    },
    {
      id: 2,
      title: 'Mis Citas',
      path: '/appointments',
      icon: 'event_note',
      iconColor: 'accent',
      textColor: 'var(--accent)',
      isMenuItem: true,
      shouldBeDisabled: false,
    },
    {
      id: 3,
      title: 'Perfil',
      path: '/profile',
      icon: 'user-custom',
      isSvgIcon: true,
      iconColor: 'accent',
      textColor: 'var(--accent)',
      isMenuItem: true,
      shouldBeDisabled: false,
    },
    {
      id: 4,
      title: 'Empresa',
      path: '/company',
      icon: 'apartment',
      iconColor: 'accent',
      textColor: 'var(--accent)',
      isMenuItem: false,
    },
    {
      id: 5,
      title: 'Agendar Cita',
      path: '/schedule-appointment',
      icon: 'event_note',
      iconColor: 'accent',
      textColor: 'var(--accent)',
      isMenuItem: false,
    },
    {
      id: 6,
      title: 'Historial',
      path: '/appointment-history',
      icon: 'history',
      iconColor: 'accent',
      textColor: 'var(--accent)',
      isMenuItem: true,
      shouldBeDisabled: false,
    },
    {
      id: 7,
      title: 'Sugerencias del App',
      path: '/feedback',
      icon: 'reviews',
      iconColor: 'accent',
      textColor: 'var(--accent)',
      isMenuItem: true,
      shouldBeDisabled: false,
    },
  ];

  ngOnInit(): void {
    this.authSubscription = this.authenticationService
      .isAuthenticated()
      .pipe(map((user) => !!user))
      .subscribe((isAuthenticated) => {
        this.menuItems.map((item) => {
          if (Object.hasOwn(item, 'shouldBeDisabled')) {
            if (!isAuthenticated) {
              item.shouldBeDisabled = true;
            } else {
              item.shouldBeDisabled = false;
            }
          }
        });
        this.authenticationService.userProfile
          .asObservable()
          .subscribe((user) => {
            const profileItemIndex = this.menuItems.findIndex((item) =>
              item.path.includes('/profile')
            );
            if (user) {
              if (this.shouldUserCompleteProfile(user)) {
                this.menuItems[profileItemIndex].icon = 'user-status';
              } else {
                this.menuItems[profileItemIndex].icon = 'user-custom';
              }
            } else {
              this.menuItems[profileItemIndex].icon = 'user-custom';
            }
          });
      });
  }

  onNotifySelectedItem(item: MenuItem) {
    this.notifySelectedItem.emit(item);
  }

  shouldUserCompleteProfile(userProfile: UserInfo) {
    if (userProfile.FirstName.length > 0 && userProfile.LastName.length > 0) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }
}
