import { Component, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'geolocation-filter',
  templateUrl: './geolocation-filter.component.html',
  styleUrls: ['./geolocation-filter.component.scss']
})
export class GeolocationFilterComponent {
  @Output() userAgentLocation = new Subject<GeolocationCoordinates | null>();
  locationFlags = {
    isLocationActive: false,
    isPendingLocation: false
  }
  buttonTooltip: 'Activar' | 'Desactivar' = 'Activar';

  constructor(private snackBar: MatSnackBar) { }

  onFilterByLocation() {
    if (!this.locationFlags.isLocationActive) {
      this.locationFlags.isPendingLocation = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((location) => {
          this.userAgentLocation.next(location.coords);
          this.locationFlags.isLocationActive = true;
          this.locationFlags.isPendingLocation = false;
          this.buttonTooltip = 'Desactivar'
          this.showSnackbar('Compartiendo ubicación');
        }, (err) => {
          this.locationFlags.isPendingLocation = false;
          this.showSnackbar('La ubicación no pudo ser compartida');
        })

      } else {
        this.showSnackbar('Geolocalización no soportada');
      }
    } else {
      this.userAgentLocation.next(null);
      this.locationFlags.isLocationActive = false;
      this.buttonTooltip = 'Activar'
      this.showSnackbar('La ubicación no está siendo compartida');
    }
  }

  showSnackbar(message: string) {
    this.snackBar.open(message, 'Entendido', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 5000,
      panelClass: 'primary-snackbar'
    })
  }

}
