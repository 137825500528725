import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _isLoading: boolean = false;
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    this._isLoading
  );
  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor() {}

  loadingListener() {
    return this.loadingSubject.asObservable();
  }

  setLoading(value: boolean) {
    this._isLoading = value;
    this.loadingSubject.next(this._isLoading);
  }
}
