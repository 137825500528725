<div mat-dialog-content>
  <button
    (click)="closeDialog()"
    mat-mini-fab
    color="accent"
    aria-label="Close modal button"
  >
    <mat-icon>close</mat-icon>
  </button>
  <img [src]="data.imageUrl" [alt]="data.name" />
</div>
