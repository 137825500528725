<!-- Desktop button -->
<a
  target="_blank"
  [href]="url"
  class="floating-button"
  [matTooltip]="!isAdmin ? 'Registra tu empresa' : 'Ir a tu empresa'"
  matTooltipPosition="left"
  mat-fab
  color="accent"
  aria-label="Botón de redirección para ir a crear una nueva empresa"
>
  <mat-icon>
    <svg
      width="35"
      height="35"
      [attr.viewBox]="!isAdmin ? '0 0 35 35' : '-2 4 45 35'"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        [attr.d]="
          !isAdmin
            ? 'M28.3621 31.2V26.28H23.4912V23H28.3621V18.08H31.6093V23H36.4803V26.28H31.6093V31.2H28.3621ZM2.38389 26.28V16.44H0.760254V13.16L2.38389 4.96004H26.7384L28.3621 13.16V16.44H26.7384V21.36H23.4912V16.44H16.9966V26.28H2.38389ZM5.63116 23H13.7493V16.44H5.63116V23ZM2.38389 3.32004V0.0400391H26.7384V3.32004H2.38389ZM4.08871 13.16H25.0336L24.0594 8.24004H5.06289L4.08871 13.16Z'
            : 'M2.13636 34.5263V21.5789H0V17.2632L2.13636 6.47368H34.1818L36.3182 17.2632V21.5789H34.1818V34.5263H29.9091V21.5789H21.3636V34.5263H2.13636ZM6.40909 30.2105H17.0909V21.5789H6.40909V30.2105ZM2.13636 4.31579V0H34.1818V4.31579H2.13636ZM4.37955 17.2632H31.9386L30.6568 10.7895H5.66136L4.37955 17.2632Z'
        "
        fill="#F0F0F0"
      />
    </svg>
  </mat-icon>
</a>
<!-- Mobile button -->
<a
  class="register-company-button"
  mat-fab
  extended
  color="accent"
  target="_blank"
  [href]="url"
>
  <mat-icon>
    <svg
      width="30"
      height="30"
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        [attr.d]="
          !isAdmin
            ? 'M28.3621 31.2V26.28H23.4912V23H28.3621V18.08H31.6093V23H36.4803V26.28H31.6093V31.2H28.3621ZM2.38389 26.28V16.44H0.760254V13.16L2.38389 4.96004H26.7384L28.3621 13.16V16.44H26.7384V21.36H23.4912V16.44H16.9966V26.28H2.38389ZM5.63116 23H13.7493V16.44H5.63116V23ZM2.38389 3.32004V0.0400391H26.7384V3.32004H2.38389ZM4.08871 13.16H25.0336L24.0594 8.24004H5.06289L4.08871 13.16Z'
            : 'M2.13636 34.5263V21.5789H0V17.2632L2.13636 6.47368H34.1818L36.3182 17.2632V21.5789H34.1818V34.5263H29.9091V21.5789H21.3636V34.5263H2.13636ZM6.40909 30.2105H17.0909V21.5789H6.40909V30.2105ZM2.13636 4.31579V0H34.1818V4.31579H2.13636ZM4.37955 17.2632H31.9386L30.6568 10.7895H5.66136L4.37955 17.2632Z'
        "
        fill="#F0F0F0"
      />
    </svg>
  </mat-icon>
  <span>{{ !isAdmin ? "Registra tu empresa" : "Ir a tu empresa" }}</span>
</a>
