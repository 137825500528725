import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appointmentStatus',
})
export class AppointmentStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'Pending':
        return 'Pendiente';
      case 'Approved':
        return 'Aprobada';
      default:
        return 'Rechazada';
    }
  }
}
