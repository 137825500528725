import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'radio-filter',
  templateUrl: './radio-filter.component.html',
  styleUrls: ['./radio-filter.component.scss'],
})
export class RadioFilterComponent {
  @Input({ required: true }) options!: { label: string; value: string }[];
  @Input({ required: true }) selectedOption!: { label: string; value: string };
  @Output() onChangeListener = new Subject<void>();
}
