import { gql } from 'apollo-angular';

export const GIVE_FEEDBACK_TO_APPOINTMENT = gql`
  mutation SaveUserFeedbackClientByUser($body: UserFeedbackClientInput!) {
    saveUserFeedbackClientByUser(body: $body) {
      success
      message
      messages
      statusCodeError
    }
  }
`;

export const GIVE_FEEDBACK_TO_APP = gql`
  mutation SaveUserFeedbackByUser($body: UserFeedbackInput!) {
    saveUserFeedbackByUser(body: $body) {
      success
      message
      messages
      statusCodeError
    }
  }
`;
