import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { MenuItem } from './menu/menu.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Apollo } from 'apollo-angular';
import { UserResponse } from 'src/types/User';
import { GET_USER } from 'src/app/graphql';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  breakpointSubscription = new Subscription();
  getUserSubscription = new Subscription();
  isMobileScreen: boolean = false;
  isResizing: boolean = false;
  menuSelectedItem: MenuItem = <MenuItem>{};

  constructor(
    public authenticationService: AuthenticationService,
    private apollo: Apollo,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.breakpointSubscription = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((size) => {
        this.isResizing = true;
        this.isMobileScreen = size.matches;
        setTimeout(() => {
          this.isResizing = false;
        }, 300);
      });

    this.authenticationService.isAuthenticated().subscribe((user) => {
      if (!!user) {
        this.getUserSubscription = this.apollo
          .watchQuery<UserResponse>({
            query: GET_USER,
          })
          .valueChanges.subscribe(({ data }) => {
            if (data.getUser.success) {
              const userData = data.getUser.data;
              if (
                data.getUser.data.FirstName.length > 0 &&
                data.getUser.data.FirstName.length > 0
              ) {
                this.authenticationService.userProfile.next({
                  ...userData,
                  DisplayName: `${userData.FirstName} ${userData.LastName}`,
                });
              } else {
                if (user.displayName) {
                  this.authenticationService.userProfile.next({
                    ...userData,
                    DisplayName: user.displayName,
                  });
                } else {
                  this.authenticationService.userProfile.next({
                    ...userData,
                    DisplayName: '',
                  });
                }
              }
            }
          });
      }
    });
  }

  onReceiveSelectedItem(item: MenuItem) {
    this.menuSelectedItem = item;
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
    this.getUserSubscription.unsubscribe();
  }
}
