import { gql } from 'apollo-angular';

export const GET_USER = gql`
  query GetUser {
    getUser {
      data {
        Id
        FirstName
        LastName
        Email
        PhoneNumber
        ProfilePictureUrl
        Place {
          Id
        }
      }
      success
      message
      messages
    }
  }
`;
