import { Component, Input } from '@angular/core';

@Component({
  selector: 'image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input({ required: true }) imgSrc!: string;
  @Input({ required: true }) imgAlt!: string;
  isImageLoading: boolean = true;

  onImageLoaded() {
    this.isImageLoading = false;
  }
}
