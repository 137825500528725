<section mat-dialog-content class="change-password-modal">
  <h1>Cambiar contraseña</h1>

  <mat-stepper linear color="accent">
    <mat-step [stepControl]="currentPassword" [editable]="false">
      <ng-template matStepLabel>Contraseña actual</ng-template>
      <form class="password-form">
        <mat-form-field>
          <input
            matInput
            [type]="!showCurrentPassword ? 'password' : 'text'"
            [formControl]="currentPassword"
          />

          <mat-label>Contraseña actual</mat-label>

          <mat-error
            *ngIf="currentPassword.invalid"
            [innerHTML]="getPasswordErrorMessage(currentPassword)"
          >
          </mat-error>

          <button
            type="button"
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="showCurrentPassword = !showCurrentPassword"
          >
            <mat-icon>{{
              showCurrentPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
        <div class="password-form-buttons">
          <button
            #stepperNextButton
            class="stepper-next-button"
            matStepperNext
          ></button>
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="onCancelClick()"
          >
            Cancelar
          </button>
          <button
            type="submit"
            mat-raised-button
            color="accent"
            [disabled]="isLoading"
            (click)="onCheckCurrentPassword(stepperNextButton)"
          >
            <mat-spinner
              *ngIf="isLoading"
              diameter="30"
              color="accent"
            ></mat-spinner>
            <span *ngIf="!isLoading">Siguiente</span>
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="changePasswordForm">
      <ng-template matStepLabel>Nueva contraseña</ng-template>
      <form class="password-form" [formGroup]="changePasswordForm">
        <mat-form-field>
          <input
            matInput
            [type]="!showPassword ? 'password' : 'text'"
            [formControl]="changePasswordForm.controls.password"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Nueva contraseña</mat-label>

          <mat-error
            *ngIf="changePasswordForm.controls.password.invalid"
            [innerHTML]="
              getPasswordErrorMessage(changePasswordForm.controls.password)
            "
          >
          </mat-error>

          <button
            type="button"
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="showPassword = !showPassword"
          >
            <mat-icon>{{
              showPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [type]="!showConfirmPassword ? 'password' : 'text'"
            [formControl]="changePasswordForm.controls.confirmPassword"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Confirmar contraseña</mat-label>

          <mat-error
            *ngIf="changePasswordForm.controls.confirmPassword.invalid || changePasswordForm.errors?.['mismatch']"
            [innerHTML]="getConfirmPasswordErrorMessage()"
          >
          </mat-error>

          <button
            type="button"
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="showConfirmPassword = !showConfirmPassword"
          >
            <mat-icon>{{
              showConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
        <div class="password-form-buttons">
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="onCancelClick()"
          >
            Cancelar
          </button>
          <button
            type="submit"
            mat-raised-button
            color="accent"
            [disabled]="isLoading"
            (click)="changePassword()"
          >
            <mat-spinner
              *ngIf="isLoading"
              diameter="30"
              color="accent"
            ></mat-spinner>
            <span *ngIf="!isLoading">Cambiar</span>
          </button>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</section>
