<auth-layout>
  <div class="container hide-scrollbar">
    <div class="dark-theme go-back-container">
      <button
        mat-icon-button
        color="primary"
        aria-label="Go back button"
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="form-container">
      <h1>Restablecer Contraseña</h1>

      <p>
        Ingresa el correo electrónico registrado para recuperar la contraseña y
        te enviaremos un correo con las instrucciones de recuperación de
        contraseña.
      </p>

      <form class="auth-form">
        <mat-form-field>
          <input
            type="text"
            matInput
            [formControl]="emailControl"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Ingresar correo electrónico</mat-label>

          <mat-error
            *ngIf="emailControl.invalid"
            [innerHTML]="getEmailErrorMessage()"
          >
          </mat-error>
        </mat-form-field>

        <button
          type="submit"
          mat-raised-button
          color="accent"
          [disabled]="isLoading"
          (click)="restorePassword()"
        >
          <mat-spinner
            *ngIf="isLoading"
            diameter="30"
            color="accent"
          ></mat-spinner>
          <span *ngIf="!isLoading">Enviar</span>
        </button>
      </form>
    </div>
  </div>
</auth-layout>
