import { Directive, ElementRef, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[intersectionObserver]'
})
export class IntersectionObserverDirective implements OnInit {
  @Input() options: IntersectionObserverInit = {};
  @Output() isIntersecting: EventEmitter<boolean> = new EventEmitter<boolean>();

  private observer?: IntersectionObserver;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isIntersecting.emit(true);
        } else {
          this.isIntersecting.emit(false);
        }
      });
    }, this.options);

    this.observer.observe(this.elementRef.nativeElement);
  }

}
