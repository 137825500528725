<!--<input id="search-box" type="text" placeholder="Buscar un lugar" />--->
<input #placesRef="ngx-places" ngx-gp-autocomplete [options]='options' (onAddressChange)="handleAddressChange($event)"  class="search-box"/>
<google-map
  #maps
  [options]="mapOptions"
  (mapClick)="onMapClick($event)"
  width="100%"
>
  <map-marker
    *ngIf="marker"
    [position]="marker.position"
    [title]="marker.title"
  ></map-marker
></google-map>
