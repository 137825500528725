import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from 'src/types/ErrorSateMatcher';
import { passwordPattern } from 'src/types/regex-pattern';
import { whiteSpacesValidator } from '../../RegisterPage/client-registration/client-registration.component';
import { matchingPasswords } from 'src/types/password-match-validator';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

interface restorePasswordFormGroup {
  password: FormControl;
  confirmPassword: FormControl;
}

@Component({
  selector: 'restore-password-form',
  templateUrl: './restore-password-form.component.html',
  styleUrls: ['./restore-password-form.component.scss'],
})
export class RestorePasswordFormComponent implements OnInit {
  matcher = new ErrorStateMatcher();
  restorePasswordForm: FormGroup<restorePasswordFormGroup> = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(passwordPattern),
        Validators.minLength(8),
        whiteSpacesValidator(),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(passwordPattern),
        Validators.minLength(8),
      ]),
    },
    {
      validators: [matchingPasswords],
    }
  );

  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isLoading: boolean = false;

  private accountEmail: string = '';
  private oobCode: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const mode = params.get('mode');
      const actionCode = params.get('oobCode');

      if (mode === 'resetPassword' && actionCode) {
        this.authenticationService
          .verifyPasswordReset(mode, actionCode)
          .then((verification) => {
            if (verification) {
              this.accountEmail = verification.accountEmail;
              this.oobCode = verification.actionCode;
            }
          });
      } else {
        // Navigate to home since this was not a BE-generated link
        this.router.navigate(['/'], { replaceUrl: true });
      }
    });
  }

  restorePassword() {
    if (this.restorePasswordForm.valid) {
      this.isLoading = true;
      this.authenticationService
        .handleResetPassword(
          this.oobCode,
          this.accountEmail,
          this.restorePasswordForm.value.password
        )
        .then(() => {
          this.isLoading = false;
        });
    }
  }

  getPasswordErrorMessage(passwordControl: FormControl) {
    if (passwordControl.hasError('required')) {
      return 'Contraseña <strong>requerida</strong>';
    }
    if (passwordControl.hasError('whiteSpaces')) {
      return '<strong>No debe contener espacios</strong>';
    }
    if (passwordControl.hasError('pattern')) {
      return '<strong>Utilice mayúsculas, minúsculas y al menos un número.</strong>';
    }
    if (passwordControl.hasError('minlength')) {
      return 'Debe tener <strong>al menos 8 caracteres.</strong>';
    }
    return '';
  }

  getConfirmPasswordErrorMessage() {
    if (
      this.restorePasswordForm.controls.confirmPassword.hasError('required')
    ) {
      return '<strong>Debe confirmar su contraseña</strong>';
    }
    if (this.restorePasswordForm.errors?.['mismatch']) {
      return 'Las contraseñas <strong>no coinciden</strong>';
    }
    return '';
  }

  goBack() {
    this.location.back();
  }
}
