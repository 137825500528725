<div class="paginator">
  <span *ngIf="pageNumber">Pág {{ pageNumber }}</span>

  <button
    type="button"
    color="primary"
    mat-mini-fab
    aria-label="Botón navegación página anterior"
    class="paginator-button"
    [disabled]="pageNumber === 1"
    (click)="previous.next()"
  >
    <mat-icon> navigate_before </mat-icon>
  </button>

  <button
    type="button"
    color="primary"
    mat-mini-fab
    aria-label="Botón navegación página siguiente"
    class="paginator-button"
    [disabled]="!nextIsEnabled"
    (click)="next.next()"
  >
    <mat-icon> navigate_next </mat-icon>
  </button>
</div>
