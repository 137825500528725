import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import { GIVE_FEEDBACK_TO_APPOINTMENT } from 'src/app/graphql';
import {
  GiveFeedbacktoAppointmentBody,
  GiveFeedbacktoAppointmentResponse,
} from 'src/types/Feedback';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { Router } from '@angular/router';

@Component({
  selector: 'rate-appointment-modal',
  templateUrl: './rate-appointment-modal.component.html',
  styleUrls: ['./rate-appointment-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RateAppointmentModalComponent {
  commentControl = new FormControl('');
  ratingScore: number = 0;

  constructor(
    public dialogRef: MatDialogRef<RateAppointmentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { appointmentId: number },
    private apollo: Apollo,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  onRated(rating: number) {
    this.ratingScore = rating;
  }

  onSubmit() {
    if (this.ratingScore === 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          warning: true,
          message:
            '¡Debe calificar su experiencia antes de enviar su evaluación!',
        },
        duration: 6000,
        panelClass: ['warning-snackbar'],
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
      return;
    }

    this.apollo
      .mutate<GiveFeedbacktoAppointmentResponse, GiveFeedbacktoAppointmentBody>(
        {
          mutation: GIVE_FEEDBACK_TO_APPOINTMENT,
          variables: {
            body: {
              appointmentId: this.data.appointmentId,
              description: this.commentControl.value!,
              rate: this.ratingScore,
            },
          },
        }
      )
      .subscribe((appointmentFeedbackResponse) => {
        if (
          appointmentFeedbackResponse.data?.saveUserFeedbackClientByUser.success
        ) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: {
              validation: true,
              message:
                appointmentFeedbackResponse.data.saveUserFeedbackClientByUser
                  .message,
            },
            duration: 3000,
            panelClass: ['success-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/appointment-history'], {
                replaceUrl: true,
              });
              this.dialogRef.close();
            });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: {
              message:
                '¡No se ha podido registrar correctamente su retroalimentación! Intente nuevamente.',
            },
            duration: 6000,
            panelClass: ['error-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        }
      });
  }
}
