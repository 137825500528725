import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Location } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { emailPattern } from 'src/types/regex-pattern';
import { ErrorStateMatcher } from 'src/types/ErrorSateMatcher';
import { whiteSpacesValidator } from '../../RegisterPage/client-registration/client-registration.component';

@Component({
  selector: 'restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
})
export class RestorePasswordComponent {
  constructor(
    private router: Router,
    private location: Location,
    private authenticationService: AuthenticationService,
    private snackbar: MatSnackBar
  ) {}

  emailControl = new FormControl('', [
    Validators.required,
    whiteSpacesValidator(),
    Validators.pattern(emailPattern),
  ]);
  matcher = new ErrorStateMatcher();
  isLoading: boolean = false;

  getEmailErrorMessage(): string {
    if (this.emailControl.hasError('required')) {
      return 'Correo electrónico <strong>requerido</strong>';
    }
    if (this.emailControl.hasError('whiteSpaces')) {
      return '<strong>No debe contener espacios</strong>';
    }
    if (this.emailControl.hasError('pattern')) {
      return 'Formato de correo electrónico <strong>inválido</strong>';
    }
    return '';
  }

  restorePassword() {
    if (this.emailControl.valid) {
      this.isLoading = true;
      const variables = {
        body: {
          email: this.emailControl.value,
          isAdmin: false,
        },
      };
      this.authenticationService
        .recoverPassword(variables)
        .subscribe((result) => {
          if (result.data?.resetPassword.success) {
            this.snackbar
              .openFromComponent(SnackbarComponent, {
                data: {
                  validation: !result.data?.resetPassword.success,
                  warning: result.data?.resetPassword.success,
                  message: result.data?.resetPassword.message,
                },
                duration: 3000,
                panelClass: ['warning-snackbar'],
                horizontalPosition: 'end',
                verticalPosition: 'top',
              })
              .afterOpened()
              .subscribe(() => {
                this.isLoading = false;
                this.router.navigate(['/sign-in']);
              });
          } else {
            this.isLoading = false;
            this.snackbar.openFromComponent(SnackbarComponent, {
              data: {
                validation: result.data?.resetPassword.success,
                warning: result.data?.resetPassword.success,
                message: result.data?.resetPassword.message,
              },
              duration: 6000,
              panelClass: ['error-snackbar'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
            });
          }
        });
    }
  }

  goBack() {
    this.location.back();
  }
}
