import { __extends } from "tslib";
var MissingFieldError = function (_super) {
  __extends(MissingFieldError, _super);
  function MissingFieldError(message, path, query, variables) {
    var _a;
    var _this = _super.call(this, message) || this;
    _this.message = message;
    _this.path = path;
    _this.query = query;
    _this.variables = variables;
    if (Array.isArray(_this.path)) {
      _this.missing = _this.message;
      for (var i = _this.path.length - 1; i >= 0; --i) {
        _this.missing = (_a = {}, _a[_this.path[i]] = _this.missing, _a);
      }
    } else {
      _this.missing = _this.path;
    }
    _this.__proto__ = MissingFieldError.prototype;
    return _this;
  }
  return MissingFieldError;
}(Error);
export { MissingFieldError };
