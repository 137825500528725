import { gql } from 'apollo-angular';

export const NOTIFICATION_APPOINTMENTS = gql`
  subscription OnNotificationAppointments {
    onNotificationAppointments {
      id
      date
      startTime
      endTime
      userName
      userEmail
      userPhoneNumber
      placeName
      placeAddress
      placeId
      specialtyId
      specialtyName
      specialtyTime
      systemCode
      previousSystemCode
    }
  }
`;
