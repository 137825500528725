import { newInvariantError } from "../../utilities/globals/index.js";
export var serializeFetchParameter = function (p, label) {
  var serialized;
  try {
    serialized = JSON.stringify(p);
  } catch (e) {
    var parseError = newInvariantError(37, label, e.message);
    parseError.parseError = e;
    throw parseError;
  }
  return serialized;
};
