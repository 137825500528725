<div mat-dialog-content class="rate-appointment">
  <button
    tabindex="-1"
    class="close-button"
    mat-icon-button
    color="primary"
    aria-label="Botón para cerrar modal"
    (click)="dialogRef.close()"
  >
    <mat-icon> close </mat-icon>
  </button>
  <h1>Evalúa tu cita</h1>
  <star-rating (rating)="onRated($event)"></star-rating>
  <p>Comentario <span>(Opcional)</span></p>
  <mat-form-field>
    <mat-label>Ingresa tu mensaje</mat-label>
    <textarea matInput [formControl]="commentControl" rows="4"></textarea>
  </mat-form-field>
  <button
    type="submit"
    mat-flat-button
    color="accent"
    class="rate-appointment-button"
    (click)="onSubmit()"
  >
    Enviar
  </button>
</div>
