export default function asyncIterator(source) {
  var _a;
  var iterator = source[Symbol.asyncIterator]();
  return _a = {
    next: function () {
      return iterator.next();
    }
  }, _a[Symbol.asyncIterator] = function () {
    return this;
  }, _a;
}
