import {
  MatSnackBarRef,
  MatSnackBarModule,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { Component, Input, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    CommonModule,
    FormsModule,
  ],
})
export class SnackbarComponent {
  constructor(
    public snackBar: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { validation: boolean; message: string; warning: boolean }
  ) {}

  @Input() showSuccessMessage: boolean = this.data.validation ?? false;
  @Input() showWarningMessage: boolean = this.data.warning ?? false;

  @Input() message: string = this.data.message;
  @Input() errorMessage: string = this.data.message;

  close() {
    this.snackBar.dismiss();
  }
}
