import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Apollo } from 'apollo-angular';
import { GET_USER } from 'src/app/graphql';
import { UserResponse } from 'src/types/User';

@Component({
  selector: 'register-company-button',
  templateUrl: './register-company-button.component.html',
  styleUrls: ['./register-company-button.component.scss'],
})
export class RegisterCompanyButtonComponent implements OnInit, OnDestroy {
  authSubscription = new Subscription();
  getUserSubscription = new Subscription();
  url = '';
  isAuthenticated: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private apollo: Apollo
  ) {}
  ngOnInit(): void {
    this.authSubscription = this.authenticationService
      .isAuthenticated()
      .subscribe((user) => {
        this.isAuthenticated = !!user;
        const token = this.cookieService.get('user_token') ?? null;

        if (this.isAuthenticated && token) {
          this.getUserSubscription = this.apollo
            .watchQuery<UserResponse>({
              query: GET_USER,
            })
            .valueChanges.subscribe(({ data }) => {
              if (data.getUser.success) {
                this.isAdmin =
                  !!data.getUser.data.Place && !!data.getUser.data.Place.Id;
                if (this.isAdmin) {
                  this.url = `${environment.adminApplicationBaseUrl}/admin/home`;
                } else {
                  this.url = `${environment.adminApplicationBaseUrl}/register-place/${token}`;
                }
              }
            });
        } else {
          this.url = `${environment.adminApplicationBaseUrl}/register`;
        }
      });
  }
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    this.getUserSubscription.unsubscribe();
  }
}
