import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { UpcomingAppointment } from 'src/types/Appointment';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent implements OnInit, AfterViewInit {
  slides: number[] = [1, 2, 3, 4, 5];
  @Input({ required: true }) appointments!: UpcomingAppointment[];
  @ViewChild('swiper', { static: false }) swiperRef!: ElementRef;

  upcomingAppointments: UpcomingAppointment[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.upcomingAppointments = this.appointments.filter((appointment) => {
      const appointmentDate = new Date(
        `${appointment.date}  ${appointment.startTime}`
      );
      if (appointmentDate.getTime() > Date.now()) {
        return appointment;
      }
      return;
    });
  }

  ngAfterViewInit(): void {
    // Configure Swiper with custom options
    const swiperConfiguration: SwiperOptions = {
      pagination: {
        clickable: true,
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
        },
        720: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
        1600: {
          slidesPerView: 4,
        },
      },
    };
    // Add configuration to the swiper element
    Object.assign(this.swiperRef.nativeElement, swiperConfiguration);
  }

  navigateToAppointments(appointment: number) {
    this.router.navigate(['appointments'], {
      queryParams: { appointment, status: 'approved' },
    });
  }
}
