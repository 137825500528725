import { gql } from 'apollo-angular';

export const GET_SPECIALTIES_BY_PLACE = gql`
  query Specialties($where: SpecialtyWhereInput) {
    specialties(where: $where) {
      Id
      Name
      Description
      Color
      ProfessionalName
      Price
      AppointmentTime
      IsHome
      Schedule {
        Name
        ScheduleDetails {
          Id
          Day
          StartTime
          EndTime
        }
      }
    }
  }
`;

export const getSpecialtiesByPlaceVariables = (placeId: number) => ({
  where: {
    AND: [
      {
        SystemCode: {
          is: {
            Value: {
              equals: 'Active',
            },
            Entity: {
              equals: 'Specialty',
            },
          },
        },
      },
      {
        Place: {
          is: {
            AND: [
              {
                SystemCode: {
                  is: {
                    Value: {
                      equals: 'Active',
                    },
                    Entity: {
                      equals: 'Place',
                    },
                  },
                },
              },
              {
                Id: {
                  equals: placeId,
                },
              },
            ],
          },
        },
      },
    ],
  },
});

export const GET_SCHEDULES_AVAILABLE_BY_SPECIALTY = gql`
  query GetSchedulesAvailableBySpecialty($date: String!, $specialtyId: Int!) {
    getSchedulesAvailableBySpecialty(date: $date, specialtyId: $specialtyId) {
      success
      message
      messages
      statusCodeError
      data {
        hour
        isAvailable
      }
    }
  }
`;

export const getSchedulesAvailableBySpecialtyVariables = (
  date: string,
  specialtyId: number
) => ({
  date,
  specialtyId,
});
