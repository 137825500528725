import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentDialogComponent } from 'src/app/components/content-dialog/content-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {
  Storage,
  StorageReference,
  deleteObject,
  ref,
  uploadBytes,
} from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Auth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  UserCredential,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { registerClientVariables } from 'src/types/Auth';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  alphaPattern,
  emailPattern,
  passwordPattern,
} from 'src/types/regex-pattern';
import { ErrorStateMatcher } from 'src/types/ErrorSateMatcher';
import { matchingPasswords } from 'src/types/password-match-validator';
import { Location } from '@angular/common';

interface RegistrationFormGroup {
  firstName: FormControl;
  lastName: FormControl;
  email: FormControl;
  phoneNumber: FormControl;
  password: FormControl;
  confirmPassword: FormControl;
  termsAndConditions: FormControl;
}

// It validates that the field contains more than white spaces
export function emptyValidator(): ValidatorFn {
  return (control: AbstractControl<string>): ValidationErrors | null => {
    return control.value.trim().length === 0 ? { isEmpty: true } : null;
  };
}

export function whiteSpacesValidator(): ValidatorFn {
  const containsWhiteSpacesRegex = /^(?=.*\s).*$/;
  return (control: AbstractControl<string>): ValidationErrors | null => {
    return containsWhiteSpacesRegex.test(control.value)
      ? { whiteSpaces: true }
      : null;
  };
}

// any is needed as type for this implementation to work properly:
const confirmPasswordValidator: any = (group: FormGroup) => {
  const password = group.controls['password'];
  const confirmPassword = group.controls['confirmPassword'];

  if (password.value !== confirmPassword.value) {
    confirmPassword.setErrors({ mismatch: true });
  } else {
    confirmPassword.setErrors(null);
  }
  return;
};

@Component({
  selector: 'app-client-registration',
  templateUrl: './client-registration.component.html',
  styleUrls: ['./client-registration.component.scss'],
})
export class ClientRegistrationComponent {
  private googleProvider = new GoogleAuthProvider();

  matcher = new ErrorStateMatcher();
  registrationForm: FormGroup<RegistrationFormGroup> = new FormGroup(
    {
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(alphaPattern),
        emptyValidator(),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(alphaPattern),
        emptyValidator(),
      ]),
      email: new FormControl('', [
        Validators.required,
        whiteSpacesValidator(),
        Validators.pattern(emailPattern),
      ]),
      phoneNumber: new FormControl<string>('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(passwordPattern),
        Validators.minLength(8),
        whiteSpacesValidator(),
      ]),
      confirmPassword: new FormControl(''),
      termsAndConditions: new FormControl(false, Validators.requiredTrue),
    },
    {
      validators: [confirmPasswordValidator],
    }
  );

  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isLoading: boolean = false;
  isGoogleLoading: boolean = false;

  myDate: Date = new Date();
  selectedFile: File = new File([], '');

  constructor(
    public termsContentDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private storage: Storage,
    private router: Router,
    private location: Location,
    private auth: Auth,
    private snackbar: MatSnackBar
  ) {}

  getNameErrorMessage(
    control: FormControl,
    controlDisplayName: string
  ): string {
    if (control.hasError('required') || control.hasError('isEmpty')) {
      return `${controlDisplayName} <strong>requerido</strong>`;
    }
    if (control.hasError('pattern')) {
      return 'Ingrese únicamente <strong>letras</strong>';
    }
    return '';
  }

  getEmailErrorMessage(): string {
    if (this.registrationForm.controls.email.hasError('required')) {
      return 'Correo electrónico <strong>requerido</strong>';
    }
    if (this.registrationForm.controls.email.hasError('whiteSpaces')) {
      return '<strong>No debe contener espacios</strong>';
    }
    if (this.registrationForm.controls.email.hasError('pattern')) {
      return 'Formato de correo electrónico <strong>inválido</strong>';
    }
    return '';
  }

  getPhoneNumberErrorMessage(): string {
    if (this.registrationForm.controls.phoneNumber.hasError('required')) {
      return 'Número telefónico <strong>requerido</strong>';
    }
    if (
      this.registrationForm.controls.phoneNumber.hasError('validatePhoneNumber')
    ) {
      return 'Ingrese un número telefónico <strong>válido</strong>';
    }
    return '';
  }

  getPasswordErrorMessage() {
    if (this.registrationForm.controls.password.hasError('required')) {
      return 'Contraseña <strong>requerida</strong>';
    }
    if (this.registrationForm.controls.password.hasError('whiteSpaces')) {
      return '<strong>No debe contener espacios</strong>';
    }
    if (this.registrationForm.controls.password.hasError('pattern')) {
      return '<strong>Utilice mayúsculas, minúsculas y al menos un número.</strong>';
    }
    if (this.registrationForm.controls.password.hasError('minlength')) {
      return 'Debe tener <strong>al menos 8 caracteres.</strong>';
    }
    return '';
  }

  getConfirmPasswordErrorMessage() {
    if (this.registrationForm.controls.confirmPassword.errors?.['mismatch']) {
      return 'Las contraseñas <strong>no coinciden</strong>';
    }
    return '';
  }

  showAcceptTermsAlert() {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: {
        warning: true,
        message: 'Debe aceptar los términos y condiciones para continuar',
      },
      duration: 3000,
      panelClass: ['warning-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  readTermsAndConditions() {
    this.termsContentDialog.open(ContentDialogComponent);
  }

  onSubmit() {
    if (this.registrationForm.valid) {
      this.isLoading = true;
      // Next line will upload the image profile and trigger the registration:
      this.uploadImageToFirebase();
    } else if (!this.registrationForm.controls.termsAndConditions.value) {
      this.showAcceptTermsAlert();
    }
  }

  storePictureAsFile(selectedFile: File) {
    this.selectedFile = selectedFile;
  }

  uploadImageToFirebase() {
    const registerVariables: registerClientVariables = {
      body: {
        profilePictureUrl: '',
        firstName: this.registrationForm.controls.firstName.value.trim(),
        lastName: this.registrationForm.controls.lastName.value.trim(),
        email: this.registrationForm.controls.email.value.trim(),
        phoneNumber: this.registrationForm.controls.phoneNumber.value.trim(),
        password: this.registrationForm.controls.password.value.trim(),
      },
    };

    if (this.selectedFile.name.length > 0) {
      const imageName = this.selectedFile.type.replace('/', '.');
      const imageRefPath = `${new Date().getTime()}_${imageName}`;
      const imageReference = ref(
        this.storage,
        `Profile Pictures/${imageRefPath}`
      );

      uploadBytes(imageReference, this.selectedFile)
        .then((uploadedImage) => {
          const imageUrl = `https://firebasestorage.googleapis.com/v0/b/${uploadedImage.ref.bucket}/o/Profile%20Pictures%2F${uploadedImage.ref.name}?alt=media`;
          registerVariables.body.profilePictureUrl = imageUrl;
          // Register after receiving the profile picture url from Firebase
          this.registerClient(registerVariables, imageReference);
        })
        .catch(() => {
          this.isLoading = false;
          this.snackbar.openFromComponent(SnackbarComponent, {
            data: {
              validation: false,
              warning: false,
              message:
                '¡Hubo un problema con el archivo adjunto! No se pudo completar el registro de la cuenta.',
            },
            duration: 2000,
            panelClass: ['error-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        });
    } else {
      this.registerClient(registerVariables);
    }
  }

  registerClient(
    registerVariables: registerClientVariables,
    uploadedPictureReference?: StorageReference
  ) {
    this.authenticationService
      .registerClient(registerVariables)
      .subscribe((registerResponse) => {
        if (registerResponse.data!.registerClient.success) {
          this.snackbar
            .openFromComponent(SnackbarComponent, {
              data: {
                validation: true,
                warning: false,
                message: registerResponse.data!.registerClient.message,
              },
              duration: 2000,
              panelClass: ['success-snackbar'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
            })
            .afterOpened()
            .subscribe(() => {
              signInWithEmailAndPassword(
                this.auth,
                this.registrationForm.controls.email.value,
                this.registrationForm.controls.password.value
              )
                .then(this.authenticationService.onFirebaseSignIn)
                .catch(this.authenticationService.handleFirebaseErrors)
                .finally(() => {
                  this.isLoading = false;
                });
            });
        } else {
          if (uploadedPictureReference) {
            deleteObject(uploadedPictureReference).then();
          }
          this.isLoading = false;
          this.snackbar.openFromComponent(SnackbarComponent, {
            data: {
              validation: registerResponse.data!.registerClient.success,
              warning: false,
              message: '¡Ha ocurrido un error con el registro!',
            },
            duration: 6000,
            panelClass: ['error-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        }
      });
  }

  registerClientWithGoogle() {
    this.isGoogleLoading = true;
    signInWithPopup(this.auth, this.googleProvider)
      .then(async (firebaseResponse: UserCredential) => {
        const token = await firebaseResponse.user.getIdToken();
        this.authenticationService.setTokenCookie(token);
        const registerVariables = {
          body: {
            token,
          },
        };

        this.authenticationService
          .registerClientWithGoogle(registerVariables)
          .subscribe((registerResponse) => {
            if (registerResponse.data!.registerClientWithGoogle.success) {
              this.snackbar
                .openFromComponent(SnackbarComponent, {
                  data: {
                    validation:
                      registerResponse.data!.registerClientWithGoogle.success,
                    message:
                      registerResponse.data!.registerClientWithGoogle.message,
                  },
                  duration: 2000,
                  panelClass: ['success-snackbar'],
                  horizontalPosition: 'end',
                  verticalPosition: 'top',
                })
                .afterOpened()
                .subscribe(() => {
                  this.isGoogleLoading = false;
                  let navigateTo = this.router.parseUrl(
                    this.router.routerState.snapshot.url
                  ).queryParams['redirectTo'];
                  this.router.navigateByUrl(navigateTo);
                });
            } else {
              this.isGoogleLoading = false;
              this.snackbar.openFromComponent(SnackbarComponent, {
                data: {
                  validation: false,
                  warning: false,
                  message:
                    registerResponse.data!.registerClientWithGoogle.message,
                },
                duration: 2000,
                panelClass: ['error-snackbar'],
                horizontalPosition: 'end',
                verticalPosition: 'top',
              });
              this.authenticationService.clientLogout();
            }
          });
      })
      .catch(this.authenticationService.handleFirebaseErrors)
      .finally(() => {
        this.isGoogleLoading = false;
      });
  }

  navigateToLogin() {
    this.router.navigate(['/sign-in'], {
      queryParams: this.router.parseUrl(this.router.url).queryParams,
    });
  }

  goBack() {
    this.location.back();
  }
}
