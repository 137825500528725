import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'error-screens-navbar',
  templateUrl: './error-screens-navbar.component.html',
  styleUrls: ['./error-screens-navbar.component.scss'],
})
export class ErrorScreensNavbarComponent implements OnInit {
  shouldShowGoBackArrow = true;

  constructor(private router: Router, private location: Location) {}

  ngOnInit(): void {
    this.shouldShowGoBackArrow = !this.location.isCurrentPathEqualTo(
      '/site-in-maintenance'
    );
  }

  goBack() {
    this.location.back();
  }

  navigateToLogin() {
    this.router.navigate(['/sign-in'], {
      queryParams: { redirectTo: '/', replaceUrl: true },
    });
  }
}
