<section class="company-info">
  <mat-card class="company-info-card" appearance="outlined">
    <div
      class="company-info-card-img-expanded"
      (click)="onClickImage(place.ProfilePictureUrl, place.Name)"
    >
      <image [imgSrc]="place.ProfilePictureUrl" [imgAlt]="place.Name" />
    </div>
    <mat-card-content>
      <section class="company-info-card-body">
        <mat-card-header>
          <mat-card-title>Información de la empresa</mat-card-title>
          <div
            class="company-info-card-img-smaller"
            (click)="onClickImage(place.ProfilePictureUrl, place.Name)"
          >
            <image [imgSrc]="place.ProfilePictureUrl" [imgAlt]="place.Name" />
          </div>
        </mat-card-header>
        <h1>{{ place.Name }}</h1>
        <a href="mailto:{{ place.Email }}">{{ place.Email }}</a>
        <a href="tel:{{ place.PhoneNumber }}" *ngIf="place.PhoneNumber">{{
          place.PhoneNumber
        }}</a>
        <mat-card-subtitle>{{
          place.Description ? "Descripción" : "Dirección"
        }}</mat-card-subtitle>
        <p>
          {{ place.Description ?? place.Address }}
        </p>
      </section>
      <mat-card-actions>
        <button
          *ngIf="place.Specialties.length > 0"
          mat-raised-button
          color="accent"
          (click)="onClickScheduleAppointment(place.Id)"
        >
          Agendar una cita
          <mat-icon>event_note</mat-icon>
        </button>
        <a
          *ngIf="place.PhoneNumber"
          mat-raised-button
          class="custom-button custom-button--whatsapp"
          [href]="generateWhatsappUrl(place.PhoneNumber, whatsappMessage)"
          target="_blank"
        >
          <span> Contáctanos </span>
          <mat-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 35 35"
              fill="none"
            >
              <path
                d="M17.5 7.09375C11.8203 7.09375 7.20312 11.7109 7.19531 17.3906C7.19531 19.3359 7.74219 21.2344 8.77344 22.8672L9.01562 23.2578L7.97656 27.0547L11.875 26.0312L12.25 26.2578C13.8281 27.1953 15.6406 27.6953 17.4922 27.6953H17.5C23.1719 27.6953 27.9141 23.0781 27.9141 17.3984C27.9141 14.6484 26.7266 12.0625 24.7812 10.1172C22.8281 8.16406 20.25 7.09375 17.5 7.09375ZM23.5547 21.8125C23.2969 22.5391 22.0625 23.1953 21.4688 23.2812C20.4844 23.4297 19.7188 23.3516 17.7578 22.5078C14.6562 21.1641 12.625 18.0391 12.4688 17.8359C12.3125 17.6328 11.2031 16.1562 11.2031 14.6328C11.2031 13.1094 12 12.3594 12.2891 12.0469C12.5703 11.7344 12.9062 11.6562 13.1172 11.6562C13.3203 11.6562 13.5312 11.6562 13.7109 11.6641C13.8984 11.6719 14.1562 11.5938 14.4062 12.1953C14.6641 12.8125 15.2812 14.3359 15.3594 14.4922C15.4375 14.6484 15.4922 14.8281 15.3828 15.0312C14.7891 16.2188 14.1562 16.1719 14.4766 16.7188C15.6719 18.7734 16.8672 19.4844 18.6875 20.3984C19 20.5547 19.1797 20.5312 19.3594 20.3203C19.5391 20.1172 20.1328 19.4141 20.3359 19.1094C20.5391 18.7969 20.75 18.8516 21.0312 18.9531C21.3125 19.0547 22.8359 19.8047 23.1484 19.9609C23.4609 20.1172 23.6641 20.1953 23.7422 20.3203C23.8125 20.4688 23.8125 21.0938 23.5547 21.8125ZM31.25 0H3.75C1.67969 0 0 1.67969 0 3.75V31.25C0 33.3203 1.67969 35 3.75 35H31.25C33.3203 35 35 33.3203 35 31.25V3.75C35 1.67969 33.3203 0 31.25 0ZM17.4922 29.7812C15.4141 29.7812 13.375 29.2578 11.5703 28.2734L5 30L6.75781 23.5781C5.67188 21.7031 5.10156 19.5703 5.10156 17.3828C5.10938 10.5547 10.6641 5 17.4922 5C20.8047 5 23.9141 6.28906 26.2578 8.63281C28.5938 10.9766 30 14.0859 30 17.3984C30 24.2266 24.3203 29.7812 17.4922 29.7812Z"
                fill="#18AB00"
              />
            </svg>
          </mat-icon>
        </a>
        <location-buttons
          [coords]="{ lat: place.Lat, lng: place.Lng }"
        ></location-buttons>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</section>
