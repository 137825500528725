import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  constructor() {}

  shouldShowInAppBrowserAlert(): boolean {
    if (this.getUserAgentAcknowledgement()) return false;

    const userAgent = navigator.userAgent;
    // Detect user-agent for in-app browsers: Instagram, Facebook, LinkedIn
    const isInstagram = userAgent.includes('Instagram');
    const isFacebook = userAgent.includes('FBAN') || userAgent.includes('FBAV');
    const isLinkedIn = userAgent.includes('LinkedIn');

    return isInstagram || isFacebook || isLinkedIn;
  }

  setUserAgentAcknowledgement() {
    localStorage.setItem('user-agent-ack', 'true');
  }

  getUserAgentAcknowledgement() {
    return localStorage.getItem('user-agent-ack');
  }
}
