import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { AppointmentByUser } from 'src/types/Appointment';

@Component({
  selector: 'appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss'],
})
export class AppointmentDetailsComponent implements AfterViewInit {
  @Input({ required: true }) appointmentData!: {
    appointment: AppointmentByUser;
    selectedAppointmentId?: number;
  };
  @Output() notifyAppointmentCancelation = new Subject<{
    id: number;
    status: string;
  }>();
  @ViewChild('appointmentCard')
  appointmentCard!: ElementRef<HTMLElement>;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private appointmentService: AppointmentsService
  ) {}

  ngAfterViewInit(): void {
    if (
      this.appointmentData.appointment.id ===
      this.appointmentData.selectedAppointmentId
    ) {
      setTimeout(() => {
        this.appointmentCard.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 200);
      this.renderer.setStyle(
        this.appointmentCard.nativeElement,
        'outline-color',
        '#561ad9'
      );
    }
  }

  onCancelAppointment() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          question: '¿Está seguro que desea cancelar la cita seleccionada?',
        },
      })
      .afterClosed()
      .subscribe((action) => {
        if (action && action.shouldExecuteAction) {
          this.notifyAppointmentCancelation.next({
            id: this.appointmentData.appointment.id,
            status: this.appointmentData.appointment.systemCode,
          });
        }
      });
  }

  onRescheduleAppointment() {
    this.appointmentService.location = this.appointmentData.appointment.location
      ? {
          id: this.appointmentData.appointment.location.Id,
          lat: this.appointmentData.appointment.location.Lat,
          lng: this.appointmentData.appointment.location.Lng,
        }
      : null;
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          question: '¿Está seguro que desea reagendar esta cita?',
        },
      })
      .afterClosed()
      .subscribe((action) => {
        if (action && action.shouldExecuteAction) {
          this.router.navigate(
            ['/schedule-appointment', this.appointmentData.appointment.placeId],
            {
              queryParams: {
                appointment: this.appointmentData.appointment.id,
                specialty: this.appointmentData.appointment.specialtyId,
              },
            }
          );
        }
      });
  }
}
