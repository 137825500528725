import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuItem } from '../menu.component';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Subscription, map } from 'rxjs';

@Component({
  selector: 'menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss']
})
export class MenuItemsComponent implements OnInit, OnDestroy {
  @Input() menuItems!: MenuItem[];
  @Input() isMobileScreen!: boolean;
  menuSelectedItem: number = 1;
  @Output() notifySelectedItem = new EventEmitter<MenuItem>();
  isAuthenticated: boolean = false;
  authSubscription = new Subscription();

  constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.route.url.subscribe(segment => {
      if (segment.length <= 0) {
        this.menuSelectedItem = 1;
        this.notifySelectedItem.emit(this.menuItems[0]);
        return;
      }
      const selectedItem = this.menuItems.find(item => item.path.length > 1 && segment[0].path.includes(item.path.slice(1)))
      this.menuSelectedItem = selectedItem!.id;
      this.notifySelectedItem.emit(selectedItem);
    })

    this.authSubscription = this.authenticationService.isAuthenticated()
      .pipe(map(user => !!user))
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      });

  }

  filterMenuItems(item: MenuItem) {
    return item.isMenuItem;
  }

  onClickLogout() {
    if (this.isAuthenticated) {
      this.authenticationService.clientLogout(true);
    }
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

}
