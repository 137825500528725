<sidenav>
  <section
    @fadeInPage
    class="container"
    *ngIf="getUserQuery.valueChanges | async as user"
  >
    <div class="delete-button-wrapper">
      <button
        type="button"
        class="delete-button"
        mat-raised-button
        color="warn"
        (click)="onDeleteAccount()"
      >
        Eliminar cuenta
      </button>
    </div>

    <div class="container-card" *ngIf="!isLoading">
      <profile-picture
        [profilePicture]="user.data.getUser.data.ProfilePictureUrl ?? ''"
        (selectedImageAsFile)="onSelectingNewProfilePicture($event)"
      ></profile-picture>

      <h2>Información Personal</h2>

      <form
        class="settings-form"
        [formGroup]="settingsForm"
        (ngSubmit)="onSubmit(user.data.getUser.data)"
      >
        <mat-form-field>
          <input
            type="text"
            matInput
            [formControl]="settingsForm.controls.firstName"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Nombre</mat-label>

          <mat-error
            *ngIf="settingsForm.controls.firstName.invalid"
            [innerHTML]="
              getNameErrorMessage(settingsForm.controls.firstName, 'Nombre')
            "
          >
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            type="text"
            matInput
            [formControl]="settingsForm.controls.lastName"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Apellidos</mat-label>

          <mat-error
            *ngIf="settingsForm.controls.lastName.invalid"
            [innerHTML]="
              getNameErrorMessage(settingsForm.controls.lastName, 'Apellido')
            "
          >
          </mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <ngx-mat-intl-tel-input
            [preferredCountries]="['cr', 'us']"
            [enableAutoCountrySelect]="true"
            [enableSearch]="true"
            searchPlaceholder="Buscar"
            name="phoneNumber"
            formControlName="phoneNumber"
            #phoneNumber
          ></ngx-mat-intl-tel-input>

          <mat-label class="mat-custom-phone-number-label"
            >Ingresar número telefónico
          </mat-label>

          <mat-hint>
            ejemplo {{ phoneNumber.selectedCountry!.placeHolder }}
          </mat-hint>

          <mat-error
            *ngIf="settingsForm.controls.phoneNumber.invalid"
            [innerHTML]="getPhoneNumberErrorMessage()"
          >
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            type="text"
            matInput
            [formControl]="settingsForm.controls.email"
          />

          <mat-label>Correo electrónico</mat-label>
        </mat-form-field>

        <button
          type="submit"
          class="settings-form-button"
          mat-raised-button
          color="accent"
        >
          Guardar
        </button>
        <button
          type="button"
          *ngIf="isEmailAndPasswordProvider"
          class="settings-form-button"
          mat-raised-button
          color="primary"
          (click)="openDialog($event)"
        >
          Cambiar Contraseña
        </button>
      </form>
    </div>
  </section>
</sidenav>
