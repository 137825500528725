<button
  mat-icon-button
  [matMenuTriggerFor]="dropdown"
  aria-label="Botón para elegir el filtro a aplicar"
>
  <mat-icon>
    <svg
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.125 2.875H1.875C1.57663 2.875 1.29048 2.75647 1.0795 2.5455C0.868526 2.33452 0.75 2.04837 0.75 1.75C0.75 1.45163 0.868526 1.16548 1.0795 0.954505C1.29048 0.743526 1.57663 0.625 1.875 0.625H22.125C22.4234 0.625 22.7095 0.743526 22.9205 0.954505C23.1315 1.16548 23.25 1.45163 23.25 1.75C23.25 2.04837 23.1315 2.33452 22.9205 2.5455C22.7095 2.75647 22.4234 2.875 22.125 2.875ZM18.375 8.125H5.625C5.32663 8.125 5.04048 8.00647 4.8295 7.79549C4.61853 7.58452 4.5 7.29837 4.5 7C4.5 6.70163 4.61853 6.41548 4.8295 6.2045C5.04048 5.99353 5.32663 5.875 5.625 5.875H18.375C18.6734 5.875 18.9595 5.99353 19.1705 6.2045C19.3815 6.41548 19.5 6.70163 19.5 7C19.5 7.29837 19.3815 7.58452 19.1705 7.79549C18.9595 8.00647 18.6734 8.125 18.375 8.125ZM13.875 13.375H10.125C9.82663 13.375 9.54048 13.2565 9.3295 13.0455C9.11853 12.8345 9 12.5484 9 12.25C9 11.9516 9.11853 11.6655 9.3295 11.4545C9.54048 11.2435 9.82663 11.125 10.125 11.125H13.875C14.1734 11.125 14.4595 11.2435 14.6705 11.4545C14.8815 11.6655 15 11.9516 15 12.25C15 12.5484 14.8815 12.8345 14.6705 13.0455C14.4595 13.2565 14.1734 13.375 13.875 13.375Z"
        fill="black"
      />
    </svg>
  </mat-icon>
</button>
<mat-menu #dropdown="matMenu">
  <mat-radio-group
    class="custom-radio-group"
    aria-labelledby="grupo de botones radio"
    [(ngModel)]="selectedOption.value"
    (change)="onChangeListener.next()"
  >
    <mat-radio-button
      class="example-radio-button"
      *ngFor="let option of options"
      [value]="option.value"
    >
      {{ option.label }}
    </mat-radio-button>
  </mat-radio-group>
</mat-menu>
