<div mat-dialog-content class="scheduled-appointment-success accept-warn-theme">
  <p>
    Su solicitud ha sido enviada con éxito, se enviará un mensaje a su correo
    con la debida respuesta a su solicitud
  </p>
  <div class="scheduled-appointment-success-actions">
    <button
      mat-flat-button
      color="primary"
      class="scheduled-appointment-success-button"
      (click)="closeDialog()"
    >
      Agendar otra cita
    </button>
    <button
      mat-flat-button
      color="warn"
      class="scheduled-appointment-success-button"
      (click)="navigateToAppointments()"
    >
      Ver cita
    </button>
  </div>
</div>
