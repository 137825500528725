<sidenav>
  <section @fadeInPage>
    <upcoming-appointments></upcoming-appointments>

    <h2>Empresas</h2>

    <search-companies
      (searchKeyword)="onListeningSearchQuery($event)"
      (userAgentLocation)="onListeningUserAgentLocation($event)"
      (selectedCategories)="onListeningPlaceCategoriesSelection($event)"
      (optionHome)="onListeningHome($event)"
    ></search-companies>

    <cards-grid *ngIf="places.length > 0" minHeight="100vh">
      <company-card
        @fadeInCards
        *ngFor="let place of places"
        [place]="place"
      ></company-card>
    </cards-grid>

    <div
      class="not-found-message-container"
      *ngIf="places.length === 0 && !loading"
    >
      <h1>No se han encontrado empresas</h1>
    </div>

    <div
      *ngIf="!loading && places.length > 0"
      class="observer-item"
      intersectionObserver
      [options]="{ threshold: 0 }"
      (isIntersecting)="onIntersection($event)"
    >
      Observe me
    </div>
    <div class="register-button-container">
      <register-company-button></register-company-button>
    </div>
  </section>
</sidenav>
