import { Pipe, PipeTransform } from '@angular/core';
import { TimeString } from 'src/types/TimeString';

@Pipe({
  name: 'timeSuffix',
})
export class TimeSuffixPipe implements PipeTransform {
  transform(value: TimeString): string {
    if (!value) return value;

    const timeParts = value.split(':');

    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = timeParts[2] ? parseInt(timeParts[2], 10) : null;

    if (hours > 0) {
      return `${value} ${hours === 1 ? 'hr' : 'hrs'}`;
    }
    if (minutes > 0) {
      return `${value} ${minutes === 1 ? 'min' : 'mins'}`;
    }
    if (seconds && seconds > 0) {
      return `${value} seg`;
    }
    return value;
  }
}
