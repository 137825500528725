import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input({ required: true }) pageNumber!: number;
  @Input({ required: true }) pageSize!: number;
  @Input({ required: true }) nextIsEnabled!: boolean;
  @Output() previous = new Subject<void>();
  @Output() next = new Subject<void>();
}
