import { Component, Input, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'location-buttons',
  templateUrl: './location-buttons.component.html',
  styleUrls: ['./location-buttons.component.scss'],
})
export class LocationButtonsComponent implements OnInit {
  @Input() placeLat?: number;
  @Input() placeLng?: number;

  isLoading: boolean = true;
  googleMapsUrl: string = '';
  wazeUrl: string = '';

  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    this.generateUrls(Number(this.placeLat), Number(this.placeLng));
    this.isLoading = false;
  }

  generateUrls(lat: number, lng: number) {
    this.googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    this.wazeUrl = `https://waze.com/ul?ll=${lat},${lng}&z=10`;
  }
}
