<sidenav>
  <section @fadeInPage *ngIf="!isLoading">
    <h2 class="company-name">{{ place.Name }}</h2>
    <company-info-card [place]="place"></company-info-card>
    <h2>Servicios</h2>
    <cards-grid
      *ngIf="place.Specialties.length > 0; else noneSpecialtiesFound"
      [needsRightPadding]="true"
    >
      <specialty-card
        @fadeInCards
        *ngFor="let specialty of place.Specialties"
        [placeId]="place.Id"
        [specialty]="specialty"
      ></specialty-card>
    </cards-grid>
    <ng-template #noneSpecialtiesFound>
      <h3 class="none-specialties-found-message">
        No se han encontrado servicios asociados con esta empresa
      </h3>
    </ng-template>
  </section>
</sidenav>
