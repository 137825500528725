import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CompanyImageDialogComponent } from './company-image-dialog/company-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PlaceDetails } from 'src/types/Place';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'company-info-card',
  templateUrl: './company-info-card.component.html',
  styleUrls: ['./company-info-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyInfoCardComponent {
  @Input({ required: true }) place!: PlaceDetails;
  readonly whatsappMessage = encodeURI(
    'Deseo obtener información sobre su empresa'
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  onClickImage(imageUrl: string, name: string) {
    const sizeBasedOnOrientation =
      screen.orientation.type === 'landscape-primary'
        ? { width: '50vw', height: '70vh' }
        : { width: '80vw', height: '60vh' };

    this.dialog.open(CompanyImageDialogComponent, {
      width: sizeBasedOnOrientation.width,
      height: sizeBasedOnOrientation.height,
      data: {
        imageUrl,
        name,
      },
    });
  }

  onClickScheduleAppointment(placeId: number) {
    this.router.navigate([`/schedule-appointment/${placeId}`], {
      relativeTo: this.activatedRoute,
    });
  }

  formatPhoneNumber(phoneNumber: string) {
    return phoneNumber.replace('+', '').replaceAll('-', '').replaceAll(' ', '');
  }

  generateWhatsappUrl(phoneNumber: string, textMessage: string) {
    return `https://wa.me/${this.formatPhoneNumber(
      phoneNumber
    )}?text=${textMessage}`;
  }
}
