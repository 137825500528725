<sidenav>
  <section @fadeIn class="feedback" *ngIf="!isLoading">
    <div class="feedback-box">
      <h1>Déjanos tu opinión</h1>
      <star-rating (rating)="onRated($event)"></star-rating>
      <p>Comentario <span>(Opcional)</span></p>
      <mat-form-field>
        <mat-label>Ingresa tu mensaje</mat-label>
        <textarea matInput [formControl]="commentControl" rows="4"></textarea>
      </mat-form-field>
      <button
        type="submit"
        mat-flat-button
        color="accent"
        class="rate-appointment-button"
        (click)="onSubmit()"
      >
        Enviar
      </button>
    </div>
  </section>
</sidenav>
