import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[highlightSelected]',
})
export class HighlightSelectedDirective implements AfterViewInit {
  @Input() highlightSelected: string = 'transparent';
  @Input() highlightSelectedProperty: string = 'backgroundColor';
  @Input() highlightSelectedInitialValue: string = 'transparent';

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.highlight(this.highlightSelectedInitialValue);
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.highlightSelected);
  }

  @HostListener('focusin') onFocus() {
    this.highlight(this.highlightSelected);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(this.highlightSelectedInitialValue);
  }

  @HostListener('focusout') onBlur() {
    this.highlight(this.highlightSelectedInitialValue);
  }

  private highlight(propertyValue: string) {
    this.el.nativeElement.style[this.highlightSelectedProperty] = propertyValue;
  }
}
