<mat-card
  *ngIf="specialty"
  class="specialty-card"
  appearance="outlined"
  [highlightSelected]="specialty.Color"
  highlightSelectedProperty="outlineColor"
  (click)="onClickScheduleAppointment(specialty.Id)"
>
  <span
    class="specialty-card-colored-bar"
    [style.background-color]="specialty.Color"
  ></span>
  <specialty-card-swiper [specialty]="specialty"></specialty-card-swiper>
  <mat-card-content>
    <mat-card-header class="specialty-card-header">
      <mat-card-title>{{ specialty.Name }}</mat-card-title>
      <mat-icon
        [matTooltip]="
          specialty.IsHome ? 'A domicilio' : 'En el establecimiento'
        "
        >{{ specialty.IsHome ? "home" : "store" }}</mat-icon
      >
    </mat-card-header>
    <p>{{ specialty.ProfessionalName }}</p>
    <p
      [attr.data-collapsed]="!showMore && specialty.Description.length > 55"
      [@expandCollapse]="showMore ? 'expanded' : 'collapsed'"
    >
      {{
        specialty.Description.length > 55 && !showMore
          ? (specialty.Description | slice : 0 : 55)
          : specialty.Description
      }}
    </p>
    <button
      [style.visibility]="
        specialty.Description.length > 55 ? 'visible' : 'hidden'
      "
      mat-button
      color="accent"
      (click)="onToggleShowMore($event)"
    >
      {{ showMore ? "Ver menos" : "Ver más" }}
      <mat-icon>{{ showMore ? "remove" : "add" }}</mat-icon>
    </button>
    <div class="card-schedule">
      <mat-card-subtitle>Horario</mat-card-subtitle>
      <p>{{ specialty.Schedule.Name }}</p>
    </div>
    <div>
      <mat-card-subtitle>Precio</mat-card-subtitle>
      <p>{{ specialty.Price }}</p>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-button
      color="accent"
      (click)="onClickScheduleAppointment(specialty.Id)"
    >
      Agendar una cita
    </button>
  </mat-card-actions>
</mat-card>
