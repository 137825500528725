import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PwaUpdateService {
  isNewVersionAvailable: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  newVersionSubscription!: Subscription;

  constructor(private swUpdate: SwUpdate) {
    this.checkForUpdates();
  }

  checkForUpdates(): void {
    this.newVersionSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe(
      (event) => {
        if (event.type === 'VERSION_READY') {
          this.isNewVersionAvailable.next(true);
        }
      }
    );
  }

  applyUpdate(): void {
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .catch((error) => console.error('Failed to apply updates:', error));
  }
}
