<mat-toolbar>
  <div class="mat-toolbar-flex-container">
    <button
      class="menu-button"
      matRipple
      [matRippleCentered]="true"
      *ngIf="isMobileScreen"
      (click)="onHandleMenuToggler()"
      color="primary"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="-2 -2 32 32"
        fill="none"
      >
        <path
          d="M3 12H21M3 6H21M3 18H15"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <div class="dark-theme">
      <button
        mat-icon-button
        color="primary"
        aria-label="Go back button"
        *ngIf="menuSelectedItem.id !== 1"
        [disabled]="menuSelectedItem.id === 1"
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1 class="mat-toolbar-title">
      <mat-icon color="accent">
        {{ menuSelectedItem.icon }}
      </mat-icon>
      {{ menuSelectedItem.title }}
    </h1>
    <span class="spacer"></span>
    <button
      class="account-button"
      *ngIf="!isAuthenticated"
      mat-raised-button
      color="accent"
      (click)="goToLogin()"
    >
      Iniciar sesión
    </button>
    <button
      class="account-button delete-button"
      *ngIf="isAuthenticated && isOnSettingsView"
      mat-raised-button
      color="warn"
      (click)="onDeleteAccount()"
    >
      Eliminar cuenta
    </button>
  </div>
</mat-toolbar>
