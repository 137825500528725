import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[detectLinks]',
})
export class DetectLinksDirective {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const paragraph = this.elementRef.nativeElement;

    const urlRegex = /(\bhttps?:\/\/\S+)/gi;

    paragraph.innerHTML = paragraph.innerHTML.replace(
      urlRegex,
      (url: string) => {
        return `<a href="${url}" target="_blank">${url}</a>`;
      }
    );
  }
}
