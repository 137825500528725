<auth-layout>
  <div class="container hide-scrollbar">
    <div class="dark-theme go-back-container">
      <button
        type="button"
        mat-icon-button
        color="primary"
        aria-label="Go back button"
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="form-container">
      <h2 class="title">Registrarse</h2>

      <profile-picture
        (selectedImageAsFile)="storePictureAsFile($event)"
      ></profile-picture>
      <form
        class="auth-form"
        [formGroup]="registrationForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="full-name-form">
          <mat-form-field>
            <input
              type="text"
              matInput
              [formControl]="registrationForm.controls.firstName"
              [errorStateMatcher]="matcher"
            />

            <mat-label>Nombre</mat-label>

            <mat-error
              *ngIf="registrationForm.controls.firstName.invalid"
              [innerHTML]="
                getNameErrorMessage(
                  registrationForm.controls.firstName,
                  'Nombre'
                )
              "
            >
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              type="text"
              matInput
              [formControl]="registrationForm.controls.lastName"
              [errorStateMatcher]="matcher"
            />

            <mat-label>Apellidos</mat-label>

            <mat-error
              *ngIf="registrationForm.controls.lastName.invalid"
              [innerHTML]="
                getNameErrorMessage(
                  registrationForm.controls.lastName,
                  'Apellido'
                )
              "
            >
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field>
          <input
            type="text"
            matInput
            [formControl]="registrationForm.controls.email"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Ingresar correo electrónico</mat-label>

          <mat-error
            *ngIf="registrationForm.controls.email.invalid"
            [innerHTML]="getEmailErrorMessage()"
          >
          </mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <ngx-mat-intl-tel-input
            [preferredCountries]="['cr', 'us']"
            [enableAutoCountrySelect]="true"
            [enableSearch]="true"
            searchPlaceholder="Buscar"
            name="phoneNumber"
            formControlName="phoneNumber"
            #phoneNumber
          ></ngx-mat-intl-tel-input>

          <mat-label class="mat-custom-phone-number-label">
            Ingresar número telefónico
          </mat-label>

          <mat-hint>
            ejemplo {{ phoneNumber.selectedCountry!.placeHolder }}
          </mat-hint>

          <mat-error
            *ngIf="registrationForm.controls.phoneNumber.invalid"
            [innerHTML]="getPhoneNumberErrorMessage()"
          >
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [type]="!showPassword ? 'password' : 'text'"
            [formControl]="registrationForm.controls.password"
          />

          <mat-label>Ingresar contraseña</mat-label>

          <mat-error
            *ngIf="registrationForm.controls.password.invalid"
            [innerHTML]="getPasswordErrorMessage()"
          >
          </mat-error>

          <button
            type="button"
            mat-icon-button
            tabindex="-1"
            matSuffix
            (click)="showPassword = !showPassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="showPassword"
          >
            <mat-icon>{{
              showPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [type]="!showConfirmPassword ? 'password' : 'text'"
            [formControl]="registrationForm.controls.confirmPassword"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Confirmar contraseña</mat-label>

          <mat-error
            *ngIf="registrationForm.controls.confirmPassword.invalid || registrationForm.controls.confirmPassword.errors?.['mismatch']"
            [innerHTML]="getConfirmPasswordErrorMessage()"
          >
          </mat-error>

          <button
            type="button"
            mat-icon-button
            matSuffix
            tabindex="-1"
            (click)="showConfirmPassword = !showConfirmPassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="showConfirmPassword"
          >
            <mat-icon>{{
              showConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <mat-radio-group formControlName="termsAndConditions">
          <mat-radio-button [value]="true">
            <span class="terms-and-conditions">
              Aceptar
              <span
                class="terms-and-conditions-link"
                (click)="readTermsAndConditions()"
              >
                términos y condiciones
              </span>
            </span>
          </mat-radio-button>
        </mat-radio-group>

        <button
          type="submit"
          mat-raised-button
          color="accent"
          [disabled]="isGoogleLoading || isLoading"
        >
          <mat-spinner
            *ngIf="isLoading"
            diameter="30"
            color="accent"
          ></mat-spinner>
          <span *ngIf="!isLoading">Registrarse</span>
        </button>

        <button
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="isGoogleLoading || isLoading"
          (click)="registerClientWithGoogle()"
        >
          <mat-spinner
            *ngIf="isGoogleLoading"
            diameter="30"
            color="accent"
          ></mat-spinner>
          <mat-icon *ngIf="!isGoogleLoading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -1 24 24"
              width="24"
            >
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
              <path d="M1 1h22v22H1z" fill="none" />
            </svg>
          </mat-icon>
          <span *ngIf="!isGoogleLoading">Registrarse con Google</span>
        </button>
      </form>

      <p class="form-container-body-text">
        ¿Ya tienes una cuenta?
        <span class="navigation-items" (click)="navigateToLogin()"
          >Iniciar Sesión</span
        >.
      </p>
    </div>
  </div>
</auth-layout>
