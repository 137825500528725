<auth-layout>
  <div class="container hide-scrollbar">
    <div class="dark-theme go-back-container">
      <button
        mat-icon-button
        color="primary"
        aria-label="Go back button"
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="form-container">
      <h1>Confirmar nueva contraseña</h1>

      <form class="auth-form" [formGroup]="restorePasswordForm">
        <mat-form-field>
          <input
            matInput
            [type]="!showPassword ? 'password' : 'text'"
            [formControl]="restorePasswordForm.controls.password"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Nueva contraseña</mat-label>

          <mat-error
            *ngIf="restorePasswordForm.controls.password.invalid"
            [innerHTML]="
              getPasswordErrorMessage(restorePasswordForm.controls.password)
            "
          >
          </mat-error>

          <button
            mat-icon-button
            matSuffix
            (click)="showPassword = !showPassword"
          >
            <mat-icon>{{
              showPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [type]="!showConfirmPassword ? 'password' : 'text'"
            [formControl]="restorePasswordForm.controls.confirmPassword"
            [errorStateMatcher]="matcher"
          />

          <mat-label>Confirmar contraseña</mat-label>

          <mat-error
            *ngIf="restorePasswordForm.controls.confirmPassword.invalid || restorePasswordForm.errors?.['mismatch']"
            [innerHTML]="getConfirmPasswordErrorMessage()"
          >
          </mat-error>

          <button
            mat-icon-button
            matSuffix
            (click)="showConfirmPassword = !showConfirmPassword"
          >
            <mat-icon>{{
              showConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>

        <button
          type="submit"
          mat-raised-button
          color="accent"
          [disabled]="isLoading"
          (click)="restorePassword()"
        >
          <mat-spinner
            *ngIf="isLoading"
            diameter="30"
            color="accent"
          ></mat-spinner>
          <span *ngIf="!isLoading">Confirmar cambio</span>
        </button>
      </form>
    </div>
  </div>
</auth-layout>
