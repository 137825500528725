<figure
  class="image-loader"
  [ngClass]="isImageLoading ? 'image-loader--loading' : 'image-loader--loaded'"
>
  <img
    class="image-loader-image"
    [src]="imgSrc"
    [alt]="imgAlt"
    loading="lazy"
    (load)="onImageLoaded()"
  />
</figure>
