import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[contrastTextColor]',
})
export class ContrastTextColorDirective implements AfterViewInit {
  elementBackgroundColor: string = '';

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) { }

  ngAfterViewInit(): void {
    // Next line always gets rgb color on most browsers, so no need to parse hex values
    this.elementBackgroundColor = this.element.nativeElement.style.getPropertyValue('background-color');
    this.setColor();
  }

  private isBackgroundColorDark(): boolean {
    const { red, green, blue } = this.createRgb();
    // Formula to calculate color brightness:
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    // If brightness is less than 128, then the text color should be white, else is black:
    return brightness < 128;
  }

  private createRgb() {
    const rgb = this.elementBackgroundColor
      .replace('rgb', '')
      .replace('(', '')
      .replace(')', '')
      .split(',');
    return { red: +rgb[0], green: +rgb[1], blue: +rgb[2] }
  }

  private setColor() {
    const isDark = this.isBackgroundColorDark();
    const textColor = isDark ? 'rgb(255,255,255)' : 'rgb(0,0,0)';
    this.renderer.setStyle(this.element.nativeElement, 'color', textColor);
  }
}
