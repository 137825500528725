import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/Home/home/home.component';
import { AppointmentsComponent } from './pages/Appointments/appointments/appointments.component';
import { ProfileComponent } from './pages/Profile/profile/profile.component';
import { ClientRegistrationComponent } from './pages/Auth/RegisterPage/client-registration/client-registration.component';
import { ClientLoginComponent } from './pages/Auth/LoginPage/client-login/client-login.component';
import { RestorePasswordComponent } from './pages/Auth/RestorePasswordPage/restore-password/restore-password.component';
import { CompanyDetailComponent } from './pages/Company/company-detail/company-detail.component';
import { ScheduleAppointmentComponent } from './pages/ScheduleAppointment/schedule-appointment/schedule-appointment.component';
import { authenticationGuard } from './authentication.guard';
import { NotFoundComponent } from './pages/404/not-found/not-found.component';
import { SiteInMaintenanceComponent } from './pages/Maintenance/site-in-maintenance/site-in-maintenance.component';
import { authScreensGuard } from './auth-screens.guard';
import { RestorePasswordFormComponent } from './pages/Auth/RestorePasswordPage/restore-password-form/restore-password-form.component';
import { AppointmentHistoryComponent } from './pages/Appointments/appointment-history/appointment-history.component';
import { FeedbackComponent } from './pages/Feedback/feedback/feedback.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'Página Principal',
  },
  {
    path: 'appointments',
    component: AppointmentsComponent,
    title: 'Mis Citas',
    canActivate: [authenticationGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    title: 'Perfil',
    canActivate: [authenticationGuard],
  },
  {
    path: 'appointment-history',
    component: AppointmentHistoryComponent,
    title: 'Historial',
    canActivate: [authenticationGuard],
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    title: 'Sugerencias del app',
    canActivate: [authenticationGuard],
  },
  {
    path: 'company/:placeId',
    component: CompanyDetailComponent,
    title: 'Empresa',
  },
  {
    path: 'schedule-appointment/:placeId',
    component: ScheduleAppointmentComponent,
    title: 'Agendar Cita',
  },
  {
    path: 'sign-in',
    component: ClientLoginComponent,
    title: 'Iniciar Sesión',
    canActivate: [authScreensGuard],
  },
  {
    path: 'sign-up',
    component: ClientRegistrationComponent,
    title: 'Registrarse',
    canActivate: [authScreensGuard],
  },
  {
    path: 'restore-password',
    component: RestorePasswordComponent,
    title: 'Restaurar Contraseña',
    canActivate: [authScreensGuard],
  },
  {
    path: 'recovery-password',
    component: RestorePasswordFormComponent,
    title: 'Restaurar Contraseña',
    canActivate: [authScreensGuard],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    title: 'Sitio no encontrado',
  },
  {
    path: 'site-in-maintenance',
    component: SiteInMaintenanceComponent,
    title: 'Sitio en mantenimiento',
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
