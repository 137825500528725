import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';


@Component({
  selector: 'cards-grid',
  templateUrl: './cards-grid.component.html',
  styleUrls: ['./cards-grid.component.scss']
})
export class CardsGridComponent implements OnInit, OnDestroy {
  @Input() needsRightPadding: boolean = false;
  @Input() minHeight: string = "";
  gridCols = 1;
  breakpointsToObserve = [
    '(max-width: 599.98px)',
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
  ];
  colsbreakpointObserverSubscription = new Subscription();

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.colsbreakpointObserverSubscription = this.breakpointObserver.observe(this.breakpointsToObserve)
      .subscribe((breakpoints: BreakpointState) => {
        this.breakpointsToObserve.map((observedBreakpoint, index) => {
          if (breakpoints.breakpoints[observedBreakpoint] === true) {
            this.gridCols = ++index;
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.colsbreakpointObserverSubscription.unsubscribe();
  }
}
