import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyImageDialogComponent } from '../../company-info-card/company-image-dialog/company-image-dialog.component';

@Component({
  selector: 'schedule-appointment-modal',
  templateUrl: './schedule-appointment-modal.component.html',
  styleUrls: ['./schedule-appointment-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleAppointmentModalComponent {
  constructor(public dialogRef: MatDialogRef<CompanyImageDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close({ shouldNavigateToAppointments: false });
  }

  navigateToAppointments() {
    this.dialogRef.close({ shouldNavigateToAppointments: true });
  }
}
