import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[darkenBorderColor]',
})
export class DarkenBorderColorDirective implements OnChanges {
  @Input('darkenBorderColor') colorToDarken!: string;
  @Input() darkenAmount: number = 10;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['colorToDarken']) {
      this.darken();
    }
  }

  private darken() {
    if (this.colorToDarken !== 'transparent') {
      const darkenedColor = this.calculateDarkenedColor(this.colorToDarken);
      this.renderer.setStyle(
        this.el.nativeElement,
        'box-shadow',
        `0px 0px 0px 2px ${darkenedColor}`
      );
    } else {
      this.renderer.setStyle(
        this.el.nativeElement,
        'box-shadow',
        '0px 0px 0px 2px transparent'
      );
    }
  }

  private calculateDarkenedColor(color: string): string {
    const rgb = this.hexToRgb(color);
    const darkenedRgb = rgb.map((value) =>
      Math.max(0, value - this.darkenAmount * 2.55)
    );
    return `rgb(${darkenedRgb[0]}, ${darkenedRgb[1]}, ${darkenedRgb[2]})`;
  }

  private hexToRgb(hex: string): number[] {
    hex = hex.slice(1);
    const bigint = parseInt(hex, 16);

    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  }
}
