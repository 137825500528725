import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeAnimation } from 'src/app/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Apollo, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import {
  CANCEL_APPOINTMENT,
  APPOINTMENTS_BY_USER,
  NOTIFICATION_APPOINTMENTS,
} from 'src/app/graphql';
import {
  AppointmentByUser,
  AppointmentSystemCode,
  AppointmentsByUser,
  AppointmentsByUserNotification,
  CancelAppointmentResponse,
} from 'src/types/Appointment';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: { time: '300ms 200ms', start: 0, end: 1 },
        }),
      ]),
    ]),
  ],
})
export class AppointmentsComponent implements OnInit, OnDestroy {
  selectedAppointmentId?: number;
  selectedAppointmentStatus?: string;
  isLoading: boolean = true;

  appointmentsByUserQuery!: QueryRef<AppointmentsByUser>;
  appointmentsByUserQuerySubscription = new Subscription();

  pendingAppointments: AppointmentByUser[] = [];
  approvedAppointments: AppointmentByUser[] = [];
  rejectedAppointments: AppointmentByUser[] = [];

  constructor(
    public loadingService: LoadingService,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    this.loadingService.setLoading(true);
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      const appointmentParam = queryParams.get('appointment');
      const appointmentsStatus = queryParams.get('status');
      if (appointmentParam && appointmentsStatus) {
        this.selectedAppointmentId = +appointmentParam;
        this.selectedAppointmentStatus = appointmentsStatus;
      }
    });

    this.appointmentsByUserQuery = this.apollo.watchQuery<AppointmentsByUser>({
      query: APPOINTMENTS_BY_USER,
      fetchPolicy: 'network-only',
    });

    this.appointmentsByUserQuerySubscription =
      this.appointmentsByUserQuery.valueChanges.subscribe(
        ({ data, loading }) => {
          if (data.appointmentsByUser) {
            this.pendingAppointments = data.appointmentsByUser.pending;
            this.approvedAppointments = data.appointmentsByUser.approved;
            this.rejectedAppointments = data.appointmentsByUser.rejected;
          }
          this.loadingService.setLoading(loading);
        }
      );
  }

  ngOnInit(): void {
    this.loadingService
      .loadingListener()
      .subscribe((isLoading) => (this.isLoading = isLoading));

    this.appointmentsByUserQuery.subscribeToMore<AppointmentsByUserNotification>(
      {
        document: NOTIFICATION_APPOINTMENTS,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data.onNotificationAppointments) {
            return prev;
          }

          const updatedAppointment =
            subscriptionData.data.onNotificationAppointments;

          const previousStatusKey =
            updatedAppointment.previousSystemCode.toLowerCase();
          const currentStatusKey = updatedAppointment.systemCode.toLowerCase();

          const previousStatusUpdate = prev.appointmentsByUser[
            <AppointmentSystemCode>previousStatusKey
          ].filter((appointment) => appointment.id !== updatedAppointment.id);
          const currentStatusUpdate = [
            ...prev.appointmentsByUser[<AppointmentSystemCode>currentStatusKey],
            updatedAppointment,
          ];

          return {
            appointmentsByUser: {
              ...prev.appointmentsByUser,
              [previousStatusKey]: previousStatusUpdate,
              [currentStatusKey]: currentStatusUpdate,
            },
          };
        },
      }
    );
  }

  cancelAppointment(appointmentId: number) {
    return this.apollo.mutate<CancelAppointmentResponse>({
      mutation: CANCEL_APPOINTMENT,
      variables: {
        body: {
          appointmentId,
        },
      },
    });
  }

  onNotifyAppointmentCancelation(appointmentToCancel: {
    id: number;
    status: string;
  }) {
    this.cancelAppointment(appointmentToCancel.id).subscribe(
      (cancelationResponse) => {
        if (cancelationResponse.data?.cancelAppointment.success) {
          this.snackBar
            .openFromComponent(SnackbarComponent, {
              data: {
                validation: true,
                message: '¡Su cita fue cancelada exitosamente!',
              },
              duration: 3000,
              panelClass: ['success-snackbar'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
            })
            .afterOpened()
            .subscribe(() => {
              if (appointmentToCancel.status === 'Approved') {
                this.refetchQuery(this.appointmentsByUserQuery);
              } else {
                this.refetchQuery(this.appointmentsByUserQuery);
              }
            });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: {
              message: cancelationResponse.data?.cancelAppointment.message,
            },
            duration: 6000,
            panelClass: ['error-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        }
      }
    );
  }

  refetchQuery(query: QueryRef<AppointmentsByUser> | undefined) {
    query!.refetch().finally(() => {
      query!.resetLastResults();
    });
  }

  ngOnDestroy(): void {
    this.appointmentsByUserQuerySubscription.unsubscribe();
  }
}
