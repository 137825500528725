<mat-accordion>
  <mat-expansion-panel
    (opened)="panelOpenState.set(true)"
    (closed)="panelOpenState.set(false)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Agregar dirección a domicilio </mat-panel-title>
    </mat-expansion-panel-header>
    <custom-maps></custom-maps>
  </mat-expansion-panel>
</mat-accordion>
