import { Component, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent {
  @Output() rating = new BehaviorSubject<number>(0);
  ratings: { label: string; rate: number }[] = [
    { label: 'Muy mala', rate: 1 },
    { label: 'Mala', rate: 2 },
    { label: 'Regular', rate: 3 },
    { label: 'Buena', rate: 4 },
    { label: 'Excelente', rate: 5 },
  ];
  selectedRate: number = 0;

  selectRate(selected: number) {
    this.selectedRate = selected;
    this.rating.next(this.selectedRate);
  }
}
