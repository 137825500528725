import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'appointment-history-comment-modal',
  templateUrl: './appointment-history-comment-modal.component.html',
  styleUrls: ['./appointment-history-comment-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppointmentHistoryCommentModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AppointmentHistoryCommentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { placeName: string; comment: string }
  ) {}
}
