import * as i0 from '@angular/core';
import { InjectionToken, Directive, inject, ChangeDetectorRef, ElementRef, input, EventEmitter, signal, booleanAttribute, Component, ViewEncapsulation, ChangeDetectionStrategy, ContentChild, ViewChild, Input, Output, HostListener, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { NgxFloatingConfig, Arrow, FloatingComponent } from '@ngx-popovers/core';
import { trigger, transition, style, animate } from '@angular/animations';
const _c0 = ["*", [["ngx-arrow"]]];
const _c1 = ["*", "ngx-arrow"];
function PopoverComponent_Conditional_1_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵlistener("@fadeInOut.start", function PopoverComponent_Conditional_1_Conditional_1_Template_div_animation_fadeInOut_start_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onAnimationStart($event));
    })("@fadeInOut.done", function PopoverComponent_Conditional_1_Conditional_1_Template_div_animation_fadeInOut_done_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onAnimationDone($event));
    });
    i0.ɵɵelementContainer(1, 3);
    i0.ɵɵprojection(2, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    const empty_r4 = i0.ɵɵreference(3);
    i0.ɵɵproperty("@fadeInOut", undefined)("@.disabled", ctx_r1.animationDisabled);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", (ctx_r1.template == null ? null : ctx_r1.template.templateRef) || empty_r4);
  }
}
function PopoverComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ngx-floating", 2);
    i0.ɵɵlistener("clickedInside", function PopoverComponent_Conditional_1_Template_ngx_floating_clickedInside_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClickedInside($event));
    })("clickedOutside", function PopoverComponent_Conditional_1_Template_ngx_floating_clickedOutside_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClickedOutside($event));
    })("computePositionReturn", function PopoverComponent_Conditional_1_Template_ngx_floating_computePositionReturn_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onComputePosition($event));
    });
    i0.ɵɵtemplate(1, PopoverComponent_Conditional_1_Conditional_1_Template, 3, 3, "div");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("reference", ctx_r1.anchor)("placement", ctx_r1.placement)("middleware", ctx_r1.middleware)("autoUpdate", ctx_r1.autoUpdate)("bindTo", ctx_r1.bindTo)("strategy", ctx_r1.strategy());
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.value ? 1 : -1);
  }
}
function PopoverComponent_ng_template_2_Template(rf, ctx) {}
class NgxPopoverConfig extends NgxFloatingConfig {
  constructor(config = {}) {
    super();
    this.arrow = true;
    this.arrowPadding = 2;
    this.autoUpdate = true;
    this.closeOnClickedOutside = true;
    Object.assign(this, config);
  }
}
const NGX_POPOVER_CONFIG = new InjectionToken('NGX_POPOVER_CONFIG', {
  providedIn: 'root',
  factory: () => new NgxPopoverConfig()
});

/**
 * Angular doesn't destroy elements in <ng-content />, so
 * the PopoverTemplate component uses for conditional content projection.
 *
 * The popover needs in a conditional projection because the components inside
 * must execute all of their lifecycle hooks.
 * And they must be destroyed when the popover closes
 *
 * @See https://angular.io/guide/content-projection#conditional-content-projection
 */
class PopoverTemplate {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static {
    this.ɵfac = function PopoverTemplate_Factory(t) {
      return new (t || PopoverTemplate)(i0.ɵɵdirectiveInject(i0.TemplateRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PopoverTemplate,
      selectors: [["", "ngx-popover-template", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverTemplate, [{
    type: Directive,
    args: [{
      selector: '[ngx-popover-template]',
      standalone: true
    }]
  }], () => [{
    type: i0.TemplateRef
  }], null);
})();
class PopoverComponent {
  constructor() {
    this.config = inject(NGX_POPOVER_CONFIG);
    this.cdRef = inject(ChangeDetectorRef);
    this.el = inject(ElementRef);
    this.anchor = this.el.nativeElement;
    this.placement = this.config.placement;
    this.strategy = input(this.config.strategy);
    this.middleware = this.config.middleware;
    /**
     * Updates floating element automatically
     */
    this.autoUpdate = this.config.autoUpdate;
    /**
     * HTMLElement where floating renders
     */
    this.bindTo = this.config.bindTo;
    /**
     * If true then the popover will close after a user
     * clicks outside the floating element
     */
    this.closeOnClickedOutside = this.config.closeOnClickedOutside;
    this.disabled = false;
    this.animationDisabled = false;
    this.value = false;
    this.valueChange = new EventEmitter();
    this.show = new EventEmitter();
    this.hide = new EventEmitter();
    this.clickedOutside = new EventEmitter();
    this.clickedInside = new EventEmitter();
    this.animationStart = new EventEmitter();
    this.animationDone = new EventEmitter();
    this.computePosition = new EventEmitter();
    this.isAnimating = signal(false);
  }
  /* Set the Floating to the arrow */
  set floatingComponent(component) {
    if (this.arrow) {
      this.arrow.setFloating(component);
    }
  }
  ngOnChanges(changes) {
    const currentValue = changes['value']?.currentValue;
    if (currentValue === true) {
      this.open();
    }
    if (currentValue === false) {
      this.close();
    }
  }
  onClick(event) {
    if (isElement(event.target)) {
      if (this.anchor.contains(event.target)) {
        this.toggle();
      }
    }
  }
  toggle() {
    if (!this.disabled) {
      if (this.value) {
        this.close();
      } else {
        this.open();
      }
    }
  }
  open() {
    this.isAnimating.set(true);
    this.value = true;
    this.valueChange.emit(true);
    this.show.emit();
  }
  close() {
    if (this.isAnimating()) {
      return;
    }
    this.isAnimating.set(true);
    this.value = false;
    this.valueChange.emit(false);
    this.hide.emit();
  }
  onClickedInside(element) {
    this.clickedInside.emit(element);
  }
  onClickedOutside(element) {
    this.clickedOutside.emit(element);
    /* Close the popover when user clicks outside */
    if (this.closeOnClickedOutside) {
      this.close();
      this.cdRef.detectChanges();
    }
  }
  onAnimationStart($event) {
    this.isAnimating.set(true);
    this.animationStart.emit($event);
  }
  onAnimationDone($event) {
    this.isAnimating.set(false);
    this.animationDone.emit($event);
  }
  onComputePosition($event) {
    this.computePosition.emit($event);
  }
  static {
    this.ɵfac = function PopoverComponent_Factory(t) {
      return new (t || PopoverComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PopoverComponent,
      selectors: [["ngx-popover"]],
      contentQueries: function PopoverComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, PopoverTemplate, 5);
          i0.ɵɵcontentQuery(dirIndex, Arrow, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.arrow = _t.first);
        }
      },
      viewQuery: function PopoverComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(FloatingComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.floatingComponent = _t.first);
        }
      },
      hostBindings: function PopoverComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function PopoverComponent_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          });
        }
      },
      inputs: {
        anchor: "anchor",
        placement: "placement",
        strategy: [1, "strategy"],
        middleware: "middleware",
        autoUpdate: [2, "autoUpdate", "autoUpdate", booleanAttribute],
        bindTo: "bindTo",
        closeOnClickedOutside: [2, "closeOnClickedOutside", "closeOnClickedOutside", booleanAttribute],
        disabled: [2, "disabled", "disabled", booleanAttribute],
        animationDisabled: [2, "animationDisabled", "animationDisabled", booleanAttribute],
        value: [2, "value", "value", booleanAttribute]
      },
      outputs: {
        valueChange: "valueChange",
        show: "show",
        hide: "hide",
        clickedOutside: "clickedOutside",
        clickedInside: "clickedInside",
        animationStart: "animationStart",
        animationDone: "animationDone",
        computePosition: "computePosition"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 4,
      vars: 1,
      consts: [["empty", ""], [3, "reference", "placement", "middleware", "autoUpdate", "bindTo", "strategy"], [3, "clickedInside", "clickedOutside", "computePositionReturn", "reference", "placement", "middleware", "autoUpdate", "bindTo", "strategy"], [3, "ngTemplateOutlet"]],
      template: function PopoverComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵtemplate(1, PopoverComponent_Conditional_1_Template, 2, 7, "ngx-floating", 1)(2, PopoverComponent_ng_template_2_Template, 0, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.value || ctx.isAnimating() ? 1 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgTemplateOutlet, FloatingComponent],
      encapsulation: 2,
      data: {
        animation: [trigger('fadeInOut', [transition(':enter', [style({
          opacity: 0,
          transform: 'scale(0.9)'
        }), animate(140, style({
          opacity: 1,
          transform: 'scale(1)'
        }))]), transition(':leave', [animate(110, style({
          opacity: 0
        }))])])]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-popover',
      standalone: true,
      imports: [CommonModule, Arrow, FloatingComponent],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [trigger('fadeInOut', [transition(':enter', [style({
        opacity: 0,
        transform: 'scale(0.9)'
      }), animate(140, style({
        opacity: 1,
        transform: 'scale(1)'
      }))]), transition(':leave', [animate(110, style({
        opacity: 0
      }))])])],
      template: "<!--\n  By default, this content will be\n  the anchor and reference\n  of the Popover\n-->\n<ng-content />\n\n@if (value || isAnimating()) {\n  <ngx-floating\n    [reference]=\"anchor\"\n    [placement]=\"placement\"\n    [middleware]=\"middleware\"\n    [autoUpdate]=\"autoUpdate\"\n    [bindTo]=\"bindTo\"\n    [strategy]=\"strategy()\"\n\n    (clickedInside)=\"onClickedInside($event)\"\n    (clickedOutside)=\"onClickedOutside($event)\"\n    (computePositionReturn)=\"onComputePosition($event)\"\n  >\n    @if (value) {\n      <div\n        @fadeInOut\n        (@fadeInOut.start)=\"onAnimationStart($event)\"\n        (@fadeInOut.done)=\"onAnimationDone($event)\"\n        [@.disabled]=\"animationDisabled\"\n      >\n        <ng-container\n          [ngTemplateOutlet]=\"template?.templateRef || empty\"\n        />\n\n        <ng-content select=\"ngx-arrow\" />\n      </div>\n    }\n  </ngx-floating>\n}\n\n<ng-template #empty />\n"
    }]
  }], null, {
    template: [{
      type: ContentChild,
      args: [PopoverTemplate]
    }],
    arrow: [{
      type: ContentChild,
      args: [Arrow]
    }],
    floatingComponent: [{
      type: ViewChild,
      args: [FloatingComponent]
    }],
    anchor: [{
      type: Input
    }],
    placement: [{
      type: Input
    }],
    middleware: [{
      type: Input
    }],
    autoUpdate: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    bindTo: [{
      type: Input
    }],
    closeOnClickedOutside: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    animationDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    value: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    valueChange: [{
      type: Output
    }],
    show: [{
      type: Output
    }],
    hide: [{
      type: Output
    }],
    clickedOutside: [{
      type: Output
    }],
    clickedInside: [{
      type: Output
    }],
    animationStart: [{
      type: Output
    }],
    animationDone: [{
      type: Output
    }],
    computePosition: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
function isElement(element) {
  return element instanceof Element;
}

/**
 * The directive used for providing an anchor element in
 * popover content.
 */
class PopoverAnchor {
  constructor(el) {
    this.el = el;
    this.popover = inject(PopoverComponent);
    this.popover.anchor = el.nativeElement;
  }
  static {
    this.ɵfac = function PopoverAnchor_Factory(t) {
      return new (t || PopoverAnchor)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PopoverAnchor,
      selectors: [["", "ngx-popover-anchor", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverAnchor, [{
    type: Directive,
    args: [{
      selector: '[ngx-popover-anchor]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();

/**
 * The PopoverClose directive provides an ease-to-use method
 * for closing a popover within its template
 *
 * @example
 *
 * <ngx-popover>
 *   <button>Trigger</button>
 *
 *   <ng-template ngx-popover-template>
 *     <p>Lorem ipsum dolor.</p>
 *     <button ngx-popover-close>Close</button>
 *   </ng-template>
 * </ngx-popover>
 */
class PopoverClose {
  constructor() {
    this.popover = inject(PopoverComponent);
  }
  onClick() {
    this.popover.close();
  }
  static {
    this.ɵfac = function PopoverClose_Factory(t) {
      return new (t || PopoverClose)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PopoverClose,
      selectors: [["", "ngx-popover-close", ""]],
      hostBindings: function PopoverClose_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function PopoverClose_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverClose, [{
    type: Directive,
    args: [{
      selector: '[ngx-popover-close]',
      standalone: true
    }]
  }], null, {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class PopoverModule {
  static {
    this.ɵfac = function PopoverModule_Factory(t) {
      return new (t || PopoverModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PopoverModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [PopoverComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [PopoverComponent, PopoverTemplate, PopoverAnchor, PopoverClose],
      exports: [PopoverComponent, PopoverTemplate, PopoverAnchor, PopoverClose]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NGX_POPOVER_CONFIG, NgxPopoverConfig, PopoverAnchor, PopoverClose, PopoverComponent, PopoverModule, PopoverTemplate };
