<div mat-dialog-content class="appointment-comment">
  <button
    class="close"
    mat-icon-button
    color="primary"
    aria-label="Botón para cerrar modal"
    [tabIndex]="-1"
    (click)="dialogRef.close()"
  >
    <mat-icon> close </mat-icon>
  </button>
  <h1>{{ data.placeName }}</h1>
  <p detectLinks>{{ data.comment }}</p>
</div>
