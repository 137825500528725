import { Component, signal } from '@angular/core';

@Component({
  selector: 'schedule-appointment-location-info',
  templateUrl: './schedule-appointment-location-info.component.html',
  styleUrl: './schedule-appointment-location-info.component.scss',
})
export class ScheduleAppointmentLocationInfoComponent {
  readonly panelOpenState = signal(false);
}
