import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Place } from 'src/types/Place';

@Component({
  selector: 'company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent {
  @Input() place!: Place;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  onClickCard(id: number) {
    this.router.navigate([`/company/${id}`], {
      relativeTo: this.activatedRoute,
    });
  }
}
