import { __assign, __rest } from "tslib";
import { wrap } from "optimism";
import { getFragmentQueryDocument } from "../../utilities/index.js";
var ApolloCache = function () {
  function ApolloCache() {
    this.assumeImmutableResults = false;
    this.getFragmentDoc = wrap(getFragmentQueryDocument);
  }
  ApolloCache.prototype.batch = function (options) {
    var _this = this;
    var optimisticId = typeof options.optimistic === "string" ? options.optimistic : options.optimistic === false ? null : void 0;
    var updateResult;
    this.performTransaction(function () {
      return updateResult = options.update(_this);
    }, optimisticId);
    return updateResult;
  };
  ApolloCache.prototype.recordOptimisticTransaction = function (transaction, optimisticId) {
    this.performTransaction(transaction, optimisticId);
  };
  ApolloCache.prototype.transformDocument = function (document) {
    return document;
  };
  ApolloCache.prototype.transformForLink = function (document) {
    return document;
  };
  ApolloCache.prototype.identify = function (object) {
    return;
  };
  ApolloCache.prototype.gc = function () {
    return [];
  };
  ApolloCache.prototype.modify = function (options) {
    return false;
  };
  ApolloCache.prototype.readQuery = function (options, optimistic) {
    if (optimistic === void 0) {
      optimistic = !!options.optimistic;
    }
    return this.read(__assign(__assign({}, options), {
      rootId: options.id || "ROOT_QUERY",
      optimistic: optimistic
    }));
  };
  ApolloCache.prototype.readFragment = function (options, optimistic) {
    if (optimistic === void 0) {
      optimistic = !!options.optimistic;
    }
    return this.read(__assign(__assign({}, options), {
      query: this.getFragmentDoc(options.fragment, options.fragmentName),
      rootId: options.id,
      optimistic: optimistic
    }));
  };
  ApolloCache.prototype.writeQuery = function (_a) {
    var id = _a.id,
      data = _a.data,
      options = __rest(_a, ["id", "data"]);
    return this.write(Object.assign(options, {
      dataId: id || "ROOT_QUERY",
      result: data
    }));
  };
  ApolloCache.prototype.writeFragment = function (_a) {
    var id = _a.id,
      data = _a.data,
      fragment = _a.fragment,
      fragmentName = _a.fragmentName,
      options = __rest(_a, ["id", "data", "fragment", "fragmentName"]);
    return this.write(Object.assign(options, {
      query: this.getFragmentDoc(fragment, fragmentName),
      dataId: id,
      result: data
    }));
  };
  ApolloCache.prototype.updateQuery = function (options, update) {
    return this.batch({
      update: function (cache) {
        var value = cache.readQuery(options);
        var data = update(value);
        if (data === void 0 || data === null) return value;
        cache.writeQuery(__assign(__assign({}, options), {
          data: data
        }));
        return data;
      }
    });
  };
  ApolloCache.prototype.updateFragment = function (options, update) {
    return this.batch({
      update: function (cache) {
        var value = cache.readFragment(options);
        var data = update(value);
        if (data === void 0 || data === null) return value;
        cache.writeFragment(__assign(__assign({}, options), {
          data: data
        }));
        return data;
      }
    });
  };
  return ApolloCache;
}();
export { ApolloCache };
