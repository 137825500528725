<section class="categories-list-wrapper">
  <mat-chip-listbox
    multiple
    aria-label="Lista de categorías"
    (change)="onSelectCategory($event)"
  >
    <swiper-container #swiper [style]="navigationCssVariables">
      @for (category of categories; track category.Id) {
      <swiper-slide>
        <mat-chip-option [value]="category.Id">{{
          category.Name
        }}</mat-chip-option>
      </swiper-slide>
      }
    </swiper-container>
  </mat-chip-listbox>
</section>
