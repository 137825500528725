import { Observable } from "./Observable.js";
export function asyncMap(observable, mapFn, catchFn) {
  return new Observable(function (observer) {
    var next = observer.next,
      error = observer.error,
      complete = observer.complete;
    var activeCallbackCount = 0;
    var completed = false;
    var promiseQueue = {
      then: function (callback) {
        return new Promise(function (resolve) {
          return resolve(callback());
        });
      }
    };
    function makeCallback(examiner, delegate) {
      if (examiner) {
        return function (arg) {
          ++activeCallbackCount;
          var both = function () {
            return examiner(arg);
          };
          promiseQueue = promiseQueue.then(both, both).then(function (result) {
            --activeCallbackCount;
            next && next.call(observer, result);
            if (completed) {
              handler.complete();
            }
          }, function (error) {
            --activeCallbackCount;
            throw error;
          }).catch(function (caught) {
            error && error.call(observer, caught);
          });
        };
      } else {
        return function (arg) {
          return delegate && delegate.call(observer, arg);
        };
      }
    }
    var handler = {
      next: makeCallback(mapFn, next),
      error: makeCallback(catchFn, error),
      complete: function () {
        completed = true;
        if (!activeCallbackCount) {
          complete && complete.call(observer);
        }
      }
    };
    var sub = observable.subscribe(handler);
    return function () {
      return sub.unsubscribe();
    };
  });
}
