<!-- Dynamic items -->
<li *ngFor="let item of menuItems | filterArray : filterMenuItems">
  <a
    [routerLink]="!item.shouldBeDisabled ? item.path : './'"
    [style.color]="menuSelectedItem === item.id ? item.textColor : ''"
    [class.menu-item-disabled]="item.shouldBeDisabled"
    [tabIndex]="item.shouldBeDisabled ? '-1' : ''"
  >
    <mat-icon
      *ngIf="!!item.isSvgIcon; else fontIcon"
      [color]="menuSelectedItem === item.id ? item.iconColor : ''"
      [matTooltip]="
        item.shouldBeDisabled ? 'Inicia sesión para tener acceso' : ''
      "
      [svgIcon]="!!item.isSvgIcon ? item.icon : ''"
    >
    </mat-icon>
    <ng-template #fontIcon>
      <mat-icon
        [color]="menuSelectedItem === item.id ? item.iconColor : ''"
        [matTooltip]="
          item.shouldBeDisabled ? 'Inicia sesión para tener acceso' : ''
        "
        [fontIcon]="item.icon"
      >
      </mat-icon>
    </ng-template>
    {{ isMobileScreen ? item.title : "" }}
  </a>

  <svg
    *ngIf="isMobileScreen && !item.shouldBeDisabled"
    [style.opacity]="menuSelectedItem === item.id ? 1 : 0"
    class="menu-item-background"
    width="225"
    height="91"
    viewBox="0 0 225 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M210.543 27.2314L18.0148 26.3728C8.07854 26.3284 0 34.371 0 44.3074C0 54.1813 7.98105 62.198 17.8549 62.2421L210.581 63.1016C218.123 63.1353 224.219 69.2587 224.219 76.8007V90.1096C224.219 90.3252 224.394 90.5 224.61 90.5C224.825 90.5 225 90.3252 225 90.1096V0.390412C225 0.174794 224.825 0 224.61 0C224.394 0 224.219 0.174793 224.219 0.390411V13.6158C224.219 21.1593 218.086 27.2651 210.543 27.2314Z"
      fill="white"
    />
  </svg>

  <svg
    *ngIf="!isMobileScreen && !item.shouldBeDisabled"
    class="menu-item-background menu-item-background-desktop"
    [style.opacity]="menuSelectedItem === item.id ? 1 : 0"
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="108"
    viewBox="0 0 57 108"
    fill="none"
  >
    <path
      d="M42.573 27.2924H17.935C8.02978 27.2924 0 35.3222 0 45.2274C0 55.1327 8.02976 63.1625 17.935 63.1625H42.5504C50.0995 63.1625 56.2192 69.2822 56.2192 76.8312V90.1096C56.2192 90.3252 56.394 90.5 56.6096 90.5C56.8252 90.5 57 90.3252 57 90.1096V0.390412C57 0.174794 56.8252 0 56.6096 0C56.394 0 56.2192 0.174793 56.2192 0.390411V13.6462C56.2192 21.1828 50.1096 27.2924 42.573 27.2924Z"
      fill="#F0F0F0"
    />
  </svg>
</li>

<!-- Fixed logout item -->
<li *ngIf="isAuthenticated">
  <button (click)="onClickLogout()">
    <mat-icon>logout</mat-icon>
    {{ isMobileScreen ? "Cerrar Sesión" : "" }}
  </button>

  <svg
    *ngIf="isMobileScreen"
    class="menu-item-background"
    width="225"
    height="91"
    viewBox="0 0 225 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M210.543 27.2314L18.0148 26.3728C8.07854 26.3284 0 34.371 0 44.3074C0 54.1813 7.98105 62.198 17.8549 62.2421L210.581 63.1016C218.123 63.1353 224.219 69.2587 224.219 76.8007V90.1096C224.219 90.3252 224.394 90.5 224.61 90.5C224.825 90.5 225 90.3252 225 90.1096V0.390412C225 0.174794 224.825 0 224.61 0C224.394 0 224.219 0.174793 224.219 0.390411V13.6158C224.219 21.1593 218.086 27.2651 210.543 27.2314Z"
      fill="white"
    />
  </svg>

  <svg
    *ngIf="!isMobileScreen"
    class="menu-item-background menu-item-background-desktop"
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="108"
    viewBox="0 0 57 108"
    fill="none"
  >
    <path
      d="M42.573 27.2924H17.935C8.02978 27.2924 0 35.3222 0 45.2274C0 55.1327 8.02976 63.1625 17.935 63.1625H42.5504C50.0995 63.1625 56.2192 69.2822 56.2192 76.8312V90.1096C56.2192 90.3252 56.394 90.5 56.6096 90.5C56.8252 90.5 57 90.3252 57 90.1096V0.390412C57 0.174794 56.8252 0 56.6096 0C56.394 0 56.2192 0.174793 56.2192 0.390411V13.6462C56.2192 21.1828 50.1096 27.2924 42.573 27.2924Z"
      fill="#F0F0F0"
    />
  </svg>
</li>
