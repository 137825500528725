import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@googlemaps/js-api-loader';
class NgxGpAutocompleteService {
  constructor() {
    this.defaultOptions = {};
  }
  setOptions(options) {
    this.defaultOptions = {
      ...this.defaultOptions,
      ...options
    };
  }
  getOptions() {
    return this.defaultOptions;
  }
  enableGooglePersistenceCheck() {
    this.verifyGooglePersistence = true;
  }
  getGooglePersistenceCheck() {
    return this.verifyGooglePersistence;
  }
  static {
    this.ɵfac = function NgxGpAutocompleteService_Factory(t) {
      return new (t || NgxGpAutocompleteService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxGpAutocompleteService,
      factory: NgxGpAutocompleteService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGpAutocompleteService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class NgxGpAutocompleteDirective {
  constructor(el, ngxGpAutocompleteService, loader, ngZone) {
    this.el = el;
    this.ngxGpAutocompleteService = ngxGpAutocompleteService;
    this.loader = loader;
    this.ngZone = ngZone;
    this.onAddressChange = new EventEmitter();
  }
  ngAfterViewInit() {
    if (!this.options) {
      this.options = this.ngxGpAutocompleteService.getOptions();
    }
    if (this.ngxGpAutocompleteService.getGooglePersistenceCheck()) {
      if (!this.isGoogleLibExists()) {
        this.loader.importLibrary("places").then(() => this.initialize(), console.error);
      }
    } else {
      this.loader.importLibrary("places").then(() => this.initialize(), console.error);
    }
  }
  isGoogleLibExists() {
    return !(!google || !google.maps || !google.maps.places);
  }
  initialize() {
    if (!this.isGoogleLibExists()) throw new Error("Google maps library can not be found");
    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);
    if (!this.autocomplete) throw new Error("Autocomplete is not initialized");
    if (!this.autocomplete.addListener != null) {
      // Check to bypass https://github.com/angular-ui/angular-google-maps/issues/270
      this.eventListener = this.autocomplete.addListener('place_changed', () => {
        this.handleChangeEvent();
      });
    }
    this.el.nativeElement.addEventListener('keydown', event => {
      if (!event.key) {
        return;
      }
      let key = event.key.toLowerCase();
      if (key == 'enter' && event.target === this.el.nativeElement) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
    // according to https://gist.github.com/schoenobates/ef578a02ac8ab6726487
    if (window && window.navigator && window.navigator.userAgent && navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      setTimeout(() => {
        let containers = document.getElementsByClassName('pac-container');
        if (containers) {
          let arr = Array.from(containers);
          if (arr) {
            for (let container of arr) {
              if (!container) continue;
              container.addEventListener('touchend', e => {
                e.stopImmediatePropagation();
              });
            }
          }
        }
      }, 500);
    }
  }
  reset() {
    this.autocomplete.setComponentRestrictions(this.options.componentRestrictions);
    this.autocomplete.setTypes(this.options.types);
  }
  handleChangeEvent() {
    this.ngZone.run(() => {
      this.place = this.autocomplete.getPlace();
      if (this.place) {
        this.onAddressChange.emit(this.place);
      }
    });
  }
  static {
    this.ɵfac = function NgxGpAutocompleteDirective_Factory(t) {
      return new (t || NgxGpAutocompleteDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NgxGpAutocompleteService), i0.ɵɵdirectiveInject(i2.Loader), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxGpAutocompleteDirective,
      selectors: [["", "ngx-gp-autocomplete", ""]],
      inputs: {
        options: "options"
      },
      outputs: {
        onAddressChange: "onAddressChange"
      },
      exportAs: ["ngx-places"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGpAutocompleteDirective, [{
    type: Directive,
    args: [{
      selector: '[ngx-gp-autocomplete]',
      exportAs: 'ngx-places'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: NgxGpAutocompleteService
    }, {
      type: i2.Loader
    }, {
      type: i0.NgZone
    }];
  }, {
    options: [{
      type: Input
    }],
    onAddressChange: [{
      type: Output
    }]
  });
})();
class NgxGpAutocompleteModule {
  static {
    this.ɵfac = function NgxGpAutocompleteModule_Factory(t) {
      return new (t || NgxGpAutocompleteModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxGpAutocompleteModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxGpAutocompleteModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxGpAutocompleteDirective],
      exports: [NgxGpAutocompleteDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-gp-autocomplete
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxGpAutocompleteDirective, NgxGpAutocompleteModule, NgxGpAutocompleteService };
