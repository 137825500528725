import { gql } from 'apollo-angular';
import { LocationInput } from 'src/types/Appointment';

export const SCHEDULE_APPOINTMENT = gql`
  mutation AddAppointmentClient($body: AppointmentDataInput!) {
    addAppointmentClient(body: $body) {
      data {
        Id
      }
      success
      message
      messages
    }
  }
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation EditAppointmentClient($body: EditAppointmentClientInput!) {
    editAppointmentClient(body: $body) {
      data {
        Id
      }
      success
      message
      messages
      statusCodeError
    }
  }
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($body: CancelAppointmentInput!) {
    cancelAppointment(body: $body) {
      success
      message
      messages
      statusCodeError
    }
  }
`;

export const SCHEDULE_APPOINTMENT_BODY = (
  date: string,
  hour: string,
  specialtyId: number,
  location: LocationInput
) => ({
  body: {
    date,
    hour,
    specialtyId,
    location,
  },
});

export const RESCHEDULE_APPOINTMENT_BODY = (
  appoinmentId: number,
  date: string,
  hour: string,
  specialtyId: number
) => ({
  body: {
    appoinmentId,
    appoinment: {
      date,
      hour,
      specialtyId,
    },
  },
});
