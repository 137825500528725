import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SpecialtyDetails } from 'src/types/Place';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'specialty-card-swiper',
  templateUrl: './specialty-card-swiper.component.html',
  styleUrl: './specialty-card-swiper.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SpecialtyCardSwiperComponent implements OnInit, AfterViewInit {
  isOpened = false;
  @Input({ required: true }) specialty!: SpecialtyDetails;
  @ViewChild('swiper', { static: false }) swiperRef?: ElementRef;
  navigationCssVariables: string = '';

  ngOnInit(): void {
    this.navigationCssVariables = `
    --swiper-navigation-color: #ffffff;
    --swiper-navigation-size: 20px;
    --swiper-navigation-sides-offset: 2px;
  `;
  }
  ngAfterViewInit(): void {
    if (this.swiperRef) {
      // Configure Swiper with custom options
      const swiperConfiguration: SwiperOptions = {
        navigation: {
          enabled: true,
        },
        slidesPerView: 1,
      };
      // Add configuration to the swiper element
      Object.assign(this.swiperRef.nativeElement, swiperConfiguration);
    }
  }

  onContainerClick(clickEvent: MouseEvent) {
    clickEvent.stopPropagation();
  }

  onToggleView() {
    this.isOpened = !this.isOpened;
  }
}
