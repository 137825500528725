<div class="appointment-history-card">
  <div class="comments-button-container">
    <button
      mat-button
      color="accent"
      [disabled]="
        !!!getCommentBySystemCode(
          appointment.UserFeedbacks,
          UserFeedbacksystemCodes.admin
        )
      "
      (click)="
        openAppointmentCommentDialog(
          appointment.Schedule.Specialties[0].Place.Name,
          appointment.UserFeedbacks
        )
      "
    >
      Ver respuesta
    </button>
  </div>
  <div class="appointment-history-card-body">
    <div class="appointment-history-card-body-col">
      <p>{{ date }}</p>
      <span
        >{{ appointment.StartDateTime | date : "h:mm a" }} -
        {{ appointment.EndDateTime | date : "h:mm a" }}</span
      >
    </div>

    <div class="appointment-history-card-body-col">
      <p>{{ appointment.Schedule.Specialties[0].Place.Name }}</p>
      <span>{{ appointment.Schedule.Specialties[0].Place.PhoneNumber }}</span>
    </div>

    <div class="appointment-history-card-body-col">
      <p>Tipo de cita</p>
      <span>{{ appointment.Schedule.Specialties[0].Name }}</span>
    </div>
  </div>
  <button
    mat-icon-button
    color="accent"
    aria-label="Botón para añadir comentario"
    class="add-comment-button"
    [disabled]="
      !!getCommentBySystemCode(
        appointment.UserFeedbacks,
        UserFeedbacksystemCodes.client
      )
    "
    matTooltip="Añadir comentario"
    (click)="openRateAppointmentDialog(appointment.Id)"
  >
    <mat-icon>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0301 13.0619C19.0301 12.5649 18.6271 12.1619 18.1301 12.1619H8.23008C7.73308 12.1619 7.33008 12.5649 7.33008 13.0619C7.33008 13.5589 7.73308 13.9619 8.23008 13.9619H18.1301C18.6271 13.9619 19.0301 13.5589 19.0301 13.0619Z"
          fill="#561AD9"
        />
        <path
          d="M8.22715 16.7069C7.73015 16.7069 7.32715 17.1099 7.32715 17.6069C7.32715 18.1039 7.73015 18.5069 8.22715 18.5069H13.4761C13.9731 18.5069 14.3761 18.1039 14.3761 17.6069C14.3761 17.1099 13.9731 16.7069 13.4761 16.7069H8.22715Z"
          fill="#561AD9"
        />
        <path
          d="M24.9596 13.1119C24.4626 13.1119 24.0596 13.5149 24.0596 14.0119V21.2909C24.0596 22.6529 22.9516 23.7609 21.5896 23.7609H15.5696C15.3746 23.7609 15.1846 23.8239 15.0286 23.9419L9.83061 27.8509C9.69861 27.9499 9.57461 27.9089 9.51261 27.8749C9.44961 27.8429 9.34561 27.7639 9.34961 27.6029L9.44961 24.6929C9.45761 24.4489 9.36661 24.2119 9.19761 24.0369C9.02861 23.8609 8.79461 23.7619 8.55061 23.7619H4.36961C3.00761 23.7619 1.89961 22.6549 1.89961 21.2919V9.39191C1.89961 8.02491 3.00761 6.91291 4.36961 6.91291H15.4596C15.9566 6.91291 16.3596 6.50991 16.3596 6.01291C16.3596 5.51591 15.9566 5.11292 15.4596 5.11292H4.36961C2.01561 5.11292 0.0996094 7.03291 0.0996094 9.39291V21.2919C0.0996094 23.6459 2.01561 25.5629 4.36961 25.5629H7.61861L7.55061 27.5449C7.52661 28.3589 7.95961 29.0989 8.68261 29.4739C8.99161 29.6349 9.32461 29.7139 9.65461 29.7139C10.0976 29.7139 10.5366 29.5709 10.9106 29.2919L15.8696 25.5629H21.5886C23.9426 25.5629 25.8586 23.6469 25.8586 21.2919V14.0129C25.8596 13.5149 25.4566 13.1119 24.9596 13.1119Z"
          fill="#561AD9"
        />
        <path
          d="M29.8247 4.53888C29.6617 4.03888 29.2607 3.66488 28.7517 3.53888L26.4517 2.96588L25.1957 0.955883C24.6387 0.061883 23.1847 0.063883 22.6287 0.955883L21.3727 2.96588L19.0727 3.53888C18.5637 3.66488 18.1617 4.03888 17.9997 4.53888C17.8377 5.03888 17.9417 5.57788 18.2797 5.98088L19.8037 7.79488L19.6377 10.1589C19.6007 10.6829 19.8327 11.1799 20.2577 11.4889C20.5217 11.6809 20.8317 11.7799 21.1457 11.7799C21.3367 11.7799 21.5297 11.7429 21.7147 11.6679L23.9127 10.7799L26.1097 11.6679C26.5967 11.8659 27.1407 11.7979 27.5667 11.4899C27.9917 11.1809 28.2237 10.6839 28.1877 10.1589L28.0217 7.79488L29.5457 5.97988C29.8827 5.57788 29.9877 5.03888 29.8247 4.53888ZM26.4087 6.91588C26.2587 7.09488 26.1837 7.32488 26.2007 7.55888L26.3607 9.82888L24.2507 8.97588C24.1427 8.93188 24.0277 8.90988 23.9137 8.90988C23.7997 8.90988 23.6847 8.93188 23.5767 8.97588L21.4677 9.82888L21.6267 7.55888C21.6427 7.32588 21.5677 7.09488 21.4187 6.91588L19.9557 5.17388L22.1637 4.62288C22.3897 4.56588 22.5857 4.42488 22.7087 4.22688L23.9147 2.29788L25.1207 4.22688C25.2447 4.42488 25.4397 4.56688 25.6657 4.62288L27.8737 5.17388L26.4087 6.91588Z"
          fill="#561AD9"
        />
      </svg>
    </mat-icon>
  </button>
</div>
