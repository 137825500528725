import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { HttpLink } from 'apollo-angular/http';
import { NgModule, inject } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  split,
} from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {
  getMainDefinition,
  offsetLimitPagination,
} from '@apollo/client/utilities';
import { CookieService } from 'ngx-cookie-service';
import { Auth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

// Custom type to handle apollo exceptions:
type ApolloException = {
  clientVersion: string;
  code: string;
  name: string;
  stacktrace: string[];
};

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const cookieService = inject(CookieService);
  const auth = inject(Auth);
  const router = inject(Router);

  const basicContext = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const authContext = setContext(async (operation, context) => {
    let token = cookieService.get('user_token') ?? null;

    if (!token) {
      if (auth.currentUser) {
        const newToken = await auth.currentUser.getIdToken(true);
        const expirationDate: Date = new Date();
        expirationDate.setMinutes(expirationDate.getMinutes() + 59);
        cookieService.set('user_token', newToken, {
          expires: expirationDate,
          sameSite: 'Lax',
        });

        token = cookieService.get('user_token');
        return { headers: { Authorization: `Bearer ${token}` } };
      }

      return {};
    }

    return { headers: { Authorization: `Bearer ${token}` } };
  });

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
      const userIsOnline = navigator.onLine;
      const networkErrorMessage = networkError?.message ?? '';
      const networkErrorName = networkError?.name ?? '';

      if (
        networkErrorMessage.includes('0 Unknown Error') &&
        networkErrorName === 'HttpErrorResponse' &&
        userIsOnline
      ) {
        router.navigate(['site-in-maintenance'], {
          state: { activatedByRouting: true },
        });
        return;
      }
      // If the BE invalidates token by any API error as a security measurement, then the current user will ask for a refreshed token
      const httpError: HttpErrorResponse = networkError as HttpErrorResponse;
      if (httpError.error.errors[0]?.message?.includes('Invalid Token')) {
        if (auth.currentUser) {
          auth.currentUser.getIdToken(true).then((token) => {
            const expirationDate: Date = new Date();
            expirationDate.setMinutes(expirationDate.getMinutes() + 59);
            cookieService.set('user_token', token, {
              expires: expirationDate,
              sameSite: 'Lax',
            });
            return;
          });
        }
      }
    }

    /*
      Catch non-existing or invalid id's when navigating to dynamic routes
      For example: company/1111999 or company/asdf
    */
    graphQLErrors?.map((error) => {
      const exception: ApolloException = <ApolloException>(
        error.extensions['exception']
      );
      if (
        exception.name === 'NotFoundError' ||
        exception.name === 'PrismaClientValidationError'
      ) {
        const currentRoute = router.routerState.snapshot.url;
        router.navigate(['/not-found'], {
          state: { activatedByRouting: true },
        });
        return;
      }
    });
  });

  // Define http and ws links to be able to select between them by operation:
  const http = httpLink.create({ uri: environment.apolloServer });
  const ws = new GraphQLWsLink(
    createClient({
      url: environment.apolloWsServer,
      connectionParams: () => {
        let token = cookieService.get('user_token') ?? null;

        if (!token) {
          return {};
        }
        return { headers: { Authorization: `Bearer ${token}` } };
      },
    })
  );
  /* 
  If the operation is a subscription then use the web socket protocol, else 
  use http for every other type of GraphQL operation:
  */
  const selectedLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    ws,
    http
  );

  const link = ApolloLink.from([
    basicContext,
    authContext,
    errorLink,
    selectedLink,
  ]);
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          PlacesResult: offsetLimitPagination(),
        },
      },
    },
  });

  return {
    link,
    cache,
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    CookieService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
