import { gql } from 'apollo-angular';
import { AppointmentsHistoryVariables } from 'src/types/Appointment';

export const GET_UPCOMING_APPOINTMENTS = gql`
  query UpcomingAppointments {
    appointmentsUpcomming {
      data {
        id
        date
        startTime
        endTime
        profilePictureUrl
        specialty
      }
    }
  }
`;

export const APPOINTMENTS_BY_USER = gql`
  query AppointmentsByUser {
    appointmentsByUser {
      approved {
        id
        date
        startTime
        endTime
        userName
        userEmail
        userPhoneNumber
        placeName
        placeAddress
        placeId
        placeLat
        placeLng
        specialtyId
        specialtyName
        specialtyTime
        systemCode
        previousSystemCode
        location {
          Id
          Lat
          Lng
        }
      }
      rejected {
        id
        date
        startTime
        endTime
        userName
        userEmail
        userPhoneNumber
        placeName
        placeAddress
        placeId
        placeLat
        placeLng
        specialtyId
        specialtyName
        specialtyTime
        systemCode
        previousSystemCode
        location {
          Id
          Lat
          Lng
        }
      }
      pending {
        id
        date
        startTime
        endTime
        userName
        userEmail
        userPhoneNumber
        placeName
        placeAddress
        placeId
        placeLat
        placeLng
        specialtyId
        specialtyName
        specialtyTime
        systemCode
        previousSystemCode
        location {
          Id
          Lat
          Lng
        }
      }
    }
  }
`;

export const APPOINTMENTS_HISTORY = gql`
  query Appointments(
    $where: AppointmentWhereInput
    $orderBy: [AppointmentOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    appointments(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      Id
      StartDateTime
      EndDateTime
      Schedule {
        Specialties {
          Name
          Place {
            Name
            PhoneNumber
          }
        }
      }
      SystemCode {
        Value
      }
      UserFeedbacks {
        Rate
        Description
        SystemCode {
          Value
        }
      }
    }
  }
`;

export const appointmentsHistoryInitialVariables = (
  userId: number
): AppointmentsHistoryVariables => ({
  where: {
    User: {
      is: {
        Id: {
          equals: userId,
        },
      },
    },
    StartDateTime: {
      lt: new Date(Date.now()).toISOString(),
    },
  },
  orderBy: [
    {
      StartDateTime: 'desc',
    },
  ],
  take: 0,
  skip: 0,
});

export const appointmentsHistoryGetByStatus = (status: string) => {
  if (status === 'Rejected') {
    return {
      SystemCode: {
        is: {
          OR: [
            {
              Value: {
                equals: 'Rejected',
              },
            },
            {
              Value: {
                equals: 'Cancel',
              },
            },
          ],
        },
      },
    };
  } else {
    return {
      SystemCode: {
        is: {
          Value: {
            equals: status,
          },
        },
      },
    };
  }
};
