import { gql } from 'apollo-angular';
import { UpdateUserVariables } from 'src/types/User';

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateOneUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $where) {
      FirstName
      LastName
      PhoneNumber
      ProfilePictureUrl
    }
  }
`;

export const updateUserVariables = ({
  id,
  firstName,
  lastName,
  phoneNumber,
  profilePictureUrl,
}: UpdateUserVariables) => {
  if (profilePictureUrl) {
    return {
      data: {
        FirstName: {
          set: firstName,
        },
        LastName: {
          set: lastName,
        },
        PhoneNumber: {
          set: phoneNumber,
        },
        ProfilePictureUrl: {
          set: profilePictureUrl,
        },
      },
      where: {
        Id: id,
      },
    };
  }

  return {
    data: {
      FirstName: {
        set: firstName,
      },
      LastName: {
        set: lastName,
      },
      PhoneNumber: {
        set: phoneNumber,
      },
    },
    where: {
      Id: id,
    },
  };
};

export const DEACTIVATE_ACCOUNT = gql`
  mutation DeactivateAccount {
    deactivateAccount {
      success
      message
      messages
      statusCodeError
    }
  }
`;

export const REACTIVATE_ACCOUNT = gql`
  mutation ReactivateAccount {
    reactivateAccount {
      success
      message
      messages
      statusCodeError
    }
  }
`;
