import { gql } from 'apollo-angular';
import { ScheduleSlotsNotificationResponse } from 'src/types/Schedule';

export const SCHEDULE_SLOTS_NOTIFICATION = gql<
  ScheduleSlotsNotificationResponse,
  any
>`
  subscription OnNotificationSchedules {
    onNotificationSchedules {
      success
      message
      messages
      statusCodeError
      data {
        hour
        isAvailable
      }
    }
  }
`;
