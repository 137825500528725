import { Component, OnInit } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { map } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GET_UPCOMING_APPOINTMENTS } from 'src/app/graphql';
import { UpcomingAppointmentsResponse } from 'src/types/Appointment';

@Component({
  selector: 'upcoming-appointments',
  templateUrl: './upcoming-appointments.component.html',
  styleUrls: ['./upcoming-appointments.component.scss']
})
export class UpcomingAppointmentsComponent implements OnInit {

  isAuthenticated: boolean = false;
  appointmentsQuery!: QueryRef<UpcomingAppointmentsResponse>;

  constructor(private apollo: Apollo, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.isAuthenticated()
      .pipe(map(user => !!user))
      .subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);

    this.appointmentsQuery = this.apollo.watchQuery<UpcomingAppointmentsResponse>({
      query: GET_UPCOMING_APPOINTMENTS
    })
  }
}
