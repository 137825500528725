<div mat-dialog-content class="custom-dialog">
  <p>{{ data.question }}</p>
  <div class="custom-dialog-buttons">
    <button
      mat-flat-button
      color="accent"
      class="custom-dialog-button"
      (click)="onConfirmationAction()"
    >
      Sí
    </button>
    <button
      mat-flat-button
      color="primary"
      class="custom-dialog-button"
      (click)="closeDialog()"
    >
      No
    </button>
  </div>
</div>
