<section class="site-in-maintenance-container">
  <error-screens-navbar></error-screens-navbar>
  <main class="site-in-maintenance">
    <h1>Sitio en Mantenimiento</h1>
    <svg
      class="line-divider"
      xmlns="http://www.w3.org/2000/svg"
      width="673"
      height="6"
      viewBox="0 0 573 6"
      fill="none"
    >
      <path
        d="M567.333 3C567.333 4.47276 568.527 5.66667 570 5.66667C571.473 5.66667 572.667 4.47276 572.667 3C572.667 1.52724 571.473 0.333333 570 0.333333C568.527 0.333333 567.333 1.52724 567.333 3ZM0 3.5H570V2.5H0V3.5Z"
        fill="black"
      />
    </svg>
    <p>
      Nos encontramos realizando mantenimiento, para brindarte una mejor
      experiencia.
    </p>
  </main>
</section>
