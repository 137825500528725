import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'company-image-dialog',
  templateUrl: './company-image-dialog.component.html',
  styleUrls: ['./company-image-dialog.component.scss']
})
export class CompanyImageDialogComponent {

  @HostListener('window:resize')
  onResize() {
    this.updateSize(screen.orientation.type);
  }

  constructor(public dialogRef: MatDialogRef<CompanyImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imageUrl: string, name: string }
  ) { }


  closeDialog() {
    this.dialogRef.close();
  }

  updateSize(screenOrientation: string) {
    if (screenOrientation === 'landscape-primary') {
      this.dialogRef.updateSize('50vw', '70vh')
    } else {
      this.dialogRef.updateSize('80vw', '60vh')
    }
  }

}
