import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, QueryRef } from 'apollo-angular';
import { GET_PLACES, getPlacesVariables } from 'src/app/graphql';
import { GetPlacesResponse, GetPlacesVariables, Place } from 'src/types/Place';
import {
  query,
  stagger,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { fadeAnimation } from 'src/app/animations';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeInPage', [
      transition(':enter', [
        query('h1,h2,div,section', [
          useAnimation(fadeAnimation, {
            params: { start: 0, end: 0 },
          }),
          stagger(30, [
            useAnimation(fadeAnimation, {
              params: { time: '300ms 100ms', start: 0, end: 1 },
            }),
          ]),
        ]),
      ]),
    ]),
    trigger('fadeInCards', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: { time: '300ms 200ms', start: 0, end: 1 },
        }),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  placesQuery?: QueryRef<GetPlacesResponse, GetPlacesVariables>;
  placesSubscription?: Subscription;
  places: Place[] = [];
  loading: boolean = true;
  searchQuery: string = '';
  userAgentCoords?: GeolocationCoordinates | null;
  selectedCategoriesFilter?: number[] | null;
  homeFilter?: number | null;

  constructor(private loadingService: LoadingService, private apollo: Apollo) {}

  ngOnInit(): void {
    this.loadingService.setLoading(true);

    this.loadingService
      .loadingListener()
      .subscribe((isLoading) => (this.loading = isLoading));

    this.placesQuery = this.apollo.watchQuery<
      GetPlacesResponse,
      GetPlacesVariables
    >({
      query: GET_PLACES,
      variables: { ...getPlacesVariables, name: this.searchQuery },
      fetchPolicy: 'network-only',
    });

    this.subscribeToPlacesQuery();
  }

  onListeningSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
    this.refetch();
  }

  onListeningUserAgentLocation(userAgentCoords: GeolocationCoordinates | null) {
    this.userAgentCoords = userAgentCoords;
    this.refetch();
  }

  onListeningPlaceCategoriesSelection(selectedCategories: number[]) {
    this.selectedCategoriesFilter = selectedCategories;
    this.refetch();
  }
  onListeningHome(home: number) {
    this.homeFilter = home;
    this.refetch();
  }

  subscribeToPlacesQuery() {
    this.placesSubscription = this.placesQuery?.valueChanges.subscribe(
      ({ data, loading }) => {
        this.updateUI(data.getPlaces.data);
        this.loadingService.setLoading(loading);
      }
    );
  }

  fetchMore() {
    this.placesQuery
      ?.fetchMore({ variables: { skip: this.places.length } })
      .then(({ data }) => {
        this.updateUI(data.getPlaces.data);
      });
  }

  refetch() {
    this.loadingService.setLoading(true);
    this.updateUI([], true);
    this.placesQuery
      ?.refetch({
        ...getPlacesVariables,
        name: this.searchQuery,
        lat: this.userAgentCoords?.latitude ?? 0,
        lng: this.userAgentCoords?.longitude ?? 0,
        categories: this.selectedCategoriesFilter ?? [],
        filterHome: this.homeFilter ?? -1,
      } as GetPlacesVariables)
      .finally(() => {
        this.placesQuery?.resetLastResults();
        this.loadingService.setLoading(false);
      });
  }

  updateUI(newPlacesData: Place[], resetUI: boolean = false) {
    if (resetUI) {
      this.places = [];
      return;
    }
    // Next line eliminates any duplicate that Apollo-angular pulls at the last page to fulfill the amount on $take variable:
    if (newPlacesData !== null) {
      this.places = [...new Set([...this.places, ...newPlacesData])];
    }
  }

  onIntersection(isIntersecting: boolean) {
    if (isIntersecting) {
      this.fetchMore();
    }
  }

  ngOnDestroy(): void {
    this.placesSubscription?.unsubscribe();
  }
}
