<section class="loading-container" *ngIf="isLoading">
  <div class="loading">
    <svg
      class="animated-logo animated-logo-loop"
      viewBox="0 0 72 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="paint0_linear_7813_1644"
          x1="35.5"
          y1="-0.01"
          x2="35.5"
          y2="45.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#AFA2FF" />
          <stop offset="1" stop-color="#561AD9" />
        </linearGradient>
      </defs>
      <path
        d="M61 3.44C61 1.54 59.46 0 57.56 0H35.66C35.66 0 35.59 0 35.55 0C35.51 0 35.48 0 35.44 0H35.26C35.2 0 35.14 0.02 35.08 0.02C22.96 0.27 13.18 10.19 13.18 22.37C13.18 28.38 15.57 33.82 19.43 37.85H13.44C11.54 37.85 10 39.39 10 41.29C10 43.19 11.54 44.73 13.44 44.73H35.2C35.32 44.73 35.43 44.75 35.55 44.75C47.89 44.75 57.93 34.71 57.93 22.37C57.93 16.36 55.54 10.9 51.66 6.88H57.56C59.46 6.88 61 5.34 61 3.44ZM51.04 22.37C51.04 30.8 44.27 37.66 35.89 37.85H35.19C26.81 37.66 20.05 30.8 20.05 22.37C20.05 13.94 26.94 6.94 35.43 6.88H35.65C44.14 6.94 51.04 13.86 51.04 22.37Z"
        fill="url(#paint0_linear_7813_1644)"
        transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 0)"
      />
      <path
        d="M3.46 44.76C5.37091 44.76 6.92 43.2109 6.92 41.3C6.92 39.3891 5.37091 37.84 3.46 37.84C1.54909 37.84 0 39.3891 0 41.3C0 43.2109 1.54909 44.76 3.46 44.76Z"
        fill="#561AD9"
        transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 0)"
      />
      <path
        d="M67.7799 6.92C69.6909 6.92 71.2399 5.37091 71.2399 3.46C71.2399 1.54909 69.6909 0 67.7799 0C65.869 0 64.3199 1.54909 64.3199 3.46C64.3199 5.37091 65.869 6.92 67.7799 6.92Z"
        fill="#AFA2FF"
        transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 0)"
      />
      <path
        id="hour-hand"
        d="M 35.93 24.794 C 35.09 24.794 34.31 24.264 34.03 23.434 C 33.68 22.384 34.24 21.254 35.29 20.904 L 42.56 18.463 C 43.61 18.113 44.74 18.673 45.09 19.724 C 45.44 20.774 44.88 21.904 43.83 22.254 L 36.56 24.694 C 36.35 24.764 36.13 24.794 35.92 24.794 L 35.93 24.794 Z"
        fill="#561AD9"
        style="transform-origin: 35.5508px 22.9404px"
        transform="matrix(1, 0.000001, -0.000001, 1, -3e-12, 0)"
      />
      <path
        id="minute-hand"
        d="M35.5699 24.92C34.4699 24.92 33.5699 24.02 33.5699 22.92V12.34C33.5699 11.24 34.4699 10.34 35.5699 10.34C36.6699 10.34 37.5699 11.24 37.5699 12.34V22.92C37.5699 24.02 36.6699 24.92 35.5699 24.92Z"
        fill="#561AD9"
        transform="matrix(1, 0, 0, 1, 4.440892098500626e-16, 0)"
      />
    </svg>
  </div>
</section>
