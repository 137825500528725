import { Component, ViewEncapsulation } from '@angular/core';
import {
  query,
  stagger,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { fadeAnimation } from 'src/app/animations';

@Component({
  selector: 'auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInPage', [
      transition(':enter', [
        query('h1,h2,p,form,.navigation-items', [
          useAnimation(fadeAnimation, {
            params: { start: 0, end: 0 },
          }),
          stagger(30, [
            useAnimation(fadeAnimation, {
              params: { time: '300ms 100ms', start: 0, end: 1 },
            }),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class AuthLayoutComponent {}
