<sidenav>
  <section @fadeIn class="appointments-history" *ngIf="!isLoading">
    <div class="appointments-history-filter">
      <radio-filter
        [options]="appointmentStatus"
        [selectedOption]="selectedAppointmentStatus"
        (onChangeListener)="onFilteringStatus()"
      ></radio-filter>
    </div>
    <mat-tab-group
      disableRipple
      style="
        --mdc-tab-indicator-active-indicator-color: transparent;
        --mat-tab-header-active-focus-indicator-color: transparent;
        --mat-tab-header-active-hover-indicator-color: transparent;
        --mat-tab-header-disabled-ripple-color: transparent;
        --mat-tab-header-active-ripple-color: transparent;
        --mat-tab-header-inactive-ripple-color: transparent;
      "
    >
      <mat-tab label="Tu historial">
        <section class="appointments-history-wrapper">
          <div class="navigation">
            <span>Mostrando {{ appointmentsHistory.length }} resultados</span>
            <paginator
              [pageNumber]="pageNumber"
              [pageSize]="PAGE_SIZE"
              [nextIsEnabled]="nextIsEnabled"
              (previous)="onPrevious()"
              (next)="onNext()"
            ></paginator>
          </div>
          <div
            *ngIf="appointmentsHistory.length > 0; else noAppointments"
            class="appointments-history-body"
          >
            <appointment-history-card
              *ngFor="let appointment of appointmentsHistory"
              [appointment]="appointment"
            ></appointment-history-card>
          </div>
          <ng-template #noAppointments>
            <div class="appointments-history-body">
              <h1 class="not-found-message">
                No hay citas que mostrar en este momento.
              </h1>
            </div>
          </ng-template>
          <div class="navigation">
            <paginator
              [pageNumber]="pageNumber"
              [pageSize]="PAGE_SIZE"
              [nextIsEnabled]="nextIsEnabled"
              (previous)="onPrevious()"
              (next)="onNext()"
            ></paginator>
          </div>
        </section>
        <div class="body-gradient"></div>
      </mat-tab>
    </mat-tab-group>
  </section>
</sidenav>
