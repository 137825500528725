<section class="swiper-custom-wrapper">
  <swiper-container
    #swiper
    style="
      --swiper-pagination-color: var(--accent);
      --swiper-pagination-bottom: -3px;
    "
  >
    <swiper-slide
      *ngFor="let appointment of upcomingAppointments"
      [lazy]="true"
    >
      <mat-card (click)="navigateToAppointments(appointment.id)">
        <mat-card-content class="swiper-card">
          <div class="swiper-card-image">
            <image
              [imgSrc]="appointment.profilePictureUrl"
              [imgAlt]="appointment.specialty"
            ></image>
          </div>
          <div class="swiper-card-col swiper-card-col-restraint">
            <p>Cita</p>
            <p>Tipo de cita</p>
            <span class="swiper-card-col-text">{{
              appointment.specialty
            }}</span>
          </div>
          <div class="swiper-card-col">
            <p>Fecha</p>
            <span>{{ appointment.date | date : "dd/MM/yyyy" }}</span>
            <span
              >{{ appointment.startTime | timeFromString }} -
              {{ appointment.endTime | timeFromString }}</span
            >
          </div>
        </mat-card-content>
      </mat-card>
    </swiper-slide>
  </swiper-container>
</section>
