import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { passwordPattern } from 'src/types/regex-pattern';
import { whiteSpacesValidator } from '../../RegisterPage/client-registration/client-registration.component';
import { matchingPasswords } from 'src/types/password-match-validator';
import { ErrorStateMatcher } from 'src/types/ErrorSateMatcher';
import {
  Auth,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from '@angular/fire/auth';

interface changePasswordFormGroup {
  password: FormControl;
  confirmPassword: FormControl;
}

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent {
  matcher = new ErrorStateMatcher();
  currentPassword = new FormControl('', [
    Validators.required,
    Validators.pattern(passwordPattern),
    Validators.minLength(8),
    whiteSpacesValidator(),
  ]);
  changePasswordForm: FormGroup<changePasswordFormGroup> = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(passwordPattern),
        Validators.minLength(8),
        whiteSpacesValidator(),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.pattern(passwordPattern),
        Validators.minLength(8),
      ]),
    },
    {
      validators: [matchingPasswords],
    }
  );

  showCurrentPassword: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isLoading: boolean = false;

  constructor(
    private auth: Auth,
    private authenticationService: AuthenticationService,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<ChangePasswordComponent>
  ) {}

  getPasswordErrorMessage(passwordControl: FormControl) {
    if (passwordControl.hasError('required')) {
      return 'Contraseña <strong>requerida</strong>';
    }
    if (passwordControl.hasError('whiteSpaces')) {
      return '<strong>No debe contener espacios</strong>';
    }
    if (passwordControl.hasError('pattern')) {
      return '<strong>Utilice mayúsculas, minúsculas y al menos un número.</strong>';
    }
    if (passwordControl.hasError('minlength')) {
      return 'Debe tener <strong>al menos 8 caracteres.</strong>';
    }
    return '';
  }

  getConfirmPasswordErrorMessage() {
    if (this.changePasswordForm.controls.confirmPassword.hasError('required')) {
      return '<strong>Debe confirmar su contraseña</strong>';
    }
    if (this.changePasswordForm.errors?.['mismatch']) {
      return 'Las contraseñas <strong>no coinciden</strong>';
    }
    return '';
  }

  onCheckCurrentPassword(stepperNextButton: HTMLButtonElement) {
    if (this.currentPassword.valid && this.auth.currentUser) {
      this.isLoading = true;
      //Create AuthCredential object
      const userCredentials = EmailAuthProvider.credential(
        this.auth.currentUser.email!,
        this.currentPassword.value!
      );
      // Re-authenticates a user with fresh credentials
      reauthenticateWithCredential(this.auth.currentUser, userCredentials)
        .then(() => {
          this.isLoading = false;
          stepperNextButton.click();
        })
        .catch((error: any) => {
          this.isLoading = false;
          if (error.message.includes('invalid-login-credentials')) {
            this.triggerErrorNotification(
              '¡Contraseña incorrecta! Intente de nuevo.'
            );
          } else if (error.message.includes('too-many-requests')) {
            this.triggerErrorNotification(
              'Acceso a cuenta deshabilitado temporalmente. ¡Muchos intentos de autenticación fallidos!',
              true
            );
          } else {
            this.triggerErrorNotification(
              '¡Ha ocurrido un error inesperado!',
              true
            );
          }
        });
    }
  }

  triggerErrorNotification(
    errorMessage: string,
    shouldLogout: boolean = false
  ) {
    this.snackbar
      .openFromComponent(SnackbarComponent, {
        data: {
          validation: false,
          warning: false,
          message: errorMessage,
        },
        duration: 3000,
        panelClass: ['error-snackbar'],
        horizontalPosition: 'end',
        verticalPosition: 'top',
      })
      .afterDismissed()
      .subscribe(() => {
        if (shouldLogout) {
          this.authenticationService.clientLogout(true);
        }
      });
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.isLoading = true;
      this.authenticationService
        .changePassword({
          body: {
            newPassword: this.changePasswordForm.controls.password.value,
          },
        })
        .subscribe((result) => {
          if (result.data?.changePassword.success == true) {
            this.snackbar
              .openFromComponent(SnackbarComponent, {
                data: {
                  validation: true,
                  message: result.data?.changePassword.message,
                },
                duration: 2000,
                panelClass: ['success-snackbar'],
                horizontalPosition: 'end',
                verticalPosition: 'top',
              })
              .afterDismissed()
              .subscribe(() => {
                this.isLoading = false;
                this.dialogRef.close();
              });
          } else {
            this.snackbar.openFromComponent(SnackbarComponent, {
              data: {
                validation: false,
                warning: false,
                message: 'Error al cambiar la contraseña. Intente de nuevo',
              },
              duration: 6000,
              panelClass: ['error-snackbar'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
            });
          }
        });
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
