import { gql } from 'apollo-angular';

export const REGISTER_CLIENT_MUTATION = gql`
  mutation RegisterClient($body: RegisterClientInput!) {
    registerClient(body: $body) {
      success
      message
      messages
    }
  }
`;

export const REGISTER_CLIENT_WITH_GOOGLE_MUTATION = gql`
  mutation RegisterClientWithGoogle($body: RegisterClientWithGoogleInput!) {
    registerClientWithGoogle(body: $body) {
      success
      message
      messages
    }
  }
`;

export const CLIENT_LOGIN_MUTATION = gql`
  mutation Login {
    login {
      success
      message
      messages
      data {
        FirstName
        LastName
        Email
        PhoneNumber
        ProfilePictureUrl
        SystemCode {
          Entity
          Value
        }
      }
    }
  }
`;

export const CLIENT_PASSWORD_RECOVERY_MUTATION = gql`
  mutation ResetPassword($body: ResetPasswordInput!) {
    resetPassword(body: $body) {
      message
      messages
      success
    }
  }
`;

export const CLIENT_PASSWORD_CHANGE_MUTATION = gql`
  mutation ChangePassword($body: ChangePasswordInput!) {
    changePassword(body: $body) {
      success
      message
      messages
    }
  }
`;
